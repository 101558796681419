import React, { useState } from 'react'
import { FormControl, Row, Col, Image, Button,InputGroup } from 'react-bootstrap'
import searchTool from '../assets/search.svg'
import searchToolConversation from '../assets/search-conversation.svg'
import chatBell from '../assets/chat-bell.svg'
import groupChatImg from '../assets/group-chat-img.png'
import groupChatImg2 from '../assets/group-chat-img3.png'
import groupChatImg3 from '../assets/group-chat-img2.png'
import chatList from '../assets/chat-list-img.svg'
import participantIcon from '../assets/participant-icon.png'
import attach from '../assets/attach-group.svg'
import picGroup from '../assets/pic-group.svg'
import arrow from '../assets/arrow-group.svg'

const GroupChatView = () => {

    const [chatLeft, setChatLeft] = useState(false)

    const conversations = [
        // Conversation 1
        {
            id: 1, header: "Conversation with testers", text: "Conversation text",
            // messages 1
            messages: [
                { user_id: 2, content: 'Hi Mark' },
                { user_id: 1, content: 'Hi Pete' },
                { user_id: 2, content: 'I want to tell you that our project is finished!You can come and read it if you have any suggestions.' },
                { user_id: 1, content: "Thank you Elizabet.I was wondering is it finished.I will read it to make sure everything is fine." },
                { user_id: 2, content: 'You’re welcome.I like the last picture that you add on second page of the project. She did amazing job.' },
                { user_id: 1, content: 'Yes, I like it too.' },
                { user_id: 1, content: 'Oh, would you like some coffee?' },
                { user_id: 2, content: 'OK I am waiting for you.' },
                { user_id: 2, content: 'Yes, coffee would be great 😊' },
                { user_id: 1, content: 'See you soon!' },
                { user_id: 2, content: 'See you' }
            ]
        },

        // Conversation 2
        {
            id: 2, header: "Group name", text: "Conversation text",
            // messages 2
            messages: [
                { user_id: 2, content: 'Hi, Tom' },
                { user_id: 1, content: 'Hi Pete' },
            ]
        },

        // Conversation 3
        {
            id: 3, header: "Group name", text: "Conversation text",
            // messages 3
            messages: [
                { user_id: 2, content: 'Hello, Can I ask you something' },
                { user_id: 1, content: 'Yes, sure' },
            ]
        }

    ];

    const [activeConversation, setActiveConversation] = useState(0);

    const conversationSelection = (id) => {
        setActiveConversation(id)
    }

    const changeMenuClass = () =>{
        setChatLeft((state)=>!state)
    }

    return (
        <>
            <div className="group-chat">

                <Row className="search-row">
                    <div className="general-search">
                        <div className="custom-search">
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"><Image src={searchTool} /></InputGroup.Text>
                                <FormControl
                                    placeholder="Search for..."
                                    aria-label="search"
                                    aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                        </div>
                        <Image src={chatBell} id="chatBell" />
                        <Button variant="light" id="darkBurger" onClick={()=> changeMenuClass()}></Button>
                    </div>

                </Row>

                <Row className="main-row">

                    <Col lg={4} className={chatLeft? "chat-left" : "chat-left display"}>

                        <div className="conversation-heading">
                            Conversation
                        </div>

                        <div className="search-conversation">
                            <div className="custom-search">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1"><Image src={searchToolConversation} /></InputGroup.Text>
                                    <FormControl
                                        placeholder="Search conversation"
                                        aria-label="search"
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                            </div>
                        </div>

                        {
                            conversations.map(conversation => {
                                return (

                                    <div onClick={() => conversationSelection(conversation.id - 1)} className={conversation.id - 1 === activeConversation ? "conversation-panel active" : "conversation-panel"}>
                                        <div className="panel-heading">
                                            {conversation.header}
                                        </div>
                                        <div className="panel-message">
                                            {conversation.messages[0].content}
                                        </div>
                                        <div className="panel-info">
                                            <Image src={groupChatImg} />
                                            <div className="panel-date">
                                                Aug 26
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                        }

                        <div className="chat-options">
                            <div className="chat-options-text">
                                Only healthcare professionals can initiate chat.
                                You will be notified when a chat opens.
                            </div>

                            <div className="chat-options-buttons">
                                <Row>
                                    <Col sm={6}>
                                        <Button variant="info">Active</Button>
                                    </Col>
                                    <Col sm={6}>
                                        <Button variant="light">History</Button>
                                    </Col>
                                </Row>


                            </div>
                        </div>

                    </Col>

                    <Col lg={8} className="chat-right">

                        <Row className="chat-heading">

                            <Col md={5}>
                                <div className="chat-name">
                                    <Image src={chatList} /> Test Conversation
                                </div>
                            </Col>

                            <Col md={7}>
                                <div className="chat-members">
                                    <div className="chat-participants">
                                        <Image src={participantIcon} />
                                        Mark
                                    </div>
                                    <div className="chat-participants">
                                        <Image src={groupChatImg2} />
                                        Pete
                                    </div>
                                    <div className="chat-participants">
                                        <Image src={groupChatImg3} />
                                        Tom
                                    </div>

                                </div>
                            </Col>

                        </Row>

                        <div className="chat-area">
                            <div className="chat-messages">
                                {
                                    conversations[activeConversation].messages.map(message =>
                                        message.user_id === 1 ?
                                            <div className="chat-message-sent">
                                                {message.content}
                                            </div> :
                                            <div className="chat-receive">
                                                <Image src={groupChatImg2} />
                                                <div className="chat-message-receive">
                                                    {message.content}
                                                </div>
                                            </div>

                                    )
                                }
                            </div>
                        </div>

                        <div className="chat-input-area">
                            <InputGroup className="mb-3">
                                <Image src={attach} />
                                <Image src={picGroup} />
                                <FormControl
                                    placeholder="Write a message..."
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                />
                                <Image src={arrow} />
                            </InputGroup>
                        </div>


                    </Col>
                </Row>

            </div>
        </>
    )
}

export default GroupChatView