import React from 'react';
import { Col, Button} from 'react-bootstrap'
import { NavLink } from 'react-router-dom';

const Sidebar = (props) => {

    return (
        <Col lg="3" md="0" sm="0" xs="0" className={props.navState && "active"}>
            <div className="main-content-left">
                <div className="sidebar-container">

                    <div className="sidebar">
                        <div className="sidebar-top-nav">
                            <a href="https://flatuikit-reactjs.trendy.solutions">HTML</a>
                            <a href="https://flatuikit-angular.trendy.solutions">Angular</a>
                            <a href="https://flatuikit-html.trendy.solutions/components/alerts.html">Vue</a>
                            <div className="nav-button">
                                <Button size="sm" variant="info" style={{marginTop:"10px"}} href="https://flatuikit.trendy.solutions/#buy-now">Buy now</Button>
                            </div>
                        </div>
                        <h6 style={{marginTop:"10px"}}>Components</h6>
                        <ul>
                            <li>
                                <NavLink activeClassName='is-active' to="/alert" >Alerts</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/badge">Badge</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/breadcrumb">Breadcrumbs</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/buttons">Buttons</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/button-group">Button Group</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/card">Card</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/carousel">Carousel</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/catalog">Catalog</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/chart">Charts</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/chat">Chat</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/collapse">Collapse</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/datepicker">Datepicker</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/dropdown">Dropdowns</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/extras">Extras</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/form">Form</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/groupchat">Group Chat</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/input">Input group</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/jumbotron">Jumbotron</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/list-group">List Group</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/login">Login</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/modal">Modals</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/navbar">Navbar</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/nav">Navs</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/pagination">Pagination</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/plans-pricing">Plans and pricing</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/progressbar">Progress bars</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/spinners">Spinners</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/switchers">Switchers</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/tables">Tables</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/toast">Toasts</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/tooltip">Tooltip</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/webpage">WebPage</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName='is-active' to="/webpage2">WebPage2</NavLink>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </Col>
    )
};

export default Sidebar;