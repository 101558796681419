import React from 'react';
import { Image, Button, Row, Col, ProgressBar } from 'react-bootstrap';
import { Line, Pie } from 'react-chartjs-2'
import ReactPrism from '@versant-digital/react-prismjs';
import { useBarcode } from 'react-barcodes'

import personIcon from '../assets/person-icon-primary.svg'
import examplePerson from '../assets/example-pic.svg'
import sunIco from '../assets/sun-ico.svg'
import groupDots from '../assets/group-dots.svg'
import iconFliht from '../assets/icon-flight.svg'
import examplePerson2 from '../assets/example-person2.png'
import iconGraph from '../assets/icon-graph.svg'
import arrowUpRight from '../assets/arrow-up-right.svg'
import newAdmin from '../assets/new-admin-design.svg'
import imgSmall1 from '../assets/small-img-1.png'
import imgSmall2 from '../assets/small-img-2.png'
import hImage from '../assets/h-image.svg'

const card1 = `<div className="card extras-1">
    <div className="icon">
        <Image src={personIcon} alt="" />
    </div>
    <div className="text">
        <p className="text-secondary">New Leads</p>
        <span className="span-count">205</span>
    </div>
</div>`

const card2 = `<div className="card extras-2">
    <div className="icon">
        <Image src={personIcon} alt="" />
    </div>
    <div className="text">
        <p className="p-12 text-secondary">New Leads</p>
        <span className="span-count">205</span>
    </div>
</div>`

const personCard = `<div className="card card-contact">
    <div className="icon">
        <Image src={examplePerson} alt="" />
    </div>
    <div className="content">
        <p className="name">Jessica Hike</p>
        <p className="p-12 text-secondary">UI/UX Designer</p>
        <p className="text p-12 text-secondary">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
        <Button className="btn btn-primary rounded-pill">Contact Jessica</Button>
    </div>
</div>`

const timeCard = `<div className="card card-time">
    <div className="icon">
        <Image src={sunIco} alt=""/>
    </div>
    <div className="content">
        <p className="text-primary m-0">Time to leave</p>
        <span className="span-time">08:00</span>
        <p className="timer">Estimate arriving in 34 min</p>
    </div>
</div>`

const newLeads = `<div className="card extras-3">
    <div className="icon">
        <div>
            <embed src={personIcon} type="" />
            <p className="p-12 text-secondary">New Leads</p>
        </div>
        <div>
            <embed src={groupDots} type="" />
        </div>
    </div>
    <ProgressBar now={55} variant="primary" />
    <div className="content">
        <div>
            <span className="num-big">205</span>
            <p className="p-12 text-primary">UP</p>
        </div>
        <div className="second">
            <p className="p-12 text-secondary">Goal</p><span className="num-small">500</span>
        </div>
    </div>
</div>`

const ticket = `<div className="card card-flight-ticket">
    <div className="flight-info">
        <div>
            <p className="city">LA</p>
            <p className="time">09:45</p>
            <p className="date">April 27, US</p>
        </div>
        <div className="flight-icon">
            <embed src={iconFliht} type="" />
        </div>
        <div>
            <p className="city">VEN</p>
            <p className="time">12:05</p>
            <p className="date">April 27, IT</p>
        </div>
    </div>
    <div className="passenger">
        <p className="label">Passenger</p>
        <p className="name">Jessica Hike</p>
        <div className="circle circle-1"></div>
        <div className="circle circle-2"></div>
    </div>
    <div className="barcode">
    <svg ref={inputRef} />
    </div>
</div>`

const graph4 = `<div className="card extras-4">
    <div className="info">
        <div className="image">
            <div><Image src={examplePerson2} alt=""/></div>
        </div>
        <div className="name">
            <p className="text-secondary">Jessica Hike</p>
            <p className="p-12 text-primary">UI/UX Designer</p>
        </div>
    </div>
    <div className="date">
        <p className="p-12 text-primary">11:45 AM</p>
        <div className="notifications">
            <span>3</span>
        </div>
    </div>
</div>`

const graph5 = `<div className="card extras-5">
    <div className="info">
        <p>UI/UX Designer</p>
        <span className="price">$58,000</span>
        <p className="status"><span>5.2%</span> Up from past week</p>
    </div>
    <div>
        <embed src={iconGraph} type="" />
    </div>
</div>`

const cardExtras = `<div className="card extras-6 bg-primary">
    <div className="info">
        <p className="">Bounce rate</p>
        <span className="status">%34</span>
    </div>
    <div className="icon">
        <p>Last 30 days</p>
        <div className="graph">
            <embed src={arrowUpRight} type=""/>
        </div>
    </div>
</div>`
const newAdminDesign = `<div className="card new-admin-design">
    <div className="top-section">
        <div className="image">
            <Image src={newAdmin} alt="" />
        </div>
        <div className="content">
            <p className="title text-secondary">New admin Design</p>
            <p className="text text-secondary">It will be as simple as Occidental</p>
            <Image src={imgSmall1} alt="" />
            <Image src={imgSmall2} alt="" />
            <div className="icon-h">H</div>
        </div>
    </div>
    <div className="bottom-section">
        <Button className="btn btn-primary btn-sm">Completed</Button>
        <p className="text-secondary">15 Oct, 2020</p>
    </div>
</div>`
const charts = `<div className="charts">
    <div className="card chart-pie">
        <div className="content">
            <div>
                <p className="p-14">Target Sales</p>
                <p>300 Sales</p>
            </div>
            <div style={{width:"60px"}}>
            <Pie data={pieData} options={pieOptions} className="pie-chart" />
            </div>
        </div>
    </div>
    <div className="card chart-line">
        <p className="p-14">Target Sales <span>320 Followers</span></p>
        <div>
        <Line data={lineData} options={lineOptions} className="line-chart" />
        </div>
    </div>
</div>`

const pieData = {
    labels: ['Organic Search', 'Email', 'Referral', 'Paid Search'],
    datasets: [{
        data: [25, 35, 35, 20],
        backgroundColor: [
            '#5561B3',
            '#161F5E',
            '#DFCA41',
            '#DF4149'
        ],
    },
    ]
};

const pieOptions = {
    radius: 30,
    responsive: true,
    maintainAspectRatio: true,
    padding: 0,
    plugins: {
        legend: {
            display: false
        }
    },
}

const lineData = {
    labels: ['02 JAN', '03 JAN', '04 JAN', '05 JAN'],
    datasets: [{
        data: [18, 26, 16, 17],
    }],
};

const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    fill: false,
    elements: {
        point: {
            radius: 0
        },
        line: {
            tension: 0.7,
            borderColor: '#5561B3',
            borderWidth: 4,
        }
    },
    scales: {
        y: {
            grid: {
                drawBorder: false,
                display: false,
                color: '#F4F5FC'
            },
            ticks: {
                display: false,
                color: '#161F5E',
                font: {
                    family: "'Poppins', sans-serif",
                    weight: 500,
                    size: 40,
                }
            }
        },
        x: {
            grid: {
                drawBorder: false,
                display: false,
            },
            ticks: {
                display: false,
            }
        }
    },
    plugins: {
        legend: {
            display: false
        },
    }
}


const ExtrasView = () => {

    const { inputRef } = useBarcode({
        value: 'react-barcodes'
    });

    return (
        <>

            <h1 className="title-section">Extras</h1>

            <br />

            <div className="card extras-1">
                <div className="icon">
                    <Image src={personIcon} alt="" />
                </div>
                <div className="text">
                    <p className="text-secondary">New Leads</p>
                    <span className="span-count">205</span>
                </div>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {card1}
            </ReactPrism>


            <div className="card extras-2">
                <div className="icon">
                    <Image src={personIcon} alt="" />
                </div>
                <div className="text">
                    <p className="p-12 text-secondary">New Leads</p>
                    <span className="span-count">205</span>
                </div>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {card2}
            </ReactPrism>

            <h2>Contact card</h2>
            <div className="card card-contact">
                <div className="icon">
                    <Image src={examplePerson} alt="" />
                </div>
                <div className="content">
                    <p className="name">Jessica Hike</p>
                    <p className="p-12 text-secondary big">UI/UX Designer</p>
                    <p className="text p-12 text-secondary">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                    <Button className="btn btn-primary rounded-pill">Contact Jessica</Button>
                </div>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {personCard}
            </ReactPrism>


            <div className="card card-time">
                <div className="icon">
                    <Image src={sunIco} alt="" />
                </div>
                <div className="content">
                    <p className="text-primary m-0">Time to leave</p>
                    <span className="span-time">08:00</span>
                    <p className="timer">Estimate arriving in 34 min</p>
                </div>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {timeCard}
            </ReactPrism>

            <div className="card extras-3">
                <div className="icon">
                    <div>
                        <embed src={personIcon} type="" />
                        <p className="p-12 text-secondary">New Leads</p>
                    </div>
                    <div>
                        <embed src={groupDots} type="" />
                    </div>
                </div>
                <ProgressBar now={55} variant="primary" />
                <div className="content">
                    <div>
                        <span className="num-big">205</span>
                        <p className="p-12 text-primary">UP</p>
                    </div>
                    <div className="second">
                        <p className="p-12 text-secondary">Goal</p><span className="num-small">500</span>
                    </div>
                </div>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {newLeads}
            </ReactPrism>

            <h2>Flight ticket</h2>
            <p>For barcode part, install React Barcodes (npm install react-barcodes --save). </p>
            <br />

            <div className="card card-flight-ticket">
                <div className="flight-info">
                    <div>
                        <p className="city">LA</p>
                        <p className="time">09:45</p>
                        <p className="date">April 27, US</p>
                    </div>
                    <div className="flight-icon">
                        <embed src={iconFliht} type="" />
                    </div>
                    <div>
                        <p className="city">VEN</p>
                        <p className="time">12:05</p>
                        <p className="date">April 27, IT</p>
                    </div>
                </div>
                <div className="passenger">
                    <p className="label">Passenger</p>
                    <p className="name">Jessica Hike</p>
                    <div className="circle circle-1"></div>
                    <div className="circle circle-2"></div>
                </div>
                <div className="barcode">
                    <svg ref={inputRef} />
                </div>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {ticket}
            </ReactPrism>

            <div className="card extras-4">
                <div className="info">
                    <div className="image">
                        <div><Image src={examplePerson2} alt="" /></div>
                    </div>
                    <div className="name">
                        <p className="text-secondary">Jessica Hike</p>
                        <p className="p-12 text-primary">UI/UX Designer</p>
                    </div>
                </div>
                <div className="date">
                    <p className="p-12 text-primary">11:45 AM</p>
                    <div className="notifications">
                        <span>3</span>
                    </div>
                </div>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {graph4}
            </ReactPrism>

            <div className="card extras-5">
                <div className="info">
                    <p>UI/UX Designer</p>
                    <span className="price">$58,000</span>
                    <p className="status"><span>5.2%</span> Up from past week</p>
                </div>
                <div>
                    <embed src={iconGraph} type="" />
                </div>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {graph5}
            </ReactPrism>

            <div className="card extras-6 bg-primary">
                <div className="info">
                    <p className="">Bounce rate</p>
                    <span className="status">%34</span>
                </div>
                <div className="icon">
                    <p>Last 30 days</p>
                    <div className="graph">
                        <embed src={arrowUpRight} type="" />
                    </div>
                </div>
            </div>
            <br />
            <div className="card extras-6 bg-danger">
                <div className="info">
                    <p className="">Bounce rate</p>
                    <span className="status">%34</span>
                </div>
                <div className="icon">
                    <p>Last 30 days</p>
                    <div className="graph">
                        <embed src={arrowUpRight} type="" />
                    </div>
                </div>
            </div>
            <br />
            <div className="card extras-6 bg-success">
                <div className="info">
                    <p className="">Bounce rate</p>
                    <span className="status">%34</span>
                </div>
                <div className="icon">
                    <p>Last 30 days</p>
                    <div className="graph">
                        <embed src={arrowUpRight} type="" />
                    </div>
                </div>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {cardExtras}
            </ReactPrism>

            <div className="card new-admin-design">
                <div className="top-section">
                    <div className="image">
                        <Image src={newAdmin} alt="" />
                    </div>
                    <div className="content">
                        <p className="title text-secondary">New admin Design</p>
                        <p className="text text-secondary">It will be as simple as Occidental</p>
                        <div className="admin-images">
                            <Image src={imgSmall1} alt="" />
                            <Image src={imgSmall2} alt="" />
                            <Image src={hImage} alt="" />
                        </div>

                    </div>
                </div>
                <div className="bottom-section">
                    <Button className="btn btn-primary btn-sm">Completed</Button>
                    <p className="text-secondary">15 Oct, 2020</p>
                </div>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {newAdminDesign}
            </ReactPrism>

            <Row className="charts">
                <Col sm={12} className="card chart-pie">
                    <div className="content">
                        <div>
                            <p className="p-14">Target Sales</p>
                            <p>300 Sales</p>
                        </div>
                        <div style={{ width: "60px" }}>
                            <Pie data={pieData} options={pieOptions} className="pie-chart" />
                        </div>
                    </div>
                </Col>
                <Col sm={12} className="card chart-line">
                    <p className="p-14">Target Sales <span>320 Followers</span></p>
                    <div>
                        <Line data={lineData} options={lineOptions} className="line-chart" />
                    </div>
                </Col>
            </Row>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {charts}
            </ReactPrism>
        </>
    )
}

export default ExtrasView