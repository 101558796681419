import React, { useState } from 'react'
import { Form, Button} from 'react-bootstrap'

const LoginView = () => {

    const [username, setUsername] = useState("");
    const [pass, setPass] = useState("");

    const [usernameRed, setUsernameRed] = useState("");
    const [passRed, setPassRed] = useState("");

    const [usernameWarning, setUsernameWarning] = useState("hide");
    const [passWarning, setPassWarning] = useState("hide");

    const emptyCheck = () => {
        if (username === "") {
            setUsernameWarning("show")
            setUsernameRed("error")
        } else {
            setUsernameWarning("hide")
            setUsernameRed("")
        }

        if (pass === "") {
            setPassWarning("show")
            setPassRed("error")
        } else {
            setPassWarning("hide")
            setPassRed("")
        }
    }

    return (
        <>
            <div className="login-wrapper">
                <div className="login-container">
                    <h2 className="login-header">trendy.</h2>
                    <Form>
                        <Form.Control className={`${usernameRed}`} placeholder="Enter username" value={username} onChange={(e) => setUsername(e.target.value)} />
                        <div className={`username-input ${usernameWarning}`}>Please enter Your Username!</div>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Control className={`${passRed}`} type="password" placeholder="Password" value={pass} onChange={(e) => setPass(e.target.value)} />
                            <div className={`password-input ${passWarning}`}>Please enter Your Password!</div>
                        </Form.Group>
                        <div className="container-switch">
                            <div className="pair primary-checked" >
                                <div className="toggle-small">
                                    <label className="toggle-switch round-primary">
                                        <input type="checkbox" />
                                        <div className="slider"></div>
                                    </label>
                                    <p>Remember me</p>
                                </div>
                            </div>
                        </div>
                        <Button variant="success" onClick={() => emptyCheck()}>Log In</Button>
                    </Form>

                </div>
            </div >

            <br />

        </>
    )
}

export default LoginView