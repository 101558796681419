import React, { useEffect, useState, useRef } from 'react'
import { Form, Button } from 'react-bootstrap'


const SelectComponent = (props) => {

    function selectSize(size) {
        switch (size) {
            case "lg":
                return "btn-lg"
            case "sm":
                return "btn-sm"
            default:
                return ""
        }
    }

    const originalSelect = useRef(null);
    const [selectDisplay, setSelectDisplay] = useState(false);

    const selectCloning = () => {

        function styledOnSingleSelectClick(e) {

            var element = e.target.parentNode.parentNode;
            var previousSibling = element.previousSibling;
            while (previousSibling && previousSibling.nodeType != 1) {
                previousSibling = previousSibling.previousSibling;
            }

            previousSibling.value = e.target.getAttribute('value');
            var options = element.children[0].children;
            for (var i = 0; i < options.length; i++) {
                options[i].removeAttribute('selected');
                options[i].classList.remove('selected');
            }

            const originalSelectOptions = element.parentNode.previousSibling.children

            e.target.setAttribute('selected', true);
            e.target.classList.add('selected');

            for (var i = 0; i < originalSelectOptions.length; i++) {

                if (originalSelectOptions[i].value == e.target.value) {
                    originalSelectOptions[i].setAttribute('selected', true);
                } else {
                    originalSelectOptions[i].removeAttribute('selected');
                }
            }

            element.parentNode.previousSibling.value = e.target.getAttribute('value')

            e.target.parentNode.parentNode.parentNode.children[0].innerText = e.target.innerText;
            toggleSelect();

            if (props.onChange) {
                props.onChange(e)
            }
        }

        function toggleSelect() {
            setSelectDisplay(state => !state)
        }

        function styledOnMultiselectClick(e) {
            var element = e.target.parentNode;

            var previousSibling = element.parentNode.previousSibling;

            while (previousSibling && previousSibling.nodeType != 1) {
                previousSibling = previousSibling.previousSibling;
            }

            for (var i = 0; i < previousSibling.children.length; i++) {
                if (previousSibling.options[i].getAttribute('selected') !== null) {
                    if (e.target.getAttribute('value') == previousSibling.children[i].value) {
                        previousSibling.options[i].removeAttribute('selected');
                    }
                } else {
                    if (e.target.getAttribute('value') == previousSibling.children[i].value) {
                        previousSibling.options[i].setAttribute('selected', true);
                    }
                }
            }


            if (e.target.getAttribute('selected') !== null) {
                e.target.removeAttribute('selected');
            } else {
                e.target.setAttribute('selected', true);

            }
        }

        if (props.type === "multiple" && !originalSelect.initialised) {

            return (
                <div className="select-container">
                    <div className="wrapper">
                        {props.children.map((option) =>
                            <Button value={option.props.value} onClick={styledOnMultiselectClick}>{option.props.children}</Button>
                        )}
                    </div>
                </div>
            )


        } else if (props.type === "single" && !originalSelect.initialised && props.rounded) {

            return (
                <div className="section-select">
                    <Button className={`select-options rounded-pill ${selectSize(props.size)}`} onClick={toggleSelect}>Choose</Button>
                    <div className="select-wrapper" style={selectDisplay ? { display: "block" } : { display: "none" }}>
                        <div className="select">
                            {props.children.map((option) =>
                                <Button value={option.props.value} onClick={styledOnSingleSelectClick}>{option.props.children}</Button>
                            )}
                        </div>
                    </div>
                </div>
            )

        } else if (props.type === "single" && !originalSelect.initialised && !props.rounded) {

            return (
                <div className="section-select">
                    <Button className={`select-options rounded-pill ${selectSize(props.size)}`} onClick={toggleSelect}>Choose</Button>
                    <div className="select-wrapper" style={selectDisplay ? { display: "block" } : { display: "none" }}>
                        <div className="select">
                            {props.children.map((option) =>
                                <Button value={option.props.value} onClick={styledOnSingleSelectClick}>{option.props.children}</Button>
                            )}
                        </div>
                    </div>
                </div>
            )

        }
    }

    return (
        <>

            <Form.Select ref={originalSelect} hidden value={props.value}>
                {props.children.map((option) => {
                    return <option value={option.props.value}>{option.props.children}</option>
                })}
            </Form.Select>


            {selectCloning()}

        </>
    )
}

export default SelectComponent