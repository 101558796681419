import React from 'react';
import { Card, ListGroup, ListGroupItem, Button, Nav, CardGroup, Row, Col } from 'react-bootstrap';

import overlayImg from '../assets/NoPath_Overlay.png'
import cardComputer from '../assets/card_computer.jpg'
import imagePlaceholder from '../assets/image-placeholder.svg'


import ReactPrism from '@versant-digital/react-prismjs';

const basicExample = `<Card style={{ width: '18rem' }}>
    <Card.Img variant="top" src={cardComputer} />
    <Card.Body>
    <Card.Title>Card Title</Card.Title>
    <Card.Text>
        Some quick example text to build on the card title and make up the bulk of
        the card's content.
    </Card.Text>
    <Button variant="primary">Go somewhere</Button>
    </Card.Body>
</Card>`

const card = `<Card style={{ width: '18rem' }}>
    <Card.Body>
        <Card.Title>Jessica Hike</Card.Title>
        <Card.Text>
            Some quick example text to build on the card title
            and make up the bulk of the card's content.
        </Card.Text>
        <Card.Link href="#">Card Link</Card.Link>
        <Card.Link href="#">Another Link</Card.Link>
    </Card.Body>
</Card>`

const cardButton = `<Card style={{ width: '18rem' }}>
    <Card.Body>
        <Card.Title>Jessica Hike</Card.Title>
        <Card.Text>
            Some quick example text to build on the card title
            and make up the bulk of the card's content.
        </Card.Text>
        <Button variant="primary rounded-pill">Contact Jessica</Button>
    </Card.Body>
</Card>`

const imagesCard = `<Card style={{ width: '18rem' }}>
    <Card.Img variant="top" src={cardComputer} />
    <Card.Body>
        <Card.Title>Card Title</Card.Title>
        <Card.Text>
            Some quick example text to build on the card title and make up the bulk of
            the card's content.
        </Card.Text>
        <Button variant="primary rounded-pill">Guide</Button>
        <Button variant="light rounded-pill">Directions</Button>
    </Card.Body>
</Card>`

const textDown = `<Card style={{ width: '18rem' }}>
    <Card.Img variant="top" src={cardComputer} />
    <Card.Body>
        <Card.Title>Card Title</Card.Title>
        <Card.Text>
            Some quick example text to build on the card title and make up the bulk of
            the card's content.
        </Card.Text>
    </Card.Body>
</Card>`

const textUp = `<Card style={{ width: '18rem' }}>
    <Card.Body>
        <Card.Title>Card Title</Card.Title>
        <Card.Text>
            Some quick example text to build on the card title and make up the bulk
            of the card's content.
        </Card.Text>
    </Card.Body>
    <Card.Img variant="bottom" src={cardComputer}/>
</Card>`

const imageOverlay = `<Card style={{ width: '18rem' }}>
    <Card.Img src={overlayImg} alt="Card image" />
    <Card.ImgOverlay>
        <Card.Title>Card title</Card.Title>
        <Card.Text>
            This is a wider card with supporting text below as a natural lead-in to
            additional content. This content is a little bit longer.
        </Card.Text>
    </Card.ImgOverlay>
</Card>`

const horizontal = `<Card className="card mb-3" style={{ maxWidth: "540px" }}>
    <div className="row g-0">
        <div className="col-md-4">
            <Card.Img src={cardComputer} className="img-fluid rounded-start" alt="..." />
        </div>
        <div className="col-md-8">
            <Card.Body className="card-body">
                <Card.Title className="card-title">Card title</Card.Title>
                <Card.Text className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</Card.Text>
            </Card.Body>
        </div>
    </div>
</Card>`

const kitchen = `<Card style={{ width: '18rem' }}>
    <Card.Img variant="top" src={cardComputer} />
    <Card.Body>
        <Card.Title>Card Title</Card.Title>
        <Card.Text variant="primary">
            Some quick example text to build on the card title and make up the bulk of
            the card's content.
        </Card.Text>
    </Card.Body>
    <ListGroup className="list-group-flush">
        <ListGroupItem>Cras justo odio</ListGroupItem>
        <ListGroupItem>Dapibus ac facilisis in</ListGroupItem>
        <ListGroupItem>Vestibulum at eros</ListGroupItem>
    </ListGroup>
    <Card.Body>
        <Card.Link href="#">Card Link</Card.Link>
        <Card.Link href="#">Another Link</Card.Link>
    </Card.Body>
</Card>`

const header1 = `<Card>
    <Card.Header as="h5">Featured</Card.Header>
    <Card.Body>
        <Card.Title>Special title treatment</Card.Title>
        <Card.Text>
            With supporting text below as a natural lead-in to additional content.
        </Card.Text>
        <Button variant="primary">Go somewhere</Button>
    </Card.Body>
</Card>`

const header2 = `<Card>
    <Card.Header>Quote</Card.Header>
    <Card.Body>
        <blockquote className="blockquote mb-0">
            <p>
                {' '}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                erat a ante.{' '}
            </p>
            <footer className="blockquote-footer">
                Someone famous in <cite title="Source Title">Source Title</cite>
            </footer>
        </blockquote>
    </Card.Body>
</Card>`

const header3 = `<Card className="text-center">
    <Card.Header>Featured</Card.Header>
    <Card.Body>
        <Card.Title>Special title treatment</Card.Title>
        <Card.Text>
            With supporting text below as a natural lead-in to additional content.
        </Card.Text>
        <Button variant="primary">Go somewhere</Button>
    </Card.Body>
    <Card.Footer className="text-muted">2 days ago</Card.Footer>
</Card>`

const navigation = `<Card>
    <Card.Header>
        <Nav variant="tabs" defaultActiveKey="#first">
            <Nav.Item>
                <Nav.Link href="#first">Active</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="#link">Link</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="#disabled" disabled>
                    Disabled
                </Nav.Link>
            </Nav.Item>
        </Nav>
    </Card.Header>
    <Card.Body>
        <Card.Title>Special title treatment</Card.Title>
        <Card.Text>
            With supporting text below as a natural lead-in to additional content.
        </Card.Text>
        <Button variant="primary">Go somewhere</Button>
    </Card.Body>
</Card>`

const backgroundColor = `{[
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
].map((variant, idx) => (
    <Card
        bg={variant.toLowerCase()}
        key={idx}
        text={variant.toLowerCase() === 'light' ? 'dark' : 'white'}
        style={{ width: '18rem' }}
        className="mb-2"
    >
        <Card.Header>Header</Card.Header>
        <Card.Body>
            <Card.Title>{variant} Card Title </Card.Title>
            <Card.Text>
                Some quick example text to build on the card title and make up the bulk
                of the card's content.
            </Card.Text>
        </Card.Body>
    </Card>
))};`

const borderColor = `<Card border="primary" style={{ width: '18rem' }}>
    <Card.Header>Header</Card.Header>
    <Card.Body>
        <Card.Title>Primary Card Title</Card.Title>
        <Card.Text>
            Some quick example text to build on the card title and make up the bulk
            of the card's content.
        </Card.Text>
    </Card.Body>
</Card>`

const cardGroup = `<CardGroup>
    <Card>
        <Card.Img variant="top" src={cardComputer} />
        <Card.Body>
            <Card.Title>Card title</Card.Title>
            <Card.Text>
                Some quick example text to build on the card title and make up the bulk of the card's content.
            </Card.Text>
        </Card.Body>
    </Card>
    <Card>
        <Card.Img variant="top" src={cardComputer} />
        <Card.Body>
            <Card.Title>Card title</Card.Title>
            <Card.Text>
                Some quick example text to build on the card title and make up the bulk of the card's content.
            </Card.Text>
        </Card.Body>
    </Card>
    <Card>
        <Card.Img variant="top" src={cardComputer} />
        <Card.Body>
            <Card.Title>Card title</Card.Title>
            <Card.Text>
                Some quick example text to build on the card title and make up the bulk of the card's content.
            </Card.Text>
        </Card.Body>
    </Card>
</CardGroup>`

const cardGroup2 = `<CardGroup>
    <Card>
        <Card.Img variant="top" src={cardComputer} />
        <Card.Body>
            <Card.Title>Card title</Card.Title>
            <Card.Text>
                Some quick example text to build on the card title and make up the bulk of the card's content.
            </Card.Text>
        </Card.Body>
        <Card.Footer>
            <small className="text-muted">Last updated 3 mins ago</small>
        </Card.Footer>
    </Card>
    <Card>
        <Card.Img variant="top" src={cardComputer} />
        <Card.Body>
            <Card.Title>Card title</Card.Title>
            <Card.Text>
                Some quick example text to build on the card title and make up the bulk of the card's content.
            </Card.Text>
        </Card.Body>
        <Card.Footer>
            <small className="text-muted">Last updated 3 mins ago</small>
        </Card.Footer>
    </Card>
    <Card>
        <Card.Img variant="top" src={cardComputer} />
        <Card.Body>
            <Card.Title>Card title</Card.Title>
            <Card.Text>
                Some quick example text to build on the card title and make up the bulk of the card's content.
            </Card.Text>
        </Card.Body>
        <Card.Footer>
            <small className="text-muted">Last updated 3 mins ago</small>
        </Card.Footer>
    </Card>
</CardGroup>`
const cardGroup3 = `<Row xs={1} md={3} className="g-4">
    {Array.from({ length: 3 }).map((_, idx) => (
        <Col>
            <Card>
                <Card.Img variant="top" src={cardComputer} />
                <Card.Body>
                    <Card.Title>Card title</Card.Title>
                    <Card.Text>
                        Some quick example text to build on the card title and make up the bulk of the card's content.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    ))}
</Row>`

const cardGroup4 = `<Row xs={1} md={3} className="g-4">
    {Array.from({ length: 3 }).map((_, idx) => (
        <Col>
            <Card>
                <Card.Img variant="top" src={cardComputer} />
                <Card.Body>
                    <Card.Title>Card title</Card.Title>
                    <Card.Text>
                        Some quick example text to build on the card title and make up the bulk of the card's content.
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">Last updated 3 mins ago</small>
                </Card.Footer>
            </Card>
        </Col>
    ))}
</Row>`

const bodyCard = `<Card>
    <Card.Body>This is some text within a card body.</Card.Body>
</Card>`

const bodyCard2 = `<Card body>This is some text within a card body.</Card>`

const imageCaps = `<Card>
    <Card.Img variant="top" src={imagePlaceholder} />
    <Card.Body>
        <Card.Text>
            Some quick example text to build on the card title and make up the bulk
            of the card's content.
        </Card.Text>
    </Card.Body>
    </Card>
    <br />
    <Card>
    <Card.Body>
        <Card.Text>
            Some quick example text to build on the card title and make up the bulk
            of the card's content.
        </Card.Text>
    </Card.Body>
    <Card.Img variant="bottom" src={imagePlaceholder}/>
</Card>`

const CardView = () => {
    return (
        <>

            <h1 className="title-section">Card</h1>
            <p>Bootstrap’s cards provide a flexible and extensible content container with multiple variants and options.</p>

            <h5 className="subtitle">Basic example</h5>

            <Card style={{ width: '18rem' }}>
                <Card.Img variant="top" src={cardComputer} />
                <Card.Body>
                    <Card.Title>Card Title</Card.Title>
                    <Card.Text>
                        Some quick example text to build on the card title and make up the bulk of
                        the card's content.
                    </Card.Text>
                    <Button variant="primary">Go somewhere</Button>
                </Card.Body>
            </Card>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {basicExample}
            </ReactPrism>

            <h5 className="subtitle">Content types</h5>
            <h5 className="subtitle">Body</h5>

            <p>Use <code>&lt;Card.Body&gt;</code> to pad content inside a <code>&lt;Card&gt;</code>.</p>

            <Card>
                <Card.Body>This is some text within a card body.</Card.Body>
            </Card>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {bodyCard}
            </ReactPrism>

            <p>Alternatively, you can use this shorthand version for Cards with body
                only, and no other children</p>

            <Card body>This is some text within a card body.</Card>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {bodyCard2}
            </ReactPrism>

            <h5 className="subtitle">Titles, text, and links</h5>

            <p>Using <code>&lt;Card.Title&gt;</code>, <code>&lt;Card.Subtitle&gt;</code>, and
                <code>&lt;Card.Text&gt;</code> inside the <code>&lt;Card.Body&gt;</code> will line them up nicely.
                <code>&lt;Card.Link&gt;</code>s are used to line up links next to each other.</p>

            <p><code>&lt;Card.Text&gt;</code> outputs <code>&lt;p&gt;</code> tags around the content, so you can
                use multiple <code>&lt;Card.Text&gt;</code>s to create separate paragraphs.</p>


            <Card style={{ width: '18rem' }}>
                <Card.Body>
                    <Card.Title>Jessica Hike</Card.Title>
                    <Card.Text>
                        Some quick example text to build on the card title
                        and make up the bulk of the card's content.
                    </Card.Text>
                    <Card.Link href="#">Card Link</Card.Link>
                    <Card.Link href="#">Another Link</Card.Link>
                </Card.Body>
            </Card>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {card}
            </ReactPrism>

            <div className="row-custom">
                <Card style={{ width: '18rem' }}>
                    <Card.Body>
                        <Card.Title>Jessica Hike</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                        </Card.Text>
                        <Button variant="primary">Contact Jessica</Button>
                    </Card.Body>
                </Card>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {cardButton}
            </ReactPrism>

            <h5 className="subtitle">Kitchen sink</h5>

            <Card style={{ width: '18rem' }}>
                <Card.Img variant="top" src={cardComputer} />
                <Card.Body>
                    <Card.Title>Card Title</Card.Title>
                    <Card.Text variant="primary">
                        Some quick example text to build on the card title and make up the bulk of
                        the card's content.
                    </Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                    <ListGroupItem>Cras justo odio</ListGroupItem>
                    <ListGroupItem>Dapibus ac facilisis in</ListGroupItem>
                    <ListGroupItem>Vestibulum at eros</ListGroupItem>
                </ListGroup>
                <Card.Body>
                    <Card.Link href="#">Card Link</Card.Link>
                    <Card.Link href="#">Another Link</Card.Link>
                </Card.Body>
            </Card>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {kitchen}
            </ReactPrism>

            <h5 className="subtitle">Header and footer</h5>

            <p>You may add a header by adding a <code>&lt;Card.Header&gt;</code> component.</p>

            <Card>
                <Card.Header as="h5">Featured</Card.Header>
                <Card.Body>
                    <Card.Title>Special title treatment</Card.Title>
                    <Card.Text>
                        With supporting text below as a natural lead-in to additional content.
                    </Card.Text>
                    <Button variant="primary">Go somewhere</Button>
                </Card.Body>
            </Card>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {header1}
            </ReactPrism>

            <p>A <code>&lt;CardHeader&gt;</code> can be styled by passing a heading element
                through the <code>&lt;as&gt;</code> prop</p>

            <Card className="text-center">
                <Card.Header as="h5">Featured</Card.Header>
                <Card.Body>
                    <Card.Title>Special title treatment</Card.Title>
                    <Card.Text>
                        With supporting text below as a natural lead-in to additional content.
                    </Card.Text>
                    <Button variant="primary">Go somewhere</Button>
                </Card.Body>
                <Card.Footer className="text-muted">2 days ago</Card.Footer>
            </Card>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {header3}
            </ReactPrism>

            <Card>
                <Card.Header as="h5">Quote</Card.Header>
                <Card.Body>
                    <blockquote className="blockquote mb-0">
                        <p>
                            {' '}
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                            erat a ante.{' '}
                        </p>
                        <footer className="blockquote-footer">
                            Someone famous in <cite title="Source Title">Source Title</cite>
                        </footer>
                    </blockquote>
                </Card.Body>
            </Card>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {header2}
            </ReactPrism>

            <h5 className="subtitle">Images</h5>

            <p>Cards include a few options for working with images. Choose from appending “image caps”
                at either end of a card, overlaying images
                with card content, or simply embedding the image in a card.</p>

            <h5 className="subtitle">Image caps</h5>

            <p>Similar to headers and footers, cards can include top and bottom “image caps”—images
                at the top or bottom of a card.</p>


            <Card>
                <Card.Img variant="top" src={imagePlaceholder} />
                <Card.Body>
                    <Card.Text>
                        Some quick example text to build on the card title and make up the bulk
                        of the card's content.
                    </Card.Text>
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Text>
                        Some quick example text to build on the card title and make up the bulk
                        of the card's content.
                    </Card.Text>
                </Card.Body>
                <Card.Img variant="bottom" src={imagePlaceholder} />
            </Card>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {imageCaps}
            </ReactPrism>


            <h5 className="subtitle">Image ovarlays</h5>

            <p>Turn an image into a card background and overlay your card’s text.
                Depending on the image, you may or may not need additional styles or utilities.</p>

            <Card style={{ width: '18rem' }}>
                <Card.Img src={overlayImg} alt="Card image" />
                <Card.ImgOverlay>
                    <Card.Title>Card title</Card.Title>
                    <Card.Text>
                        This is a wider card with supporting text below as a natural lead-in to
                        additional content. This content is a little bit longer.
                    </Card.Text>
                </Card.ImgOverlay>
            </Card>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {imageOverlay}
            </ReactPrism>

            <h5 className="subtitle">Navigation</h5>
            <p>Add some navigation to a card’s header (or block) with React Bootstrap’s
                <a href="../navs"> Nav</a> components.</p>
            <Card>
                <Card.Header>
                    <Nav variant="tabs" defaultActiveKey="#first">
                        <Nav.Item>
                            <Nav.Link href="#first">Active</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#">Link</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#disabled" disabled>
                                Disabled
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>
                <Card.Body>
                    <Card.Title>Special title treatment</Card.Title>
                    <Card.Text>
                        With supporting text below as a natural lead-in to additional content.
                    </Card.Text>
                    <Button variant="primary">Go somewhere</Button>
                </Card.Body>
            </Card>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {navigation}
            </ReactPrism>

            <h5 className="subtitle">Horizontal</h5>

            <p>Using a combination of grid and utility classes, cards can be made horizontal in a mobile-friendly and responsive way. In the example below, we remove the grid gutters with <code>.g-0</code> and use <code>.col-md-*</code> classes to make the card horizontal at the <code>md</code> breakpoint. Further adjustments may be needed depending on your card content.</p>

            <Card className="card mb-3 horizontal" style={{ maxWidth: "540px" }}>
                <div className="row g-0">
                    <div className="col-md-4">
                        <Card.Img src={cardComputer} className="img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <Card.Body className="card-body">
                            <Card.Title className="card-title">Card title</Card.Title>
                            <Card.Text className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</Card.Text>
                        </Card.Body>
                    </div>
                </div>
            </Card>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {horizontal}
            </ReactPrism>

            <h5 className="subtitle">Top and bottom</h5>

            <div className="row-custom">
                <Card style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={cardComputer} />
                    <Card.Body>
                        <Card.Title>Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk of
                            the card's content.
                        </Card.Text>
                        <Button variant="primary rounded-pill">Guide</Button>
                        <Button variant="light rounded-pill">Directions</Button>
                    </Card.Body>
                </Card>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {imagesCard}
            </ReactPrism>

            <div className="row-custom">
                <Card style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={cardComputer} />
                    <Card.Body>
                        <Card.Title>Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk of
                            the card's content.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {textDown}
            </ReactPrism>

            <div className="row-custom">
                <Card style={{ width: '18rem' }}>
                    <Card.Body>
                        <Card.Title>Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk
                            of the card's content.
                        </Card.Text>
                    </Card.Body>
                    <Card.Img variant="bottom" src={cardComputer} />
                </Card>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {textUp}
            </ReactPrism>

            <h1 className="title">Card Styles</h1>

            <h5 className="subtitle">Text variants</h5>

            <Card bg="dark"
                style={{ width: '18rem' }}
                className="mb-2"
            >
                <Card.Body>
                    <Card.Title>Card Title </Card.Title>
                    <Card.Text>
                        Some quick example text to build on the card title and make up the bulk
                        of the card's content.
                    </Card.Text>
                    <Button>Go somewhere</Button>
                </Card.Body>
            </Card>

            <h5 className="subtitle">Background color</h5>
            <p>You can change a card's appearance by changing their <code>&lt;bg&gt;</code>, and <code>&lt;text&gt;</code> props.</p>

            {[
                'Primary',
                'Secondary',
                'Success',
                'Danger',
                'Warning',
                'Info',
                'Light',
                'Dark',
            ].map((variant, idx) => (
                <Card
                    bg={variant.toLowerCase()}
                    key={idx}
                    style={{ width: '18rem' }}
                    className="mb-2"
                >
                    <Card.Header>Header</Card.Header>
                    <Card.Body>
                        <Card.Title>{variant} Card Title </Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk
                            of the card's content.
                        </Card.Text>
                    </Card.Body>
                </Card>
            ))}

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {backgroundColor}
            </ReactPrism>

            <h5 className="subtitle">Border Color</h5>

            <>
                <Card border="primary" style={{ width: '18rem' }}>
                    <Card.Header>Header</Card.Header>
                    <Card.Body>
                        <Card.Title>Primary Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk
                            of the card's content.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <br />

                <Card border="secondary" style={{ width: '18rem' }}>
                    <Card.Header>Header</Card.Header>
                    <Card.Body>
                        <Card.Title>Secondary Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk
                            of the card's content.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <br />

                <Card border="success" style={{ width: '18rem' }}>
                    <Card.Header>Header</Card.Header>
                    <Card.Body>
                        <Card.Title>Success Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk
                            of the card's content.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <br />

                <Card border="danger" style={{ width: '18rem' }}>
                    <Card.Header>Header</Card.Header>
                    <Card.Body>
                        <Card.Title>Danger Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk
                            of the card's content.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <br />

                <Card border="warning" style={{ width: '18rem' }}>
                    <Card.Header>Header</Card.Header>
                    <Card.Body>
                        <Card.Title>Warning Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk
                            of the card's content.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <br />

                <Card border="info" style={{ width: '18rem' }}>
                    <Card.Header>Header</Card.Header>
                    <Card.Body>
                        <Card.Title>Info Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk
                            of the card's content.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <br />

                <Card border="light" style={{ width: '18rem' }}>
                    <Card.Header>Header</Card.Header>
                    <Card.Body>
                        <Card.Title>Light Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk
                            of the card's content.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <br />

                <Card border="dark" style={{ width: '18rem' }}>
                    <Card.Header>Header</Card.Header>
                    <Card.Body>
                        <Card.Title>Dark Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk
                            of the card's content.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <br />

            </>
            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {borderColor}
            </ReactPrism>

            <h5 className="subtitle">Card layout</h5>
            <h5 className="subtitle">Card groups</h5>

            <CardGroup>
                <Card>
                    <Card.Img variant="top" src={cardComputer} />
                    <Card.Body>
                        <Card.Title>Card title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={cardComputer} />
                    <Card.Body>
                        <Card.Title>Card title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={cardComputer} />
                    <Card.Body>
                        <Card.Title>Card title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
          

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {cardGroup}
            </ReactPrism>

            <CardGroup>
                <Card>
                    <Card.Img variant="top" src={cardComputer} />
                    <Card.Body>
                        <Card.Title>Card title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted">Last updated 3 mins ago</small>
                    </Card.Footer>
                </Card>
                <Card>
                    <Card.Img variant="top" src={cardComputer} />
                    <Card.Body>
                        <Card.Title>Card title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted">Last updated 3 mins ago</small>
                    </Card.Footer>
                </Card>
                <Card>
                    <Card.Img variant="top" src={cardComputer} />
                    <Card.Body>
                        <Card.Title>Card title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted">Last updated 3 mins ago</small>
                    </Card.Footer>
                </Card>
            </CardGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {cardGroup2}
            </ReactPrism>

            <h5 className="subtitle">Grid cards</h5>

            <p>Use <code>Row</code>'s <a href="/layout/grid/#row-layout-col-sizing">grid column</a> props to control how many cards to show per row.</p>

            <Row xs={1} md={3} className="g-4">
                {Array.from({ length: 3 }).map((_, idx) => (
                    <Col>
                        <Card>
                            <Card.Img variant="top" src={cardComputer} />
                            <Card.Body>
                                <Card.Title>Card title</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the bulk of the card's content.
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">Last updated 3 mins ago</small>
                            </Card.Footer>
                        </Card>
                    </Col>
                ))}
            </Row>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {cardGroup4}
            </ReactPrism>

            <Row xs={1} md={3} className="g-4">
                {Array.from({ length: 3 }).map((_, idx) => (
                    <Col>
                        <Card>
                            <Card.Img variant="top" src={cardComputer} />
                            <Card.Body>
                                <Card.Title>Card title</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the bulk of the card's content.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {cardGroup3}
            </ReactPrism>

        </>
    );
}

export default CardView;