import React, { useState } from 'react';
import { Carousel, Image } from 'react-bootstrap';
import carouselImg from '../assets/card_computer.jpg'
import slide1 from '../assets/slide1.svg'
import slide2 from '../assets/slide2.svg'
import slide3 from '../assets/slide3.svg'
import ReactPrism from '@versant-digital/react-prismjs';

const carousel = `<Carousel indicators={false}>
    <Carousel.Item indicators="false">
        <Image
            className="d-block w-100"
            src={carouselImg}
            alt="First slide"
        />
    </Carousel.Item>
    <Carousel.Item>
        <Image
            className="d-block w-100"
            src={carouselImg}
            alt="Second slide"
        />
    </Carousel.Item>
    <Carousel.Item>
        <Image
            className="d-block w-100"
            src={carouselImg}
            alt="Third slide"
        />
    </Carousel.Item>
</Carousel>`


const controlled = `const [index, setIndex] = useState(0);
const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
};
  
return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src="holder.js/800x400?text=First slide&bg=373940"
        alt="First slide"
        />
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src="holder.js/800x400?text=Second slide&bg=282c34"
        alt="Second slide"
        />
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src="holder.js/800x400?text=Third slide&bg=20232a"
        alt="Third slide"
        />
    </Carousel.Item>
    </Carousel>
);`

const carousel3 = `<Carousel>
    <Carousel.Item>
        <Image
            className="d-block w-100"
            src={carouselImg}
            alt="First slide"
        />
        <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <Image
            className="d-block w-100"
            src={carouselImg}
            alt="Second slide"
        />

        <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <Image
            className="d-block w-100"
            src={carouselImg}
            alt="Third slide"
        />

        <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
    </Carousel.Item>
</Carousel>`

const carousel4 = `<div className="indicators-bottom-wrapper">
    <Carousel className="indicators-bottom">
        <Carousel.Item>
            <Image
                className="d-block w-100"
                src={carouselImg}
                alt="First slide"
            />
            <Carousel.Caption className="carousel-caption-top" >
                <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <Image
                className="d-block w-100"
                src={carouselImg}
                alt="Second slide"
            />

            <Carousel.Caption className="carousel-caption-top">
                <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <Image
                className="d-block w-100"
                src={carouselImg}
                alt="Third slide"
            />

            <Carousel.Caption className="carousel-caption-top">
                <h3>Third slide label</h3>
                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
            </Carousel.Caption>
        </Carousel.Item>
    </Carousel>
</div>`

const dark = `<Carousel variant="dark">
    <Carousel.Item>
        <img
            className="d-block w-100"
            src={slide1}
            alt="First slide"
        />
        <Carousel.Caption>
            <h5>First slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
            className="d-block w-100"
            src={slide2}
            alt="Second slide"
        />
        <Carousel.Caption>
            <h5>Second slide label</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
            className="d-block w-100"
            src={slide3}
            alt="Third slide"
        />
        <Carousel.Caption>
            <h5>Third slide label</h5>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
    </Carousel.Item>
</Carousel>`

const CarouselView = () => {


    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <>
            <h1 className="title-section">Carousels</h1>
            <p>
                A slideshow component for cycling through elements—images or slides of
                text—like a carousel.
            </p>

            <h5 className="subtitle">Example</h5>
            <p>
                Carousels don’t automatically normalize slide dimensions. As such,
                you may need to use additional utilities or custom styles to appropriately size content.
                While carousels support previous/next controls and indicators,
                they’re not explicitly required. Add and customize as you see fit.
            </p>

            <Carousel indicators={false}>
                <Carousel.Item indicators="false">
                    <Image
                        className="d-block w-100"
                        src={carouselImg}
                        alt="First slide"
                    />

                </Carousel.Item>
                <Carousel.Item>
                    <Image
                        className="d-block w-100"
                        src={carouselImg}
                        alt="Second slide"
                    />

                </Carousel.Item>
                <Carousel.Item>
                    <Image
                        className="d-block w-100"
                        src={carouselImg}
                        alt="Third slide"
                    />

                </Carousel.Item>
            </Carousel>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {carousel}
            </ReactPrism>


            <h5 className="subtitle">Controlled</h5>

            <p>You can also <em>control</em> the Carousel state, via the
                <code> activeIndex</code> prop and <code>onSelect</code> handler.</p>

            <Carousel activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={carouselImg}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={carouselImg}
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={carouselImg}
                        alt="Third slide"
                    />
                </Carousel.Item>
            </Carousel>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {controlled}
            </ReactPrism>

            <br />
            <Carousel>
                <Carousel.Item>
                    <Image
                        className="d-block w-100"
                        src={carouselImg}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                        className="d-block w-100"
                        src={carouselImg}
                        alt="Second slide"
                    />

                    <Carousel.Caption>
                        <h3>Second slide label</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                        className="d-block w-100"
                        src={carouselImg}
                        alt="Third slide"
                    />

                    <Carousel.Caption>
                        <h3>Third slide label</h3>
                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {carousel3}
            </ReactPrism>
            <br />
            <div className="indicators-bottom-wrapper">
                <Carousel className="indicators-bottom">
                    <Carousel.Item>
                        <Image
                            className="d-block w-100"
                            src={carouselImg}
                            alt="First slide"
                        />
                        <Carousel.Caption className="carousel-caption-top" >
                            <h3>First slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image
                            className="d-block w-100"
                            src={carouselImg}
                            alt="Second slide"
                        />

                        <Carousel.Caption className="carousel-caption-top">
                            <h3>Second slide label</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image
                            className="d-block w-100"
                            src={carouselImg}
                            alt="Third slide"
                        />

                        <Carousel.Caption className="carousel-caption-top">
                            <h3>Third slide label</h3>
                            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {carousel4}
            </ReactPrism>

            <h5 className="subtitle">Dark variant</h5>

            <p>Add <code>variant="dark"</code> to the <code>Carousel</code> for darker controls, indicators, and captions.</p>

            <Carousel variant="dark">
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slide1}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h5>First slide label</h5>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slide2}
                        alt="Second slide"
                    />
                    <Carousel.Caption>
                        <h5>Second slide label</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slide3}
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                        <h5>Third slide label</h5>
                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {dark}
            </ReactPrism>

        </>
    );
}

export default CarouselView;