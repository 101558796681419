import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { Button, Image } from 'react-bootstrap';
import 'react-calendar/dist/Calendar.css';

import calendarIco from '../assets/calendar-ico.svg'
import callendarArrow from '../assets/calendar-arrow.svg'
import callendarArrowBlack from '../assets/calendar-black-arrow.svg'

const DatePickerView = () => {

    const [value, onChange] = useState(new Date());

    return (
        <>
            <h1 className="title-section">Datepicker</h1>

            <p>
                For this component you need to install React Calendar
                (npm install react-calendar)
            </p>
            <br />

            <Calendar
                onChange={onChange}
                value={value}
            />

            <br />

            <div className="calendar-banner">
                <Image src={calendarIco} alt="" />

                <div className="calendar-banner-content">
                    <div className="callendar-banner-time">
                        10:00-11:00
                    </div>
                    <div className="callendar-banner-text">
                        Wednesday, June 2021
                    </div>
                </div>
            </div>

            <br />

            <div className="calendar-banner">
                <Image src={calendarIco} alt="" />

                <div className="calendar-banner-content">
                    <div className="callendar-banner-time">
                        10:00-11:00
                    </div>
                    <div className="callendar-banner-text">
                        Wednesday, June 2021
                    </div>
                </div>
                <Button variant="info"><Image src={callendarArrow} alt="" /></Button>
            </div>

            <br />



            <div className="calendar-banner">
                <div className="date-number">12</div>

                <div className="date-text">
                    <div className="day-mark">Today</div>
                    <div className="date-day">Tuesday</div>
                </div>

                <Button variant="light"><Image src={callendarArrowBlack} alt="" /></Button>
            </div>
            <br />
            <div className="small-date-card">
                <div className="small-date-number">23</div>
                <div className="day-tag">Mon</div>
            </div>

        </>
    );
}

export default DatePickerView;