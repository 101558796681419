import React from 'react'
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap'
import ReactPrism from '@versant-digital/react-prismjs';

const breadcrumbExample = `<Breadcrumb>
    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
</Breadcrumb>

<Breadcrumb>
    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
    <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
        Library
    </Breadcrumb.Item>
</Breadcrumb>

<Breadcrumb>
    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
    <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
        Library
    </Breadcrumb.Item>
    <Breadcrumb.Item active>Data</Breadcrumb.Item>
</Breadcrumb>`

const BreadcrumbView = () => {
    return (
        <>
            <h1 className="title-section">Breadcrumb</h1>

            <p>Indicate the current page’s location within a navigational
                hierarchy that automatically adds separators via CSS. Add <code>active </code>
                prop to active  <code>Breadcrumb.Item </code>. Do not
                set both  <code>active </code> and  <code>href </code> attributes. <code>active </code> overrides  <code>href </code> and  <code>span </code>
                element is rendered instead of  <code>a </code>.</p>

            <h5 className="subtitle">Example</h5>


            <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            </Breadcrumb>

            <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                    Library
                </Breadcrumb.Item>
            </Breadcrumb>

            <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                    Library
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Data</Breadcrumb.Item>
            </Breadcrumb>


            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {breadcrumbExample}
            </ReactPrism>
        </>
    );
}

export default BreadcrumbView;