import React from 'react';
import { Button, ButtonGroup, ButtonToolbar, DropdownButton, Dropdown, InputGroup, FormControl, Image } from 'react-bootstrap';
import ReactPrism from '@versant-digital/react-prismjs';

import fastForward from '../assets/fast-forward.svg'
import play from '../assets/awesome-play.svg'
import stop from '../assets/awesome-stop.svg'
import fastForward2 from '../assets/awesome-fast-forward.svg'
import right from '../assets/awesome-right.svg'
import left from '../assets/awesome-left.svg'
import minus from '../assets/minus.svg'
import plus from '../assets/plus.svg'

const exampleGroup = `<ButtonGroup aria-label="Basic example">
    <Button variant="primary">Left</Button>
    <Button variant="primary">Middle</Button>
    <Button variant="primary">Right</Button>
</ButtonGroup>`

const groupSizing = `<div className="btn-group-wrapper">
    <ButtonGroup size="lg">
        <Button variant="primary">Left</Button>
        <Button variant="primary">Middle</Button>
        <Button variant="primary">Right</Button>
    </ButtonGroup>
    <br />
    <ButtonGroup size="md">
        <Button variant="primary">Left</Button>
        <Button variant="primary">Middle</Button>
        <Button variant="primary">Right</Button>
    </ButtonGroup>
    <br />
    <ButtonGroup size="sm">
        <Button variant="primary">Left</Button>
        <Button variant="primary">Middle</Button>
        <Button variant="primary">Right</Button>
    </ButtonGroup>
</div>`

const nesting = `<ButtonGroup>
    <Button variant="light">1</Button>
    <Button variant="light">2</Button>
    <DropdownButton as={ButtonGroup} title="Dropdown" id="bg-nested-dropdown" variant="light">
        <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
        <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
    </DropdownButton>
</ButtonGroup>`

const verticalGroup = `<ButtonGroup vertical>
    <Button variant="light">Button</Button>
    <Button variant="light">Button</Button>
    <Button variant="light">Button</Button>
    <Button variant="light">Button</Button>
    <Button variant="light">Button</Button>
</ButtonGroup>`

const buttonToolbar = `<ButtonToolbar className="mlr-20">
<ButtonGroup>
    <Button variant="light">1</Button>
    <Button variant="light">2</Button>
    <Button variant="light">3</Button>
    <Button variant="light">4</Button>
</ButtonGroup>
<ButtonGroup>
    <Button variant="light">5</Button>
    <Button variant="light">6</Button>
    <Button variant="light">7</Button>
</ButtonGroup>
<ButtonGroup>
    <Button variant="light">8</Button>
</ButtonGroup>
</ButtonToolbar>`

const exampleLightGroup = `<ButtonGroup aria-label="Basic example">
    <Button variant="light">Left</Button>
    <Button variant="light">Middle</Button>
    <Button variant="light">Right</Button>
</ButtonGroup>
`
const groupSizingLight = `<div className="btn-group-wrapper">
    <ButtonGroup size="lg">
        <Button variant="light">Left</Button>
        <Button variant="light">Middle</Button>
        <Button variant="light">Right</Button>
    </ButtonGroup>
    </div>
    <br />
    <div className="btn-group-wrapper">
    <ButtonGroup size="md">
        <Button variant="light">Left</Button>
        <Button variant="light">Middle</Button>
        <Button variant="light">Right</Button>
    </ButtonGroup>
    </div>
    <br />
    <div className="btn-group-wrapper">
    <ButtonGroup size="sm">
        <Button variant="light">Left</Button>
        <Button variant="light">Middle</Button>
        <Button variant="light">Right</Button>
    </ButtonGroup>
</div>
`
const buttonToolbar2=`<ButtonToolbar className="mb-3" aria-label="Toolbar with Button groups">
    <ButtonGroup className="me-2" aria-label="First group">
        <Button variant="light">1</Button>{' '}
        <Button variant="light">2</Button>{' '}
        <Button variant="light">3</Button>{' '}
        <Button variant="light">4</Button>
    </ButtonGroup>
    <InputGroup>
        <InputGroup.Text id="btnGroupAddon">@</InputGroup.Text>
        <FormControl
            type="text"
            placeholder="Input group example"
            aria-label="Input group example"
            aria-describedby="btnGroupAddon"
        />
    </InputGroup>
    </ButtonToolbar>

    <ButtonToolbar
    className="justify-content-between"
    aria-label="Toolbar with Button groups"
    >
    <ButtonGroup aria-label="First group">
        <Button variant="light">1</Button>{' '}
        <Button variant="light">2</Button>{' '}
        <Button variant="light">3</Button>{' '}
        <Button variant="light">4</Button>
    </ButtonGroup>
    <InputGroup>
        <InputGroup.Text id="btnGroupAddon2">@</InputGroup.Text>
        <FormControl
            type="text"
            placeholder="Input group example"
            aria-label="Input group example"
            aria-describedby="btnGroupAddon2"
        />
    </InputGroup>
</ButtonToolbar>`

const ButtonGroupView = () => {
    return (
        <>
            <h1 className="title-section">Button Group</h1>
            <p>Group a series of buttons together on a single line with the button group.</p>

            <h5 className="subtitle">Basic Example</h5>
            <p>Wrap a series of <code>&lt;Button&gt;</code>s in a <code>&lt;ButtonGroup&gt;</code>.</p>

            <ButtonGroup aria-label="Basic example">
                <Button variant="primary">Left</Button>
                <Button variant="primary">Middle</Button>
                <Button variant="primary">Right</Button>
            </ButtonGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {exampleGroup}
            </ReactPrism>

            <h5 className="subtitle">Basic Light Example</h5>

            <ButtonGroup aria-label="Basic example">
                <Button variant="light">Left</Button>
                <Button variant="light">Middle</Button>
                <Button variant="light">Right</Button>
            </ButtonGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {exampleLightGroup}
            </ReactPrism>

            <h5 className="subtitle">Button Toolbar</h5>
            <p>Combine sets of  <code>&lt;ButtonGroup&gt;</code>s into a <code>&lt;ButtonToolbar&gt;</code> for more complex components.</p>

            <ButtonToolbar className="mlr-20">
                <ButtonGroup>
                    <Button variant="light">1</Button>
                    <Button variant="light">2</Button>
                    <Button variant="light">3</Button>
                    <Button variant="light">4</Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button variant="light">5</Button>
                    <Button variant="light">6</Button>
                    <Button variant="light">7</Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button variant="light">8</Button>
                </ButtonGroup>
            </ButtonToolbar>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {buttonToolbar}
            </ReactPrism>

            <p>Feel free to mix input groups with button groups in your toolbars.
                Similar to the example above, you’ll likely need some utilities though
                to space things properly.</p>

            <ButtonToolbar className="mb-3" aria-label="Toolbar with Button groups">
                <ButtonGroup className="me-2" aria-label="First group">
                    <Button variant="light">1</Button>{' '}
                    <Button variant="light">2</Button>{' '}
                    <Button variant="light">3</Button>{' '}
                    <Button variant="light">4</Button>
                </ButtonGroup>
                <InputGroup>
                    <InputGroup.Text id="btnGroupAddon">@</InputGroup.Text>
                    <FormControl
                        type="text"
                        placeholder="Input group example"
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon"
                    />
                </InputGroup>
            </ButtonToolbar>

            <ButtonToolbar
                className="justify-content-between"
                aria-label="Toolbar with Button groups"
            >
                <ButtonGroup aria-label="First group">
                    <Button variant="light">1</Button>{' '}
                    <Button variant="light">2</Button>{' '}
                    <Button variant="light">3</Button>{' '}
                    <Button variant="light">4</Button>
                </ButtonGroup>
                <InputGroup>
                    <InputGroup.Text id="btnGroupAddon2">@</InputGroup.Text>
                    <FormControl
                        type="text"
                        placeholder="Input group example"
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon2"
                    />
                </InputGroup>
            </ButtonToolbar>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {buttonToolbar2}
            </ReactPrism>

            <h5 className="subtitle">Sizing</h5>
            <p>Instead of applying button sizing props to every button in a
                group, just add size prop to the button group.</p>

            <div className="btn-group-wrapper">
                <ButtonGroup size="lg">
                    <Button variant="primary">Left</Button>
                    <Button variant="primary">Middle</Button>
                    <Button variant="primary">Right</Button>
                </ButtonGroup>
            </div>
            <br />
            <div className="btn-group-wrapper">
                <ButtonGroup size="md">
                    <Button variant="primary">Left</Button>
                    <Button variant="primary">Middle</Button>
                    <Button variant="primary">Right</Button>
                </ButtonGroup>
            </div>
            <br />
            <div className="btn-group-wrapper">
                <ButtonGroup size="sm">
                    <Button variant="primary">Left</Button>
                    <Button variant="primary">Middle</Button>
                    <Button variant="primary">Right</Button>
                </ButtonGroup>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {groupSizing}
            </ReactPrism>

            <div className="btn-group-wrapper">
                <ButtonGroup size="lg">
                    <Button variant="light">Left</Button>
                    <Button variant="light">Middle</Button>
                    <Button variant="light">Right</Button>
                </ButtonGroup>
            </div>
            <br />
            <div className="btn-group-wrapper">
                <ButtonGroup size="md">
                    <Button variant="light">Left</Button>
                    <Button variant="light">Middle</Button>
                    <Button variant="light">Right</Button>
                </ButtonGroup>
            </div>
            <br />
            <div className="btn-group-wrapper">
                <ButtonGroup size="sm">
                    <Button variant="light">Left</Button>
                    <Button variant="light">Middle</Button>
                    <Button variant="light">Right</Button>
                </ButtonGroup>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {groupSizingLight}
            </ReactPrism>

            <h5 className="subtitle">Nesting</h5>
            <p>You can place other button types within the button group like dropdownbuttons.</p>

            <ButtonGroup>
                <Button variant="light">1</Button>
                <Button variant="light">2</Button>

                <DropdownButton as={ButtonGroup} title="Dropdown" id="bg-nested-dropdown" variant="light">
                    <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
                    <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
                </DropdownButton>
            </ButtonGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {nesting}
            </ReactPrism>

            <h5 className="subtitle">Vertical variation</h5>
            <p>Make a set of buttons appear vertically stacked rather than horizontally, by adding vertical to the button group. Split button dropdowns are not supported here.</p>

            <ButtonGroup vertical>
                <Button variant="light">Button</Button>
                <Button variant="light">Button</Button>
                <Button variant="light">Button</Button>
                <Button variant="light">Button</Button>
                <Button variant="light">Button</Button>
            </ButtonGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {verticalGroup}
            </ReactPrism>

            <p>Vertical variation with dropdown.</p>

            <ButtonGroup vertical>
                <Button variant="light">Button</Button>
                <Button variant="light">Button</Button>

                <DropdownButton as={ButtonGroup} title="Dropdown" id="bg-vertical-dropdown-1" variant="light">
                    <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
                    <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
                </DropdownButton>

                <Button variant="light">Button</Button>
                <Button variant="light">Button</Button>

                <DropdownButton as={ButtonGroup} title="Dropdown" id="bg-vertical-dropdown-2" variant="light">
                    <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
                    <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
                </DropdownButton>

                <DropdownButton as={ButtonGroup} title="Dropdown" id="bg-vertical-dropdown-3" variant="light">
                    <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
                    <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
                </DropdownButton>
            </ButtonGroup>

            <h5 className="subtitle">Extras</h5>

            <ButtonGroup size="lg" className="extras-buttons">
                <Button variant="primary"><Image src={fastForward} /></Button>
                <Button variant="primary"><Image src={play} /></Button>
                <Button variant="primary"><Image src={stop} /></Button>
                <Button variant="primary"><Image src={fastForward2} /></Button>
            </ButtonGroup>

            <br />
            <br />

            <ButtonGroup size="lg">
                <Button variant="primary"><Image src={left} /></Button>
                <Button variant="primary"><Image src={right} /></Button>
            </ButtonGroup>

            <br />
            <br />

            <ButtonGroup size="lg" vertical>
                <Button variant="light rounded-pill"><Image src={plus} /></Button>
                <Button variant="light rounded-pill"><Image src={minus} /></Button>
            </ButtonGroup>
        </>
    );
}

export default ButtonGroupView