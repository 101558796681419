import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'
import ReactPrism from '@versant-digital/react-prismjs';

const alertNormal = `<Alert variant="primary">
<span className="title-bold">Primary alert</span> — check it out!
</Alert>
<Alert variant="secondary">
<span className="title-bold">Secondary alert</span> — check it out!
</Alert>
<Alert variant="success">
<span className="title-bold">Success alert</span> — check it out!
</Alert>
<Alert variant="danger">
<span className="title-bold">Danger alert</span> — check it out!
</Alert>
<Alert variant="warning">
<span className="title-bold">Warning alert</span> — check it out!
</Alert>
<Alert variant="info">
<span className="title-bold">Info alert</span> — check it out!
</Alert>
<Alert variant="light">
<span className="title-bold">Light alert</span> — check it out!
</Alert>
<Alert variant="dark">
<span className="title-bold"> Dark alert</span> — check it out!
</Alert>`

const alertOutline = `<Alert variant="outline-primary">
<span className="title-bold">Primary alert</span> — check it out!
</Alert>
<Alert variant="outline-secondary">
<span className="title-bold">Secondary alert</span> — check it out!
</Alert>
<Alert variant="outline-success">
<span className="title-bold">Success alert</span> — check it out!
</Alert>
<Alert variant="outline-danger">
<span className="title-bold">Danger alert</span> — check it out!
</Alert>
<Alert variant="outline-warning">
<span className="title-bold">Warning alert</span> — check it out!
</Alert>
<Alert variant="outline-info">
<span className="title-bold">Info alert</span> — check it out!
</Alert>
<Alert variant="outline-light">
<span className="title-bold">Light alert</span> — check it out!
</Alert>
<Alert variant="outline-dark">
<span className="title-bold">Dark alert</span> — check it out!
</Alert>`

const dismisingNormal = `<Alert variant="primary" show={visiblePrimary} onClose={() => setVisiblePrimary(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>
<Alert variant="secondary" show={visibleSecondary} onClose={() => setVisibleSecondary(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>
<Alert variant="danger" show={visibleDanger} onClose={() => setVisibleDanger(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>
<Alert variant="success" show={visibleSuccess} onClose={() => setVisibleSuccess(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>
<Alert variant="warning" show={visibleWarning} onClose={() => setVisibleWarning(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>
<Alert variant="info" show={visibleInfo} onClose={() => setVisibleInfo(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>
<Alert variant="light" show={visibleLight} onClose={() => setVisibleLight(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>
<Alert variant="dark" show={visibleDark} onClose={() => setVisibleDark(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>`

const dissmisingOutline = `<Alert variant="outline-primary" show={visiblePrimaryOutline} onClose={() => setVisibilePrimaryOutline(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>
<Alert variant="outline-secondary" show={visibleSecondaryOutline} onClose={() => setVisibleSecondaryOutline(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>
<Alert variant="outline-success" show={visibleSuccessOutline} onClose={() => setVisibleSuccessOutline(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>
<Alert variant="outline-danger" show={visibleDangerOutline} onClose={() => setVisibleDangerOutline(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>
<Alert variant="outline-warning" show={visibleWarningOutline} onClose={() => setVisibleWarningOutline(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>
<Alert variant="outline-info" show={visibleInfoOutline} onClose={() => setVisibleInfoOutline(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>
<Alert variant="outline-light" show={visibleLightOutline} onClose={() => setVisibleLightOutline(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>
<Alert variant="outline-dark" show={visibleDarkOutline} onClose={() => setVisibleDarkOutline(prevState => !prevState)} dismissible>
I am an alert and I can be dismissed!
</Alert>`

const additionalContent = `<Alert variant="success">
    <h4 className="alert-heding">Well done!</h4>
    <p>
        Aww yeah, you successfully read this important alert message. This example text is going
        to run a bit longer so that you can see how spacing within an alert works with this kind
        of content.
    </p>
    <hr />
    <p>
        Whenever you need to, be sure to use margin utilities to keep things nice and tidy.
    </p>
</Alert>`

const links = `return(
    {
        [
            'primary',
            'secondary',
            'success',
            'danger',
            'warning',
            'info',
            'light',
            'dark',
        ].map((variant, idx) => (
            <Alert key={idx} variant={variant}>
                This is a {variant} alert with{' '}
                <Alert.Link href="#">an example link</Alert.Link>. Give it a click if you
                like.
            </Alert>
        ))
    }
)`



const AlertView = () => {

    const [visiblePrimary, setVisiblePrimary] = useState(true);
    const [visibleSecondary, setVisibleSecondary] = useState(true);
    const [visibleSuccess, setVisibleSuccess] = useState(true);
    const [visibleDanger, setVisibleDanger] = useState(true);
    const [visibleWarning, setVisibleWarning] = useState(true);
    const [visibleInfo, setVisibleInfo] = useState(true);
    const [visibleLight, setVisibleLight] = useState(true);
    const [visibleDark, setVisibleDark] = useState(true);

    const [visiblePrimaryOutline, setVisibilePrimaryOutline] = useState(true);
    const [visibleSecondaryOutline, setVisibleSecondaryOutline] = useState(true);
    const [visibleSuccessOutline, setVisibleSuccessOutline] = useState(true);
    const [visibleDangerOutline, setVisibleDangerOutline] = useState(true);
    const [visibleWarningOutline, setVisibleWarningOutline] = useState(true);
    const [visibleInfoOutline, setVisibleInfoOutline] = useState(true);
    const [visibleLightOutline, setVisibleLightOutline] = useState(true);
    const [visibleDarkOutline, setVisibleDarkOutline] = useState(true);

    return (
        <>
            <h1 className="title-section">Alerts</h1>
            <p className="lead">Provide contextual feedback messages for typical user actions with the handful of available and flexible alert messages.</p>

            <h5 className="subtitle">Examples</h5>
            <p>Alerts are available for any length of text, as well as an optional
                dismiss button. For proper styling, use one of the eight <code>variant</code>s.</p>

            <Alert variant="primary">
                <span className="title-bold">Primary alert</span> — check it out!
            </Alert>
            <Alert variant="secondary">
                <span className="title-bold">Secondary alert</span> — check it out!
            </Alert>
            <Alert variant="success">
                <span className="title-bold">Success alert</span> — check it out!
            </Alert>
            <Alert variant="danger">
                <span className="title-bold">Danger alert</span> — check it out!
            </Alert>
            <Alert variant="warning">
                <span className="title-bold">Warning alert</span> — check it out!
            </Alert>
            <Alert variant="info">
                <span className="title-bold">Info alert</span> — check it out!
            </Alert>
            <Alert variant="light">
                <span className="title-bold">Light alert</span> — check it out!
            </Alert>
            <Alert variant="dark">
                <span className="title-bold"> Dark alert</span> — check it out!
            </Alert>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {alertNormal}
            </ReactPrism>

            <aside role="note" className="Callout-styles-module--warning--39Zw- Callout-styles-module--callout--1_K3h">
                <header style={{ background: "rgba(255,229,100,.3)", padding: "10px", borderRadius: "10px" }} className="h5">Conveying meaning to assistive technologies</header>
                <div style={{ background: "rgba(255,229,100,.3)", padding: "10px", borderRadius: "10px" }}>Using color to add meaning only provides a visual indication, which will not be conveyed to users of assistive technologies – such as screen readers. Ensure that information denoted by the color is either obvious from the content itself (e.g. the visible text), or is included through alternative means, such as additional text hidden with the <code>.visually-hidden</code> class.</div></aside>

            <h5 className="subtitle">Links</h5>

            <p>For links, use the <code>&lt;Alert.Link&gt;</code> component to provide matching
                colored links within any alert.</p>

            {
                [
                    'primary',
                    'secondary',
                    'success',
                    'danger',
                    'warning',
                    'info',
                    'light',
                    'dark',
                ].map((variant, idx) => (
                    <Alert key={idx} variant={variant}>
                        This is a {variant} alert with{' '}
                        <Alert.Link href="#">an example link</Alert.Link>. Give it a click if you
                        like.
                    </Alert>
                ))
            }

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {links}
            </ReactPrism>


            <h5 className="subtitle">Outline</h5>

            <Alert variant="outline-primary">
                <span className="title-bold">Primary alert</span> — check it out!
            </Alert>
            <Alert variant="outline-secondary">
                <span className="title-bold">Secondary alert</span> — check it out!
            </Alert>
            <Alert variant="outline-success">
                <span className="title-bold">Success alert</span> — check it out!
            </Alert>
            <Alert variant="outline-danger">
                <span className="title-bold">Danger alert</span> — check it out!
            </Alert>
            <Alert variant="outline-warning">
                <span className="title-bold">Warning alert</span> — check it out!
            </Alert>
            <Alert variant="outline-info">
                <span className="title-bold">Info alert</span> — check it out!
            </Alert>
            <Alert variant="outline-light">
                <span className="title-bold">Light alert</span> — check it out!
            </Alert>
            <Alert variant="outline-dark">
                <span className="title-bold">Dark alert</span> — check it out!
            </Alert>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {alertOutline}
            </ReactPrism>

            <h5 className="subtitle">Dismissing - normal</h5>

            <p>Add the <code>dismissible</code> prop to add a functioning dismiss
                button to the Alert.</p>

            <Alert variant="primary" show={visiblePrimary} onClose={() => setVisiblePrimary(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>
            <Alert variant="secondary" show={visibleSecondary} onClose={() => setVisibleSecondary(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>
            <Alert variant="danger" show={visibleDanger} onClose={() => setVisibleDanger(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>
            <Alert variant="success" show={visibleSuccess} onClose={() => setVisibleSuccess(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>
            <Alert variant="warning" show={visibleWarning} onClose={() => setVisibleWarning(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>
            <Alert variant="info" show={visibleInfo} onClose={() => setVisibleInfo(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>
            <Alert variant="light" show={visibleLight} onClose={() => setVisibleLight(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>
            <Alert variant="dark" show={visibleDark} onClose={() => setVisibleDark(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {dismisingNormal}
            </ReactPrism>

            <h5 className="subtitle">Dismissing - outline</h5>

            <Alert variant="outline-primary" show={visiblePrimaryOutline} onClose={() => setVisibilePrimaryOutline(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>
            <Alert variant="outline-secondary" show={visibleSecondaryOutline} onClose={() => setVisibleSecondaryOutline(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>
            <Alert variant="outline-success" show={visibleSuccessOutline} onClose={() => setVisibleSuccessOutline(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>
            <Alert variant="outline-danger" show={visibleDangerOutline} onClose={() => setVisibleDangerOutline(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>
            <Alert variant="outline-warning" show={visibleWarningOutline} onClose={() => setVisibleWarningOutline(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>
            <Alert variant="outline-info" show={visibleInfoOutline} onClose={() => setVisibleInfoOutline(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>
            <Alert variant="outline-light" show={visibleLightOutline} onClose={() => setVisibleLightOutline(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>
            <Alert variant="outline-dark" show={visibleDarkOutline} onClose={() => setVisibleDarkOutline(prevState => !prevState)} dismissible>
                I am an alert and I can be dismissed!
            </Alert>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {dissmisingOutline}
            </ReactPrism>

            <h5 className="subtitle">Additional content</h5>

            <p>Alerts can contain whatever content you like. Headers, paragraphs, dividers, go crazy.</p>

            <Alert variant="success">
                <h4 className="alert-heding">Well done!</h4>
                <p>
                    Aww yeah, you successfully read this important alert message. This example text is going
                    to run a bit longer so that you can see how spacing within an alert works with this kind
                    of content.
                </p>
                <hr />
                <p>
                    Whenever you need to, be sure to use margin utilities to keep things nice and tidy.
                </p>
            </Alert>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {additionalContent}
            </ReactPrism>

        </>
    );
}

export default AlertView;
