import React, { useState } from 'react';
import { Toast, Row, Col, Button, ToastContainer, Form } from 'react-bootstrap';
import ReactPrism from '@versant-digital/react-prismjs';

const toast = `<Toast>
    <Toast.Header>
        <div className="toast-mark primary"></div>
        <strong className="me-auto">Bootstrap</strong>
        <small>11 mins ago</small>
    </Toast.Header>
    <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
</Toast>`

const toastDismissible = `
const [showA, setShowA] = useState(true);
const [showB, setShowB] = useState(true);

const toggleShowA = () => setShowA(!showA);
const toggleShowB = () => setShowB(!showB);

return (
  <Row>
    <Col md={6} className="mb-2">
      <Button onClick={toggleShowA} className="mb-2">
        Toggle Toast <strong>with</strong> Animation
      </Button>
      <Toast show={showA} onClose={toggleShowA}>
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
          />
          <div className="toast-mark primary"></div>
          <strong className="me-auto">Bootstrap</strong>
          <small>11 mins ago</small>
        </Toast.Header>
        <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
      </Toast>
    </Col>
    <Col md={6} className="mb-2">
      <Button onClick={toggleShowB} className="mb-2">
        Toggle Toast <strong>without</strong> Animation
      </Button>
      <Toast onClose={toggleShowB} show={showB} animation={false}>
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
          />
          <div className="toast-mark primary"></div>
          <strong className="me-auto">Bootstrap</strong>
          <small>11 mins ago</small>
        </Toast.Header>
        <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
      </Toast>
    </Col>
  </Row>
);`

const stacking = `<ToastContainer>
  <Toast>
    <Toast.Header>
      <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
      <div className="toast-mark primary"></div>
      <strong className="me-auto">Bootstrap</strong>
      <small className="text-muted">just now</small>
    </Toast.Header>
    <Toast.Body>See? Just like this.</Toast.Body>
  </Toast>
  <Toast>
    <Toast.Header>
      <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
      <div className="toast-mark primary"></div>
      <strong className="me-auto">Bootstrap</strong>
      <small className="text-muted">2 seconds ago</small>
    </Toast.Header>
    <Toast.Body>Heads up, toasts will stack automatically</Toast.Body>
  </Toast>
</ToastContainer>`

const placement = `const [position, setPosition] = useState('top-start');

return (
<>
    <div className="mb-3">
    <label htmlFor="selectToastPlacement">Toast position</label>
    <Form.Select
        id="selectToastPlacement"
        className="mt-2"
        onChange={(e) => setPosition(e.currentTarget.value)}
    >
        {[
        'top-start',
        'top-center',
        'top-end',
        'middle-start',
        'middle-center',
        'middle-end',
        'bottom-start',
        'bottom-center',
        'bottom-end',
        ].map((p) => (
        <option key={p} value={p}>
            {p}
        </option>
        ))}
    </Form.Select>
    </div>

    <div
    aria-live="polite"
    aria-atomic="true"
    className="bg-dark position-relative"
    style={{ minHeight: '240px' }}
    >
    <ToastContainer className="p-3" position={position}>
        <Toast>
        <Toast.Header closeButton={false}>
            <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
            />
            <div className="toast-mark primary"></div>
            <strong className="me-auto">Bootstrap</strong>
            <small>11 mins ago</small>
        </Toast.Header>
        <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
        </Toast>
    </ToastContainer>
    </div>
</>
);`
const placement2 = `<div
aria-live="polite"
aria-atomic="true"
className="bg-dark position-relative"
style={{ minHeight: '300px' }}
>
<ToastContainer position="top-end" className="p-3">
  <Toast>
    <Toast.Header>
      <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
      <div className="toast-mark primary"></div>
      <strong className="me-auto">Bootstrap</strong>
      <small className="text-muted">just now</small>
    </Toast.Header>
    <Toast.Body>See? Just like this.</Toast.Body>
  </Toast>
  <Toast>
    <Toast.Header>
      <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
      <div className="toast-mark primary"></div>
      <strong className="me-auto">Bootstrap</strong>
      <small className="text-muted">2 seconds ago</small>
    </Toast.Header>
    <Toast.Body>Heads up, toasts will stack automatically</Toast.Body>
  </Toast>
</ToastContainer>
</div>`

const autohide = `const [show, setShow] = useState(false);
  
return (
    <Row>
    <Col xs={6}>
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
        <Toast.Header>
            <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
            />
            <div className="toast-mark primary"></div>
            <strong className="me-auto">Bootstrap</strong>
            <small>11 mins ago</small>
        </Toast.Header>
        <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
        </Toast>
    </Col>
    <Col xs={6}>
        <Button onClick={() => setShow(true)}>Show Toast</Button>
    </Col>
    </Row>
);`
const colors = `{
  [
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
  ].map((variant, idx) => (
    <Toast className="d-inline-block m-1" bg={variant.toLowerCase()} key={idx}>
      <Toast.Header>
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <div className={\`toast-mark \${variant.toLowerCase()}\`}></div>
        <strong className="me-auto">Bootstrap</strong>
        <small>11 mins ago</small>
      </Toast.Header>
      <Toast.Body className={variant === 'Dark' && 'text-white'}>
        Hello, world! This is a toast message.
      </Toast.Body>
    </Toast>
  ))
}`

const ToastView = () => {

  const [showA, setShowA] = useState(true);
  const [showB, setShowB] = useState(true);

  const toggleShowA = () => setShowA(!showA);
  const toggleShowB = () => setShowB(!showB);

  const [position, setPosition] = useState('top-start');
  const [show, setShow] = useState(false);

  return (
    <>
      <h1 className="title-section">Toasts</h1>

      <p className="lead">Push notifications to your visitors with a toast, a lightweight and easily customizable alert message.</p>
      <p>Toasts are lightweight notifications designed to mimic the push notifications that have been popularized by mobile and desktop operating systems. They’re built with flexbox, so they’re easy to align and position.</p>
      <h5 className="subtitle">Basic</h5>
      <p>To encourage extensible and predictable toasts, we recommend a header and body. Toast headers use display: flex, allowing easy alignment of content thanks to our margin and flexbox utilities.</p>
      <p>Toasts are as flexible as you need and have very little required markup. At a minimum, we require a single element to contain your “toasted” content and strongly encourage a dismiss button.</p>

      <Toast>
        <Toast.Header>
          <div className="toast-mark primary"></div>
          <strong className="me-auto">Bootstrap</strong>
          <small>11 mins ago</small>
        </Toast.Header>
        <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
      </Toast>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {toast}
      </ReactPrism>

      <h5 className="subtitle">Dismissible</h5>

      <Row>
        <Col md={6} className="mb-2">
          <Button onClick={toggleShowA} className="mb-2">
            Toggle Toast <strong>with</strong> Animation
          </Button>
          <Toast show={showA} onClose={toggleShowA}>
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <div className="toast-mark primary"></div>
              <strong className="me-auto">Bootstrap</strong>
              <small>11 mins ago</small>
            </Toast.Header>
            <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
          </Toast>
        </Col>
        <Col md={6} className="mb-2">
          <Button onClick={toggleShowB} className="mb-2">
            Toggle Toast <strong>without</strong> Animation
          </Button>
          <Toast onClose={toggleShowB} show={showB} animation={false}>
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <div className="toast-mark primary"></div>
              <strong className="me-auto">Bootstrap</strong>
              <small>11 mins ago</small>
            </Toast.Header>
            <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
          </Toast>
        </Col>
      </Row>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {toastDismissible}
      </ReactPrism>

      <h5 className="subtitle">Stacking</h5>
      <p>When you have multiple toasts, we default to vertically stacking them in a readable manner.</p>

      <ToastContainer>
        <Toast>
          <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <div className="toast-mark primary"></div>
            <strong className="me-auto">Bootstrap</strong>
            <small className="text-muted">just now</small>
          </Toast.Header>
          <Toast.Body>See? Just like this.</Toast.Body>
        </Toast>
        <Toast>
          <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <div className="toast-mark primary"></div>
            <strong className="me-auto">Bootstrap</strong>
            <small className="text-muted">2 seconds ago</small>
          </Toast.Header>
          <Toast.Body>Heads up, toasts will stack automatically</Toast.Body>
        </Toast>
      </ToastContainer>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {stacking}
      </ReactPrism>

      <h5 className="subtitle">Placement</h5>
      <p>Place toasts by setting a <code>position</code> in a <code>ToastContainer</code>. The top right is often used for notifications, as is the top middle.</p>

      <div className="mb-3">
        <label htmlFor="selectToastPlacement">Toast position</label>
        <Form.Select
          id="selectToastPlacement"
          className="mt-2"
          onChange={(e) => setPosition(e.currentTarget.value)}
        >
          {[
            'top-start',
            'top-center',
            'top-end',
            'middle-start',
            'middle-center',
            'middle-end',
            'bottom-start',
            'bottom-center',
            'bottom-end',
          ].map((p) => (
            <option key={p} value={p}>
              {p}
            </option>
          ))}
        </Form.Select>
      </div>

      <div
        aria-live="polite"
        aria-atomic="true"
        className="bg-dark position-relative"
        style={{ minHeight: '240px' }}
      >
        <ToastContainer className="p-3" position={position}>
          <Toast>
            <Toast.Header closeButton={false}>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <div className="toast-mark primary"></div>
              <strong className="me-auto">Bootstrap</strong>
              <small>11 mins ago</small>
            </Toast.Header>
            <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
          </Toast>
        </ToastContainer>
      </div>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {placement}
      </ReactPrism>

      <p>For systems that generate more notifications, consider using a wrapping element so they can easily stack.</p>

      <div
        aria-live="polite"
        aria-atomic="true"
        className="bg-dark position-relative"
        style={{ minHeight: '300px' }}
      >
        <ToastContainer position="top-end" className="p-3">
          <Toast>
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
              <div className="toast-mark primary"></div>
              <strong className="me-auto">Bootstrap</strong>
              <small className="text-muted">just now</small>
            </Toast.Header>
            <Toast.Body>See? Just like this.</Toast.Body>
          </Toast>
          <Toast>
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
              <div className="toast-mark primary"></div>
              <strong className="me-auto">Bootstrap</strong>
              <small className="text-muted">2 seconds ago</small>
            </Toast.Header>
            <Toast.Body>Heads up, toasts will stack automatically</Toast.Body>
          </Toast>
        </ToastContainer>
      </div>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {placement2}
      </ReactPrism>

      <h5 className="subtitle">Autohide</h5>
      <p>A Toast can also automatically hide after X milliseconds. For that, use the <code>autohide</code> prop in combination with <code>delay</code> the prop to sepecify the delay. But be aware, that it will only trigger the <code>onClose</code> function, you have to set manually the show property.</p>

      <Row>
        <Col xs={6}>
          <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <div className="toast-mark primary"></div>
              <strong className="me-auto">Bootstrap</strong>
              <small>11 mins ago</small>
            </Toast.Header>
            <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
          </Toast>
        </Col>
        <Col xs={6}>
          <Button onClick={() => setShow(true)}>Show Toast</Button>
        </Col>
      </Row>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {autohide}
      </ReactPrism>

      <h5 className="subtitle">Contextual variations</h5>
      <p>Add any of the below mentioned modifier classes to change the appearance of a toast.</p>

      {
        [
          'Primary',
          'Secondary',
          'Success',
          'Danger',
          'Warning',
          'Info',
          'Light',
          'Dark',
        ].map((variant, idx) => (
          <Toast className="d-inline-block m-1" bg={variant.toLowerCase()} key={idx}>
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
              <div className={`toast-mark ${variant.toLowerCase()}`}></div>
              <strong className="me-auto">Bootstrap</strong>
              <small>11 mins ago</small>
            </Toast.Header>
            <Toast.Body className={variant === 'Dark' && 'text-white'}>
              Hello, world! This is a toast message.
            </Toast.Body>
          </Toast>
        ))
      }

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {colors}
      </ReactPrism>

    </>


  )
}

export default ToastView;