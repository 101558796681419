import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import ReactPrism from '@versant-digital/react-prismjs';

const BadgeView = () => {

    const badgeSizes = `<h1>Example heading <Badge bg="secondary">New</Badge></h1>
<h2>Example heading <Badge bg="secondary">New</Badge></h2>
<h3>Example heading <Badge bg="secondary">New</Badge></h3>
<h4>Example heading <Badge bg="secondary">New</Badge></h4>
<h5>Example heading <Badge bg="secondary">New</Badge></h5>
<h6>Example heading <Badge bg="secondary">New</Badge></h6>`

    const contextualVariants = `<Badge bg="primary">Primary</Badge>
<Badge bg="secondary">Secondary</Badge>
<Badge bg="success">Success</Badge>
<Badge bg="danger">Danger</Badge>
<Badge bg="warning">Warning</Badge>
<Badge bg="info">Info</Badge>
<Badge bg="light">Light</Badge>
<Badge bg="dark">Dark</Badge>`

    const pillBadges = `<Badge bg="primary" pill>Primary</Badge>
<Badge bg="secondary" pill>Secondary</Badge>
<Badge bg="success" pill>Success</Badge>
<Badge bg="danger" pill>Danger</Badge>
<Badge bg="warning" pill>Warning</Badge>
<Badge bg="info" pill>Info</Badge>
<Badge bg="light" pill>Light</Badge>
<Badge bg="dark" pill>Dark</Badge>`

    const outlineBadges = `<Badge bg="outline-primary" pill>Primary</Badge>
<Badge bg="outline-secondary" pill>Secondary</Badge>
<Badge bg="outline-success" pill>Success</Badge>
<Badge bg="outline-danger" pill>Danger</Badge>
<Badge bg="outline-warning" pill>Warning</Badge>
<Badge bg="outline-info" pill>Info</Badge>
<Badge bg="outline-light" pill>Light</Badge>
<Badge bg="outline-dark" pill>Dark</Badge>`

    const notifications = `<Button variant="primary">
    Notifications <Badge className="notification" bg="light">4</Badge>
</Button>
<Button variant="secondary">
    Notifications <Badge className="notification" bg="light">4</Badge>
</Button>
<Button variant="success">
    Notifications <Badge className="notification" bg="light">4</Badge>
</Button>
<Button variant="danger">
    Notifications <Badge className="notification" bg="light">4</Badge>
</Button>
<Button variant="warning">
    Notifications <Badge className="notification" bg="light">4</Badge>
</Button>
<Button variant="info">
    Notifications <Badge className="notification" bg="light">4</Badge>
</Button>
<Button variant="light">
    Notifications <Badge className="notification" bg="dark">4</Badge>
</Button>
<Button variant="dark">
    Notifications <Badge className="notification" bg="light">4</Badge>
</Button>`

    const notificationsOutline = `<Button variant="outline-primary">
    Notifications <Badge className="notification" bg="primary">4</Badge>
</Button>
<Button variant="outline-secondary">
    Notifications <Badge className="notification" bg="secondary">4</Badge>
</Button>
<Button variant="outline-success">
    Notifications <Badge className="notification" bg="success">4</Badge>
</Button>
<Button variant="outline-danger">
    Notifications <Badge className="notification" bg="danger">4</Badge>
</Button>
<Button variant="outline-warning">
    Notifications <Badge className="notification" bg="warning">4</Badge>
</Button>
<Button variant="outline-info">
    Notifications <Badge className="notification" bg="info">4</Badge>
</Button>
<Button variant="outline-light">
    Notifications <Badge className="notification" bg="light">4</Badge>
</Button>
<Button variant="outline-dark">
    Notifications <Badge className="notification" bg="dark">4</Badge>
</Button>`

    const outline = `<Badge bg="outline-primary">Primary</Badge>
<Badge bg="outline-secondary">Secondary</Badge>
<Badge bg="outline-success">Success</Badge>
<Badge bg="outline-danger">Danger</Badge>
<Badge bg="outline-warning">Warning</Badge>
<Badge bg="outline-info">Info</Badge>
<Badge bg="outline-light">Light</Badge>
<Badge bg="outline-dark">Dark</Badge>`


    return (
        <>

            <h1 className="title-section">Badges</h1>
            <p>Badges scale to match the size of the immediate parent element by using relative font sizing and em units.</p>

            <h5 className="subtitle">Sizes</h5>
            <div className="badge-heading">
                <h1>Example heading <Badge bg="secondary">New</Badge></h1>
                <h2>Example heading <Badge bg="secondary">New</Badge></h2>
                <h3>Example heading <Badge bg="secondary">New</Badge></h3>
                <h4>Example heading <Badge bg="secondary">New</Badge></h4>
                <h5>Example heading <Badge bg="secondary">New</Badge></h5>
                <h6>Example heading <Badge bg="secondary">New</Badge></h6>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {badgeSizes}
            </ReactPrism>

            <p>Note that depending on how they are used, badges may be confusing for
                users of screen readers and similar assistive technologies. While the
                styling of badges provides a visual cue as to their purpose, these users
                will simply be presented with the content of the badge. Depending on the
                specific situation, these badges may seem like random additional words or
                numbers at the end of a sentence, link, or button. Unless the context is
                clear, consider including additional context with a visually hidden piece
                of additional text.</p>

            <h5 className="subtitle">Contextual variations</h5>
            <p>Add any of the below mentioned modifier classes to change the appearance of a badge.</p>

            <div className="row-custom">
                <Badge bg="primary">Primary</Badge>
                <Badge bg="secondary">Secondary</Badge>
                <Badge bg="success">Success</Badge>
                <Badge bg="danger">Danger</Badge>
                <Badge bg="warning">Warning</Badge>
                <Badge bg="info">Info</Badge>
                <Badge bg="light">Light</Badge>
                <Badge bg="dark">Dark</Badge>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {contextualVariants}
            </ReactPrism>

            <h5 className="subtitle">Pill badges</h5>

            <p>badges Use the <code>pill</code> modifier class to make badges more rounded
                (with a larger <code>border-radius</code>).
                Useful if you miss the badges from v3.</p>

            <div className="row-custom">
                <Badge bg="primary" pill>Primary</Badge>
                <Badge bg="secondary" pill>Secondary</Badge>
                <Badge bg="success" pill>Success</Badge>
                <Badge bg="danger" pill>Danger</Badge>
                <Badge bg="warning" pill>Warning</Badge>
                <Badge bg="info" pill>Info</Badge>
                <Badge bg="light" pill>Light</Badge>
                <Badge bg="dark" pill>Dark</Badge>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {pillBadges}
            </ReactPrism>

            <h5 className="subtitle">Outline</h5>

            <div className="row-custom">
                <Badge bg="outline-primary">Primary</Badge>
                <Badge bg="outline-secondary">Secondary</Badge>
                <Badge bg="outline-success">Success</Badge>
                <Badge bg="outline-danger">Danger</Badge>
                <Badge bg="outline-warning">Warning</Badge>
                <Badge bg="outline-info">Info</Badge>
                <Badge bg="outline-light">Light</Badge>
                <Badge bg="outline-dark">Dark</Badge>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {outline}
            </ReactPrism>

            <h5 className="subtitle">Outline Pill</h5>

            <div className="row-custom">
                <Badge bg="outline-primary" pill>Primary</Badge>
                <Badge bg="outline-secondary" pill>Secondary</Badge>
                <Badge bg="outline-success" pill>Success</Badge>
                <Badge bg="outline-danger" pill>Danger</Badge>
                <Badge bg="outline-warning" pill>Warning</Badge>
                <Badge bg="outline-info" pill>Info</Badge>
                <Badge bg="outline-light" pill>Light</Badge>
                <Badge bg="outline-dark" pill>Dark</Badge>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {outlineBadges}
            </ReactPrism>

            <h5 className="subtitle">Notifications</h5>

            <p>Badges can be used as part of links or buttons to provide a counter.</p>

            <div className="row-custom">
                <Button variant="primary">
                    Notifications <Badge className="notification" bg="light">4</Badge>
                </Button>
                <Button variant="secondary">
                    Notifications <Badge className="notification" bg="light">4</Badge>
                </Button>
                <Button variant="success">
                    Notifications <Badge className="notification" bg="light">4</Badge>
                </Button>
                <Button variant="danger">
                    Notifications <Badge className="notification" bg="light">4</Badge>
                </Button>
                <Button variant="warning">
                    Notifications <Badge className="notification" bg="light">4</Badge>
                </Button>
                <Button variant="info">
                    Notifications <Badge className="notification" bg="light">4</Badge>
                </Button>
                <Button variant="light">
                    Notifications <Badge className="notification" bg="dark">4</Badge>
                </Button>
                <Button variant="dark">
                    Notifications <Badge className="notification" bg="light">4</Badge>
                </Button>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {notifications}
            </ReactPrism>

            <h5 className="subtitle">Notifications outline</h5>

            <div className="row-custom">
                <Button variant="outline-primary">
                    Notifications <Badge className="notification" bg="primary">4</Badge>
                </Button>
                <Button variant="outline-secondary">
                    Notifications <Badge className="notification" bg="secondary">4</Badge>
                </Button>
                <Button variant="outline-success">
                    Notifications <Badge className="notification" bg="success">4</Badge>
                </Button>
                <Button variant="outline-danger">
                    Notifications <Badge className="notification" bg="danger">4</Badge>
                </Button>
                <Button variant="outline-warning">
                    Notifications <Badge className="notification" bg="warning">4</Badge>
                </Button>
                <Button variant="outline-info">
                    Notifications <Badge className="notification" bg="info">4</Badge>
                </Button>
                <Button variant="outline-light">
                    Notifications <Badge className="notification" bg="light">4</Badge>
                </Button>
                <Button variant="outline-dark">
                    Notifications <Badge className="notification" bg="dark">4</Badge>
                </Button>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {notificationsOutline}
            </ReactPrism>

        </>
    );
}

export default BadgeView;