import React, { useState } from 'react';
import { Modal, Button, Col, Container, Row } from 'react-bootstrap';
import ReactPrism from '@versant-digital/react-prismjs';

const modal = `<Button variant="primary" onClick={handleShow}>
Launch demo modal
</Button>

<Modal.Dialog>
  <Modal.Header closeButton>
    <Modal.Title>Modal title</Modal.Title>
  </Modal.Header>

  <Modal.Body>
    <p>Modal body text goes here.</p>
  </Modal.Body>

  <Modal.Footer>
    <Button variant="secondary">Close</Button>
    <Button variant="primary">Save changes</Button>
  </Modal.Footer>
</Modal.Dialog>`

const liveDemo = `const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

return (
  <>
    <Button variant="primary" onClick={handleShow}>
      Launch static backdrop modal
    </Button>

    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Modal title</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        I will not close if you click outside me. Don't even try to press
        escape key.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary">Understood</Button>
      </Modal.Footer>
    </Modal>
  </>
);`

const withoutAnimation = `const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);

return(
<>
  <Button variant="primary" onClick={handleShow1}>
    Launch demo modal
  </Button>

  <Modal show={show1} onHide={handleClose1} animation={false}>
    <Modal.Header closeButton>
      <Modal.Title>Modal heading</Modal.Title>
    </Modal.Header>
    <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose1}>
        Close
      </Button>
      <Button variant="primary" onClick={handleClose1}>
        Save Changes
      </Button>
    </Modal.Footer>
  </Modal>
</>
)`

const vertical = `function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function App() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

render(<App />);`

const grid = `function MydModalWithGrid(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Using Grid in Modal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Row>
            <Col xs={12} md={8}>
              .col-xs-12 .col-md-8
            </Col>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
          </Row>

          <Row>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function App() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch modal with grid
      </Button>

      <MydModalWithGrid show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

render(<App />);`

const sizes = `function Example() {
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);

  return (
    <>
      <Button onClick={() => setSmShow(true)}>Small modal</Button>{' '}
      <Button onClick={() => setLgShow(true)}>Large modal</Button>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Small Modal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...</Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...</Modal.Body>
      </Modal>
    </>
  );
}

render(<Example />);`

const fullscreen1 = `function Example() {
  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  return (
    <>
      {values.map((v, idx) => (
        <Button key={idx} className="me-2" onClick={() => handleShow(v)}>
          Full screen
          {typeof v === 'string' && \`below \${v.split('-')[0]}\`}
        </Button>
      ))}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>Modal body content</Modal.Body>
      </Modal>
    </>
  );
}

render(<Example />);`

const custom1 = `function Example() {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        Custom Width Modal
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Custom Modal Styling
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Ipsum molestiae natus adipisci modi eligendi? Debitis amet quae unde
            commodi aspernatur enim, consectetur. Cumque deleniti temporibus
            ipsam atque a dolores quisquam quisquam adipisci possimus
            laboriosam. Quibusdam facilis doloribus debitis! Sit quasi quod
            accusamus eos quod. Ab quos consequuntur eaque quo rem! Mollitia
            reiciendis porro quo magni incidunt dolore amet atque facilis ipsum
            deleniti rem!
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}

render(<Example />);`

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function MydModalWithGrid(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Using Grid in Modal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Row>
            <Col xs={12} md={8}>
              .col-xs-12 .col-md-8
            </Col>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
          </Row>

          <Row>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const ModalView = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show2, setShow2] = useState(false);

  function handleShow2(breakpoint) {
    setFullscreen(breakpoint);
    setShow2(true);
  }

  const [show3, setShow3] = useState(false);

  return (
    <>
      <h1 className="title-section">Modals</h1>

      <p>Add dialogs to your site for lightboxes, user notifications, or completely custom content.</p>

      <h5 className="subtitle">Overview</h5>

      <ul>
        <li>Modals are positioned over everything else in the document and remove
          scroll from the <code>&lt;body&gt;</code> so that modal content scrolls instead.</li>
        <li>Modals are <em>unmounted</em> when closed.</li><li>Bootstrap only supports <strong>one</strong> modal window at a time.
          Nested modals aren't supported, but if you really need them, the
          underlying <code>@restart/ui</code> library can support them if you're
          willing.</li>
        <li>Modal's "trap" focus in them, ensuring the keyboard navigation cycles
          through the modal, and not the rest of the page.</li>
        <li>Unlike vanilla Bootstrap, <code>autoFocus</code> works in Modals
          because React handles the implementation.</li>
      </ul>

      <h5 className="subtitle">Examples</h5>
      <h5 className="subtitle">Static Markup</h5>

      <p>Below is a <em>static</em> modal dialog (without the positioning) to
        demonstrate the look and feel of the Modal.</p>

      <br />
      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>

      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Modal body text goes here.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary">Close</Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer>
      </Modal.Dialog>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {modal}
      </ReactPrism>

      <h5 className="subtitle">Live demo</h5>

      <p>A modal with header, body, and set of actions in the footer. Use
        <code>&lt;Modal/&gt;</code> in combination with other components to show or hide your
        Modal. The <code>&lt;Modal/&gt;</code> Component comes with a few convenient "sub components": <code>&lt;Modal.Header/&gt;</code>,
        <code>&lt;Modal.Title/&gt;</code>, <code>&lt;Modal.Body/&gt;</code>, and <code>&lt;Modal.Footer/&gt;</code>, which you can use to build the Modal content.</p>

      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {liveDemo}
      </ReactPrism>

      <h5 className="subtitle">Without Animation</h5>

      <p>A Modal can also be without an animation. For that set the <code>animation </code>
        prop to <code>false</code>.</p>

      <>
        <Button variant="primary" onClick={handleShow1}>
          Launch demo modal
        </Button>

        <Modal show={show1} onHide={handleClose1} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose1}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {withoutAnimation}
      </ReactPrism>

      <h5 className="subtitle">Additional Import Options</h5>

      <p>The Modal Header, Title, Body, and Footer components are available as static properties the <code>&lt;Modal/&gt;</code> component, but you can also, import them directly like: <code>require("react-bootstrap/ModalHeader")</code>.</p>

      <h5 className="subtitle">Vertically centered</h5>

      <p>You can vertically center a modal by passing the <code>centered</code> prop.</p>

      <>
        <Button variant="primary" onClick={() => setModalShow(true)}>
          Launch vertically centered modal
        </Button>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {vertical}
      </ReactPrism>

      <h5 className="subtitle">Using the grid</h5>

      <p>You can use grid layouts within a model using regular grid components
        inside the modal content.</p>

      <>
        <Button variant="primary" onClick={() => setModalShow1(true)}>
          Launch modal with grid
        </Button>

        <MydModalWithGrid show={modalShow1} onHide={() => setModalShow1(false)} />
      </>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {grid}
      </ReactPrism>

      <h5 className="subtitle">Optional Sizes</h5>

      <p>You can specify a bootstrap large or small modal by using the
        <code> size</code> prop.</p>

      <>
        <Button onClick={() => setSmShow(true)}>Small modal</Button>{' '}
        <Button onClick={() => setLgShow(true)}>Large modal</Button>
        <Modal
          size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Small Modal
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>...</Modal.Body>
        </Modal>
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Large Modal
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>...</Modal.Body>
        </Modal>
      </>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {sizes}
      </ReactPrism>

      <h5 className="subtitle">Fullscreen Modal</h5>
      <p>You can use the <code>fullscreen</code> prop to make the modal
        fullscreen. Specifying a breakpoint will only set the modal as
        fullscreen <strong>below</strong> the breakpoint size.</p>
      <div className="row-custom">
        <>
          {values.map((v, idx) => (
            <Button key={idx} className="me-2" onClick={() => handleShow2(v)}>
              Full screen
              {typeof v === 'string' && `below ${v.split('-')[0]}`}
            </Button>
          ))}
          <Modal show={show2} fullscreen={fullscreen} onHide={() => setShow2(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>Modal body content</Modal.Body>
          </Modal>
        </>
      </div>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {fullscreen1}
      </ReactPrism>

      <h5 className="subtitle">Sizing modals using custom CSS</h5>

      <p>You can apply custom css to the modal dialog div using the
        <code>dialogClassName</code> prop. Example is using a custom css class with width
        set to 90%.</p>

      <>
        <Button variant="primary" onClick={() => setShow3(true)}>
          Custom Width Modal
        </Button>

        <Modal
          show={show3}
          onHide={() => setShow3(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Custom Modal Styling
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Ipsum molestiae natus adipisci modi eligendi? Debitis amet quae unde
              commodi aspernatur enim, consectetur. Cumque deleniti temporibus
              ipsam atque a dolores quisquam quisquam adipisci possimus
              laboriosam. Quibusdam facilis doloribus debitis! Sit quasi quod
              accusamus eos quod. Ab quos consequuntur eaque quo rem! Mollitia
              reiciendis porro quo magni incidunt dolore amet atque facilis ipsum
              deleniti rem!
            </p>
          </Modal.Body>
        </Modal>
      </>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {custom1}
      </ReactPrism>

    </>
  );
}

export default ModalView;