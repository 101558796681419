import React, { useState, useEffect } from 'react'
import { Button, InputGroup, FormControl, Image } from 'react-bootstrap'
import arrowImage from '../assets/chat-arrow.svg'
import attImage from '../assets/chat-att.svg'

const ChatView = () => {

    const [message, setMessage] = useState("")

    const [messages, setMessages] = useState([
        {
            messageClass: "chat-received",
            messageText: "Hello, my name is Pete. Check out your documents for the tour."
        },
        {
            messageClass: "chat-received",
            messageText: "Have a nice trip!"
        },
        {
            messageClass: "chat-send",
            messageText: "Thank you so much. If we'll have any questions, we'll contact you."
        }
    ])

    const sendMessage = () => {
        if (message !== "") {
            setMessages(prev => [...prev, { messageClass: "chat-send", messageText: message }])
            setMessage("")
        }
    }

    const sendMessageEnter = (e) => {
        if (message !== "") {
            if (e.key === 'Enter') {
                setMessages(prev => [...prev, { messageClass: "chat-send", messageText: message }])
                setMessage("")
            }
        }
    }

    useEffect(() => {
        var messageBody = document.querySelector('.chat-body');
        messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }, [messages])


    return (
        <>
            <h1 className="title-section">Chat</h1>
            <p>Use chat to communicate with others.</p>

            <div className="chat">
                <div className="chat-body">
                    <div className="chat-date">
                        March 6, 2020 at 8:21am
                    </div>
                    <div className="chat-messages">
                        {messages.map((message) => <div className={message.messageClass}>{message.messageText}</div>)}
                    </div>

                </div>
                <div className="chat-input">

                    <InputGroup className="mb-3">

                        <Button variant="outline-secondary" id="button-addon2">
                            <Image src={attImage} />
                        </Button>

                        <FormControl
                            placeholder="Message"
                            onKeyDown={sendMessageEnter}
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                        />

                        <Button variant="outline-secondary" id="button-addon2"
                            onClick={sendMessage}
                        >
                            <Image src={arrowImage} />
                        </Button>

                    </InputGroup>

                </div>
            </div>

        </>
    );
}

export default ChatView;