import React, { useState } from 'react'
import { Accordion, Card, useAccordionButton, Button, Collapse } from 'react-bootstrap';

import ReactPrism from '@versant-digital/react-prismjs';

const collapse = `const [open, setOpen] = useState(false);
return (
    <Button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
    >
        Link with href
    </Button>
            <br />
    <Collapse in={open}>
        <div id="example-collapse-text">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
            terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer
            labore wes anderson cred nesciunt sapiente ea proident.
        </div>
    </Collapse>
)`
const accordion = `<Accordion defaultActiveKey="0">
    <Accordion.Item eventKey="0">
        <Accordion.Header>Accordion 1</Accordion.Header>
        <Accordion.Body>
            Anim pariatur cliche reprehenderit, enim eiusmod
            high life accusamus terry richardson ad squid.
            3 wolf moon officia aute, non cupidatat
            skateboard dolor brunch.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="1">
        <Accordion.Header>Accordion 2</Accordion.Header>
        <Accordion.Body>
            Anim pariatur cliche reprehenderit, enim eiusmod
            high life accusamus terry richardson ad squid.
            3 wolf moon officia aute, non cupidatat
            skateboard dolor brunch.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="2">
        <Accordion.Header>Accordion 3</Accordion.Header>
        <Accordion.Body>
            Anim pariatur cliche reprehenderit, enim eiusmod
            high life accusamus terry richardson ad squid.
            3 wolf moon officia aute, non cupidatat
            skateboard dolor brunch.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="3">
        <Accordion.Header>Accordion 4</Accordion.Header>
        <Accordion.Body>
            Anim pariatur cliche reprehenderit, enim eiusmod
            high life accusamus terry richardson ad squid.
            3 wolf moon officia aute, non cupidatat
            skateboard dolor brunch.
        </Accordion.Body>
    </Accordion.Item>
</Accordion>`

const fullAccordion = `<Accordion>
    <Accordion.Item eventKey="0">
        <Accordion.Header>Accordion Item #1</Accordion.Header>
        <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="1">
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
        </Accordion.Body>
    </Accordion.Item>
</Accordion>`

const customAccordion = `<Accordion defaultActiveKey="0">
    <Card>
        <Card.Header>
            <CustomToggle eventKey="0">Click me!</CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
            <Card.Body>Hello! I'm the body</Card.Body>
        </Accordion.Collapse>
    </Card>
    <Card>
        <Card.Header>
            <CustomToggle eventKey="1">Click me!</CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
            <Card.Body>Hello! I'm another body</Card.Body>
        </Accordion.Collapse>
    </Card>
</Accordion>`

const CollapseView = () => {

    const [open, setOpen] = useState(false);

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            console.log('totally custom!'),
        );

        return (
            <button
                type="button"
                style={{ backgroundColor: 'pink' }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }

    return (
        <>
            <h1 className="title-section">Collapse</h1>

            <h5 className="subtitle">Basic Example</h5>

            <p>Add a collapse toggle animation to an element or component.</p>
            <p>Build vertically collapsing accordions in combination with the Collapse component.</p>

            <Button
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
            >
                Link with href
            </Button>
            <br />
            <Collapse in={open}>
                <div id="example-collapse-text">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
                    terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer
                    labore wes anderson cred nesciunt sapiente ea proident.
                </div>
            </Collapse>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {collapse}
            </ReactPrism>

            <p>Click the accordions below to expand/collapse the accordion content.</p>

            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Accordion 1</Accordion.Header>
                    <Accordion.Body>
                        Anim pariatur cliche reprehenderit, enim eiusmod
                        high life accusamus terry richardson ad squid.
                        3 wolf moon officia aute, non cupidatat
                        skateboard dolor brunch.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Accordion 2</Accordion.Header>
                    <Accordion.Body>
                        Anim pariatur cliche reprehenderit, enim eiusmod
                        high life accusamus terry richardson ad squid.
                        3 wolf moon officia aute, non cupidatat
                        skateboard dolor brunch.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Accordion 3</Accordion.Header>
                    <Accordion.Body>
                        Anim pariatur cliche reprehenderit, enim eiusmod
                        high life accusamus terry richardson ad squid.
                        3 wolf moon officia aute, non cupidatat
                        skateboard dolor brunch.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Accordion 4</Accordion.Header>
                    <Accordion.Body>
                        Anim pariatur cliche reprehenderit, enim eiusmod
                        high life accusamus terry richardson ad squid.
                        3 wolf moon officia aute, non cupidatat
                        skateboard dolor brunch.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {accordion}
            </ReactPrism>

            <h5 className="subtitle">Fully Collapsed State</h5>
            <p>If you want your <code>Accordion</code> to start in a fully-collapsed state, then simply don't pass in a <code>defaultActiveKey</code> prop to <code>Accordion</code>.</p>

            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Accordion Item #1</Accordion.Header>
                    <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                    <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {fullAccordion}
            </ReactPrism>

            <h5 className="subtitle">Custom Accordions</h5>

            <p>You can still create card-based accordions like those in Bootstrap 4. You can hook
                into the Accordion toggle functionality via <code>useAccordionButton</code> to make custom
                toggle components.</p>

            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="0">Click me!</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>Hello! I'm the body</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="1">Click me!</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>Hello! I'm another body</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {customAccordion}
            </ReactPrism>

        </>
    )
}

export default CollapseView