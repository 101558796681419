import React from 'react'
import { Navbar, NavDropdown, Nav, Container, Form, FormControl, Button, Offcanvas } from 'react-bootstrap'
import logo from '../assets/trendy-logo.png'
import ReactPrism from '@versant-digital/react-prismjs';

const navbar = `<Navbar bg="light" expand="lg">
    <Container>
        <Navbar.Brand href="#home">Navbar</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href="#home">Home</Nav.Link>
                <Nav.Link href="#link">Link</Nav.Link>
                <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#link" disabled>Disabled</Nav.Link>
            </Nav>
        </Navbar.Collapse>
    </Container>
</Navbar>`

const navbarSearch = `<Navbar bg="light" expand="lg">
    <Container>
        <Navbar.Brand href="#home">Navbar</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href="#home">Home</Nav.Link>
                <Nav.Link href="#link">Link</Nav.Link>
                <Nav.Link href="#link">Pricing</Nav.Link>
                <Nav.Link href="#link" disabled>Disabled</Nav.Link>
            </Nav>
            <Form className="d-flex standard">
                <FormControl
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                />
                <Button variant="outline-primary">Search</Button>
            </Form>
        </Navbar.Collapse>
    </Container>
</Navbar>`

const text = `<Navbar>
    <Container>
        <Navbar.Brand href="#home">Navbar with text</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
                Signed in as: <a href="#login">Mark Otto</a>
            </Navbar.Text>
        </Navbar.Collapse>
    </Container>
</Navbar>`

const fixedTop = `<Navbar fixed="top" />`

const fixedBottom = `<Navbar fixed="bottom" />`

const stickyTop = `<Navbar sticky="top" />`

const containers = `<Container>
    <Navbar expand="lg" variant="light" bg="light">
        <Container>
            <Navbar.Brand href="#">Navbar</Navbar.Brand>
        </Container>
    </Navbar>
</Container>`

const containers2 = `<Navbar expand="lg" variant="light" bg="light">
    <Container>
        <Navbar.Brand href="#">Navbar</Navbar.Brand>
    </Container>
</Navbar>`

const dropdown = `<Navbar bg="light" expand="lg">
    <Container>
        <Navbar.Brand href="#home">Navbar</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href="#home" active>Home</Nav.Link>
                <Nav.Link href="#link">Link</Nav.Link>
                <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#link" disabled>Disabled</Nav.Link>
            </Nav>
        </Navbar.Collapse>
    </Container>
</Navbar>`

const scroling = `<Navbar bg="light" expand="lg">
    <Container fluid>
        <Navbar.Brand href="#">Navbar scroll</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
            <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
            >
                <Nav.Link href="#action1">Home</Nav.Link>
                <Nav.Link href="#action2">Link</Nav.Link>
                <NavDropdown title="Link" id="navbarScrollingDropdown">
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                        Something else here
                    </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#" disabled>
                    Link
                </Nav.Link>
            </Nav>
            <Form className="d-flex">
                <FormControl
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                />
                <Button variant="outline-success">Search</Button>
            </Form>
        </Navbar.Collapse>
    </Container>
</Navbar>`

const responsive = `<Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
    <Container>
        <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href="#features">Features</Nav.Link>
                <Nav.Link href="#pricing">Pricing</Nav.Link>
                <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown>
            </Nav>
            <Nav>
                <Nav.Link href="#deets">More deets</Nav.Link>
                <Nav.Link eventKey={2} href="#memes">
                    Dank memes
                </Nav.Link>
            </Nav>
        </Navbar.Collapse>
    </Container>
</Navbar>`

const offcanvas = `<Navbar bg="secondary" expand={false} variant="dark">
    <Container fluid>
        <Navbar.Toggle aria-controls="offcanvasNavbar" />
        <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel">Offcanvas</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link href="#action1">Home</Nav.Link>
                    <Nav.Link href="#action2">Link</Nav.Link>
                    <NavDropdown title="Dropdown" id="offcanvasNavbarDropdown">
                        <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action5">
                            Something else here
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                <Form className="d-flex">
                    <FormControl
                        type="search"
                        placeholder="Search"
                        className="me-2"
                        aria-label="Search"
                    />
                    <Button variant="outline-success">Search</Button>
                </Form>
            </Offcanvas.Body>
        </Navbar.Offcanvas>
    </Container>
</Navbar>`

const navbarBrand = `<>
    <Navbar bg="light">
        <Container>
            <Navbar.Brand href="#home">Brand link</Navbar.Brand>
        </Container>
    </Navbar>
    <br />
    <Navbar bg="light">
        <Container>
            <Navbar.Brand>Brand text</Navbar.Brand>
        </Container>
    </Navbar>
    <br />
    <Navbar bg="light">
        <Container>
            <Navbar.Brand href="#home">
                <img
                    src={logo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                />
            </Navbar.Brand>
        </Container>
    </Navbar>
    <br />
    <Navbar bg="dark" variant="dark">
        <Container>
            <Navbar.Brand href="#home">
                <img
                    alt=""
                    src={logo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                />{' '}
                Trendy
            </Navbar.Brand>
        </Container>
    </Navbar>
</>`

const navForms = `<Form className="d-flex nav-forms">
    <FormControl
        type="search"
        placeholder="Search"
        className="me-2"
        aria-label="Primary"
    />
    <Button variant="outline-primary">Search</Button>
</Form>`

const colors1 = `<Navbar bg="primary" expand="lg" variant="dark">
    <Container fluid>
        <Navbar.Brand href="#">Navbar</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
            <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
            >
                <Nav.Link href="#action1">Home</Nav.Link>
                <Nav.Link href="#action2">Features</Nav.Link>
                <Nav.Link href="#1">Pricing</Nav.Link>
                <Nav.Link href="#">About</Nav.Link>
            </Nav>
            <Form className="d-flex">
                <FormControl
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                />
                <Button variant="secondary">Search</Button>
            </Form>
        </Navbar.Collapse>
    </Container>
</Navbar>`

const colors2 = `<Navbar bg="secondary" expand="lg" variant="dark">
    <Container fluid>
        <Navbar.Brand href="#">Navbar</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
            <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
            >
                <Nav.Link href="#action1">Home</Nav.Link>
                <Nav.Link href="#action2">Features</Nav.Link>
                <Nav.Link href="#3">Pricing</Nav.Link>
                <Nav.Link href="#4">About</Nav.Link>
            </Nav>
            <Form className="d-flex">
                <FormControl
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                />
                <Button variant="primary">Search</Button>
            </Form>
        </Navbar.Collapse>
    </Container>
</Navbar>`

const colors3 = `<Navbar bg="light" expand="lg">
    <Container fluid>
        <Navbar.Brand href="#">Navbar</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
            <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
            >
                <Nav.Link href="#action1">Home</Nav.Link>
                <Nav.Link href="#action2">Features</Nav.Link>
                <Nav.Link href="#3">Pricing</Nav.Link>
                <Nav.Link href="#4">About</Nav.Link>
            </Nav>
            <Form className="d-flex">
                <FormControl
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                />
                <Button variant="primary-outline">Search</Button>
            </Form>
        </Navbar.Collapse>
    </Container>
</Navbar>`

const NavbarView = () => {
    return (
        <>
            <h1 className="title-section">Navbar</h1>

            <p>A powerful, responsive navigation header, the navbar. Includes support for branding, navigation, and more</p>

            <h5 className="subtitle">Overview</h5>

            <p>Here’s what you need to know before getting started with the Navbar:</p>

            <ul>
                <li>Use the <code>expand</code> prop to allow for collapsing the <code>Navbar</code> at
                    lower breakpoints.</li>
                <li><code>Navbar</code>s and their contents are fluid by default. Use optional
                    <a href="#containers">containers</a> to limit their horizontal width.</li>
                <li>Use spacing and flex utilities to size and position content</li>
            </ul>

            <p>A responsive navigation header, including support for branding,
                navigation, and more. Here’s an example of all the sub-components
                included in a responsive light-themed navbar that automatically
                collapses at the lg (large) breakpoint.</p>

            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="#home">Navbar</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#home">Home</Nav.Link>
                            <Nav.Link href="#link">Link</Nav.Link>
                            <Nav.Link href="#link">Pricing</Nav.Link>
                            <Nav.Link href="#link" disabled>Disabled</Nav.Link>
                        </Nav>
                        <Form className="d-flex standard">
                            <FormControl
                                type="search"
                                placeholder="Search"
                                className="me-2"
                                aria-label="Search"
                            />
                            <Button outline variant="outline-primary">Search</Button>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {navbarSearch}
            </ReactPrism>

            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="#home">Navbar</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#home">Home</Nav.Link>
                            <Nav.Link href="#link">Link</Nav.Link>
                            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="#link" disabled>Disabled</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {navbar}
            </ReactPrism>

            <h5 className="subtitle">Brand</h5>

            <p>A simple flexible branding component. Images are supported but will
                likely require custom styling to work well.</p>

            <>
                <Navbar bg="light">
                    <Container>
                        <Navbar.Brand href="#home">Brand link</Navbar.Brand>
                    </Container>
                </Navbar>
                <br />
                <Navbar bg="light">
                    <Container>
                        <Navbar.Brand>Brand text</Navbar.Brand>
                    </Container>
                </Navbar>
                <br />
                <Navbar bg="light">
                    <Container>
                        <Navbar.Brand href="#home">
                            <img
                                src={logo}
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                                alt="React Bootstrap logo"
                            />
                        </Navbar.Brand>
                    </Container>
                </Navbar>
                <br />
                <Navbar bg="dark" variant="dark">
                    <Container>
                        <Navbar.Brand href="#home">
                            <img
                                alt=""
                                src={logo}
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                            />{' '}
                            Trendy
                        </Navbar.Brand>
                    </Container>
                </Navbar>
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {navbarBrand}
            </ReactPrism>

            <h5 className="subtitle">Text and Non-nav links</h5>

            <p>Loose text and links can be wrapped <code>Navbar.Text</code> in order to
                correctly align it vertically.</p>

            <Navbar>
                <Container>
                    <Navbar.Brand href="#home">Navbar with text</Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            Signed in as: <a href="#login">Mark Otto</a>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {text}
            </ReactPrism>

            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="#home">Navbar</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#home" active>Home</Nav.Link>
                            <Nav.Link href="#link">Link</Nav.Link>
                            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="#link" disabled>Disabled</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {dropdown}
            </ReactPrism>

            <h5 className="subtitle">Forms</h5>
            <p>Place various form controls and components within a navbar:</p>

            <Form className="d-flex nav-forms">
                <FormControl
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Primary"
                />
                <Button variant="outline-primary">Search</Button>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {navForms}
            </ReactPrism>

            <h5 className="subtitle">Color schemes</h5>

            <p>Theming the navbar has never been easier thanks to the combination of
                theming classes and background-color utilities. Choose from
                <code>variant="light"</code> for use with light background colors, or
                <code>variant="dark"</code> for dark background colors. Then, customize
                with the <code>bg</code> prop or any custom css!</p>

            <Navbar bg="primary" expand="lg" variant="dark">
                <Container fluid>
                    <Navbar.Brand href="#">Navbar</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link href="#action1">Home</Nav.Link>
                            <Nav.Link href="#action2">Features</Nav.Link>
                            <Nav.Link href="#1">Pricing</Nav.Link>
                            <Nav.Link href="#">About</Nav.Link>
                        </Nav>
                        <Form className="d-flex">
                            <FormControl
                                type="search"
                                placeholder="Search"
                                className="me-2"
                                aria-label="Search"
                            />
                            <Button variant="secondary">Search</Button>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {colors1}
            </ReactPrism>

            <Navbar bg="secondary" expand="lg" variant="dark">
                <Container fluid>
                    <Navbar.Brand href="#">Navbar</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link href="#action1">Home</Nav.Link>
                            <Nav.Link href="#action2">Features</Nav.Link>
                            <Nav.Link href="#3">Pricing</Nav.Link>
                            <Nav.Link href="#4">About</Nav.Link>
                        </Nav>
                        <Form className="d-flex">
                            <FormControl
                                type="search"
                                placeholder="Search"
                                className="me-2"
                                aria-label="Search"
                            />
                            <Button variant="primary">Search</Button>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {colors2}
            </ReactPrism>

            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Navbar.Brand href="#">Navbar</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link href="#action1">Home</Nav.Link>
                            <Nav.Link href="#action2">Features</Nav.Link>
                            <Nav.Link href="#3">Pricing</Nav.Link>
                            <Nav.Link href="#4">About</Nav.Link>
                        </Nav>
                        <Form className="d-flex">
                            <FormControl
                                type="search"
                                placeholder="Search"
                                className="me-2"
                                aria-label="Search"
                            />
                            <Button variant="primary-outline">Search</Button>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {colors3}
            </ReactPrism>

            <h5 className="subtitle">Containers</h5>

            <p>While not required, you can wrap the Navbar in a <code>&lt;Container&gt;</code> component
                to center it on a page, or add one within to only center the contents of a
                <a href="#placement">fixed or static top navbar</a>.</p>

            <Container>
                <Navbar expand="lg" variant="light" bg="light">
                    <Container>
                        <Navbar.Brand href="#">Navbar</Navbar.Brand>
                    </Container>
                </Navbar>
            </Container>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {containers}
            </ReactPrism>

            <p>When the container is within your navbar, its horizontal padding is
                removed at breakpoints lower than your specified
                <code> expand= 'sm' | 'md' | 'lg' | 'xl' | 'xxl' </code> prop. This
                ensures we’re not doubling up on padding unnecessarily on lower
                viewports when your navbar is collapsed.</p>

            <Navbar expand="lg" variant="light" bg="light">
                <Container>
                    <Navbar.Brand href="#">Navbar</Navbar.Brand>
                </Container>
            </Navbar>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {containers2}
            </ReactPrism>

            <h5 className="subtitle">Placement</h5>

            <p>You can use Bootstrap's <a href="https://getbootstrap.com/docs/5.1/utilities/position/">position utilities</a> to
                place navbars in non-static positions. Choose from fixed to the top,
                fixed to the bottom, or stickied to the top (scrolls with the page until
                it reaches the top, then stays there). Fixed navbars use
                <code> position: fixed</code>, meaning they’re pulled from the normal
                flow of the DOM and may require custom CSS (e.g., padding-top on the
                <code> &lt;body&gt; </code>) to prevent overlap with other elements. Also
                note that <code>.sticky-top</code> uses <code>position: sticky</code>, which
                <a href="https://caniuse.com/#feat=css-sticky"> isn’t fully supported in every browser</a>.</p>

            <p>Since these positioning needs are so common for Navbars, we've added convenience props for them</p>

            <h5 className="subtitle">Fixed top</h5>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {fixedTop}
            </ReactPrism>

            <h5 className="subtitle">Fixed bottom</h5>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {fixedBottom}
            </ReactPrism>

            <h5 className="subtitle">Sticky top</h5>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {stickyTop}
            </ReactPrism>

            <h5 className="subtitle">Scrolling</h5>

            <p>You can use the <code>navbarScroll</code> prop in a <code>&lt;Nav&gt;</code> to enable vertical scrolling within the
                toggleable contents of a collapsed navbar. See the <a href="https://getbootstrap.com/docs/5.1/components/navbar/#scrolling">Bootstrap docs</a> for more information.</p>

            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Navbar.Brand href="#">Navbar scroll</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link href="#action1">Home</Nav.Link>
                            <Nav.Link href="#action2">Link</Nav.Link>
                            <NavDropdown title="Link" id="navbarScrollingDropdown">
                                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action5">
                                    Something else here
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="#" disabled>
                                Link
                            </Nav.Link>
                        </Nav>
                        <Form className="d-flex">
                            <FormControl
                                type="search"
                                placeholder="Search"
                                className="me-2"
                                aria-label="Search"
                            />
                            <Button variant="outline-success">Search</Button>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {scroling}
            </ReactPrism>

            <h5 className="subtitle">Responsive behaviors</h5>

            <p>Use the <code>expand</code> prop as well as the
                <code>Navbar.Toggle</code> and <code>Navbar.Collapse</code> components
                to control when content collapses behind a button.</p>

            <p>Set the <code>defaultExpanded</code> prop to make the Navbar start
                expanded. Set <code>collapseOnSelect</code> to make the Navbar collapse
                automatically when the user selects an item. You can also finely control
                the collapsing behavior by using the <code>expanded</code> and
                <code>onToggle</code> props.</p>

            <div>Watch out! You <strong>need</strong> to provide a breakpoint value to <code>expand</code> in order for the Navbar to collapse at all.</div>

            <br />

            <Navbar collapseOnSelect expand="lg" bg="secondary" variant="dark">
                <Container>
                    <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#features">Features</Nav.Link>
                            <Nav.Link href="#pricing">Pricing</Nav.Link>
                            <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav>
                            <Nav.Link href="#deets">More deets</Nav.Link>
                            <Nav.Link eventKey={2} href="#memes">
                                Dank memes
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {responsive}
            </ReactPrism>

            <h5 className="subtitle">Offcanvas</h5>

            <p>Transform your expanding and collapsing navbar into an offcanvas drawer
                with the offcanvas component. We extend both the offcanvas default
                styles and use the <code>expand</code> prop to create a dynamic and
                flexible navigation sidebar.</p>

            <p>In the example below, to create an offcanvas navbar that is always
                collapsed across all breakpoints, set the <code>expand</code> prop to <code>false</code>.</p>

            <Navbar bg="secondary" expand={false} variant="dark">
                <Container fluid>
                    <Navbar.Toggle aria-controls="offcanvasNavbar" />
                    <Navbar.Offcanvas
                        id="offcanvasNavbar"
                        aria-labelledby="offcanvasNavbarLabel"
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="offcanvasNavbarLabel">Offcanvas</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <Nav.Link href="#action1">Home</Nav.Link>
                                <Nav.Link href="#action2">Link</Nav.Link>
                                <NavDropdown title="Dropdown" id="offcanvasNavbarDropdown">
                                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                                    <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action5">
                                        Something else here
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                            <Form className="d-flex">
                                <FormControl
                                    type="search"
                                    placeholder="Search"
                                    className="me-2"
                                    aria-label="Search"
                                />
                                <Button variant="outline-success">Search</Button>
                            </Form>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {offcanvas}
            </ReactPrism>

        </>


    );
}

export default NavbarView;