import React from 'react'
import { Route, Switch, Redirect} from 'react-router-dom';
import { Col } from 'react-bootstrap';

import Alert from '../views/AlertView';
import Badge from '../views/BadgeView';
import Button from '../views/ButtonView';
import Breadcrumb from '../views/BreadcrumbView';
import Card from '../views/CardView';
import Dropdown from '../views/DropdownView';
import ButtonGroup from '../views/ButtonGroupView';
import Carousel from '../views/CarouselView'
import Form from '../views/FormsView'
import Table from '../views/TableView'
import ListGroup from '../views/ListGroupView'
import Navbar from '../views/NavbarView'
import Pagination from '../views/PaginationView'
import ProgressBar from '../views/ProgressBarView'
import Chart from '../views/ChartsView'
import PlansAndPricing from '../views/PlansAndPricingView'
import Switchers from '../views/SwitchersView'
import Toast from '../views/ToastView'
import Tooltip from '../views/TooltipView'
import Nav from '../views/NavsView'
import Datepicker from '../views/DatePickerView'
import Collapse from '../views/CollapseView'
import Extras from '../views/ExtrasView'
import Modal from '../views/ModalView'
import Jumbotron from '../views/JumbotronView';
import Chat from '../views/ChatView'
import GroupChatView from '../views/GroupChatView'
import CatalogView from '../views/CatalogView'
import InputView from '../views/InputView';
import Spinners from '../views/SpinerView'
import LoginView from '../views/LoginView'


const Tree = () => {
  return (
    <>
      <Col lg="9" md="12" sm="12" xs="12">
        <div className="main-content-right">
          <Switch>
            <Route exact path="/">
              <Redirect to="/alert" />
            </Route>
            <Route path="/alert">
              <Alert />
            </Route>
            <Route path="/badge">
              <Badge />
            </Route>
            <Route path="/buttons">
              <Button />
            </Route>
            <Route path="/breadcrumb">
              <Breadcrumb />
            </Route>
            <Route path="/card">
              <Card />
            </Route>
            <Route path="/dropdown">
              <Dropdown />
            </Route>
            <Route path="/button-group">
              <ButtonGroup />
            </Route>
            <Route path="/carousel">
              <Carousel />
            </Route>
            <Route path="/form">
              <Form />
            </Route>
            <Route path="/tables">
              <Table />
            </Route>
            <Route path="/list-group">
              <ListGroup />
            </Route>
            <Route path="/navbar">
              <Navbar />
            </Route>
            <Route path="/pagination">
              <Pagination />
            </Route>
            <Route path="/progressbar">
              <ProgressBar />
            </Route>
            <Route path="/chart">
              <Chart />
            </Route>
            <Route path="/plans-pricing">
              <PlansAndPricing />
            </Route>
            <Route path="/switchers">
              <Switchers />
            </Route>
            <Route path="/toast">
              <Toast />
            </Route>
            <Route path="/tooltip">
              <Tooltip />
            </Route>
            <Route path="/nav">
              <Nav />
            </Route>
            <Route path="/chat">
              <Chat />
            </Route>
            <Route path="/datepicker">
              <Datepicker />
            </Route>
            <Route path="/collapse">
              <Collapse />
            </Route>
            <Route path="/extras">
              <Extras />
            </Route>
            <Route path="/modal">
              <Modal />
            </Route>
            <Route path="/jumbotron">
              <Jumbotron />
            </Route>
            <Route path="/chat2">
              <Chat />
            </Route>
            <Route path="/webpage">
            </Route>
            <Route path="/groupchat">
              <GroupChatView />
            </Route>
            <Route path="/catalog">
              <CatalogView />
            </Route>
            <Route path="/input">
              <InputView />
            </Route>
            <Route path="/spinners">
              <Spinners />
            </Route>
            <Route path="/login">
              <LoginView />
            </Route>
          </Switch>
        </div>
      </Col>
    </>
  )
}

export default Tree