import React from 'react'
import { ListGroup, Badge, Button } from 'react-bootstrap'
import ReactPrism from '@versant-digital/react-prismjs';

const basicExample = `<ListGroup>
    <ListGroup.Item>Cras justo odio</ListGroup.Item>
    <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
    <ListGroup.Item>Morbi leo risus</ListGroup.Item>
    <ListGroup.Item>Porta ac consectetur ac</ListGroup.Item>
    <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
</ListGroup>`

const activeItems = `<ListGroup as="ul">
    <ListGroup.Item as="li" active>
        Cras justo odio
    </ListGroup.Item>
    <ListGroup.Item as="li">Dapibus ac facilisis in</ListGroup.Item>
    <ListGroup.Item as="li" disabled>
        Morbi leo risus
    </ListGroup.Item>
    <ListGroup.Item as="li">Porta ac consectetur ac</ListGroup.Item>
</ListGroup>`

const disabledItems = `<ListGroup>
    <ListGroup.Item disabled>Cras justo odio</ListGroup.Item>
    <ListGroup.Item disabled>Dapibus ac facilisis in</ListGroup.Item>
    <ListGroup.Item disabled>Morbi leo risus</ListGroup.Item>
    <ListGroup.Item disabled>Porta ac consectetur ac</ListGroup.Item>
</ListGroup>`

const actionableItems = `function alertClicked() {
    alert('You clicked the third ListGroupItem');
}

return(
    <ListGroup defaultActiveKey="#link1">
        <ListGroup.Item action href="#link1">
        Link 1
        </ListGroup.Item>
        <ListGroup.Item action href="#link2" disabled>
        Link 2
        </ListGroup.Item>
        <ListGroup.Item action onClick={alertClicked}>
        This one is a button
        </ListGroup.Item>
    </ListGroup>
)`

const flush = `<ListGroup variant="flush">
    <ListGroup.Item>Cras justo odio</ListGroup.Item>
    <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
    <ListGroup.Item>Morbi leo risus</ListGroup.Item>
    <ListGroup.Item>Porta ac consectetur ac</ListGroup.Item>
</ListGroup>`

const numbered = `<ListGroup as="ol" numbered>
    <ListGroup.Item as="li">Cras justo odio</ListGroup.Item>
    <ListGroup.Item as="li">Cras justo odio</ListGroup.Item>
    <ListGroup.Item as="li">Cras justo odio</ListGroup.Item>
</ListGroup>`

const customContent2 = `<ListGroup>
    <ListGroup.Item href="#" active>
        <div className="d-flex w-100 justify-content-between">
            <h5 className="mb-1">List group item heading</h5>
            <div className="small-heading-list-group">3 days ago</div>
        </div>
        <p className="list-group-text">Donec id elit non mi porta gravida at eget metus.
            Maecenas sed diam eget risus varius blandit.
        </p>
        <div className="small-footer-list-group">And some small print.</div>
    </ListGroup.Item>

    <ListGroup.Item href="#" >
        <div className="d-flex w-100 justify-content-between">
            <h5 className="mb-1">List group item heading</h5>
            <div className="small-heading-list-group">3 days ago</div>
        </div>
        <p className="list-group-text">Donec id elit non mi porta gravida at eget metus.
            Maecenas sed diam eget risus varius blandit.
        </p>
        <div className="small-footer-list-group">And some small print.</div>
    </ListGroup.Item>

    <ListGroup.Item href="#" >
        <div className="d-flex w-100 justify-content-between">
            <h5 className="mb-1">List group item heading</h5>
            <div className="small-heading-list-group">3 days ago</div>
        </div>
        <p className="list-group-text">Donec id elit non mi porta gravida at eget metus.
            Maecenas sed diam eget risus varius blandit.
        </p>
        <div className="small-footer-list-group">And some small print.</div>
    </ListGroup.Item>
</ListGroup>`

const buttons = `<ListGroup defaultActiveKey="#link1">
    <Button active>Cras justo odio</Button>
    <Button disabled>Dapibus ac facilisis in</Button>
    <Button>Morbi leo risus</Button>
    <Button>Porta ac consectetur ac</Button>
</ListGroup>`

const list = `<ListGroup defaultActiveKey="#link1">
    <ListGroup.Item action href="#link1">
        Cras justo odio
    </ListGroup.Item>
    <ListGroup.Item action href="#link2">
        Dapibus ac facilisis in
    </ListGroup.Item>
    <ListGroup.Item action onClick={alertClicked}>
        Morbi leo risus
    </ListGroup.Item>
    <ListGroup.Item action href="#link2" disabled>
        Porta ac consectetur ac
    </ListGroup.Item>
</ListGroup>
`
const horizontal = `<ListGroup horizontal>
    <ListGroup.Item>Cras justo odio</ListGroup.Item>
    <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
    <ListGroup.Item>Morbi leo risus</ListGroup.Item>
</ListGroup>`

const contextual = `<ListGroup>
    <ListGroup.Item>No style</ListGroup.Item>
    <ListGroup.Item variant="primary">A simple primary list group item</ListGroup.Item>
    <ListGroup.Item variant="secondary">A simple secondary list group item</ListGroup.Item>
    <ListGroup.Item variant="success">A simple success list group item</ListGroup.Item>
    <ListGroup.Item variant="danger">A simple danger list group item</ListGroup.Item>
    <ListGroup.Item variant="warning">A simple warning list group item</ListGroup.Item>
    <ListGroup.Item variant="info">A simple info list group item</ListGroup.Item>
    <ListGroup.Item variant="light">A simple light list group item</ListGroup.Item>
    <ListGroup.Item variant="dark">A simple dark list group item</ListGroup.Item>
</ListGroup>`

const withBadges = `<ListGroup as="ol">
    <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start">
        <div className="ms-2 me-auto">
            Cras justo odio
        </div>
        <Badge variant="primary" pill>
            14
        </Badge>
    </ListGroup.Item>
    <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start">
        <div className="ms-2 me-auto">
            Dapibus ac facilisis in
        </div>
        <Badge variant="primary" pill>
            2
        </Badge>
    </ListGroup.Item>
    <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start">
        <div className="ms-2 me-auto">
            Morbi leo risus
        </div>
        <Badge variant="primary" pill>
            1
        </Badge>
    </ListGroup.Item>
</ListGroup>`

const customContent = `<ListGroup as="ol" numbered>
    <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
    >
        <div className="ms-2 me-auto">
            <div className="fw-bold">Subheading</div>
            Cras justo odio
        </div>
        <Badge bg="primary" pill>
            14
        </Badge>
    </ListGroup.Item>
    <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
    >
        <div className="ms-2 me-auto">
            <div className="fw-bold">Subheading</div>
            Cras justo odio
        </div>
        <Badge bg="primary" pill>
            14
        </Badge>
    </ListGroup.Item>
    <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
    >
        <div className="ms-2 me-auto">
            <div className="fw-bold">Subheading</div>
            Cras justo odio
        </div>
        <Badge bg="primary" pill>
            14
        </Badge>
    </ListGroup.Item>
</ListGroup>`

const contextualAction = `<ListGroup>
    <ListGroup.Item>No style</ListGroup.Item>
    <ListGroup.Item action variant="primary">
        Primary
    </ListGroup.Item>
    <ListGroup.Item action variant="secondary">
        Secondary
    </ListGroup.Item>
    <ListGroup.Item action variant="success">
        Success
    </ListGroup.Item>
    <ListGroup.Item action variant="danger">
        Danger
    </ListGroup.Item>
    <ListGroup.Item action variant="warning">
        Warning
    </ListGroup.Item>
    <ListGroup.Item action variant="info">
        Info
    </ListGroup.Item>
    <ListGroup.Item action variant="light">
        Light
    </ListGroup.Item>
    <ListGroup.Item action variant="dark">
        Dark
    </ListGroup.Item>
</ListGroup>`

const ListGroupView = () => {



    function alertClicked() {
        alert('You clicked the third ListGroupItem');
    }

    return (

        <>

            <h1 className="title-section">List groups</h1>
            <p>List groups are a flexible and powerful component for displaying a series of content.
                Modify and extend them to support just about any content within.</p>

            <h5 className="subtitle">Basic Example</h5>

            <ListGroup>
                <ListGroup.Item>Cras justo odio</ListGroup.Item>
                <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                <ListGroup.Item>Morbi leo risus</ListGroup.Item>
                <ListGroup.Item>Porta ac consectetur ac</ListGroup.Item>
                <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
            </ListGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {basicExample}
            </ReactPrism>

            <h5 className="subtitle">Active items</h5>

            <p>Set the <code>active</code> prop to indicate the list groups current active selection.</p>

            <ListGroup as="ul">
                <ListGroup.Item as="li" active>
                    Cras justo odio
                </ListGroup.Item>
                <ListGroup.Item as="li">Dapibus ac facilisis in</ListGroup.Item>
                <ListGroup.Item as="li" disabled>
                    Morbi leo risus
                </ListGroup.Item>
                <ListGroup.Item as="li">Porta ac consectetur ac</ListGroup.Item>
            </ListGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {activeItems}
            </ReactPrism>

            <h5 className="subtitle">Disabled items</h5>

            <p>Set the <code>disabled</code> prop to prevent actions on a <code>&lt;ListGroup.Item&gt;</code>. For elements
                that aren't naturally disable-able (like anchors) <code>onClick</code> handlers are added
                that call <code>preventDefault</code> to mimick disabled behavior.</p>

            <ListGroup>
                <ListGroup.Item disabled>Cras justo odio</ListGroup.Item>
                <ListGroup.Item disabled>Dapibus ac facilisis in</ListGroup.Item>
                <ListGroup.Item disabled>Morbi leo risus</ListGroup.Item>
                <ListGroup.Item disabled>Porta ac consectetur ac</ListGroup.Item>
            </ListGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {disabledItems}
            </ReactPrism>

            <h5 className="subtitle">Actionable items</h5>

            <p>Toggle the <code>action</code> prop to create <em>actionable</em> list group
                items, with disabled, hover and active styles. List item actions will render
                a <code>&lt;button&gt;</code> or <code>&lt;a&gt;</code> (depending on the presence of an <code>href</code>) by default but
                can be overridden by setting the <code>as</code> prop as usual.</p>

            <p>List items <code>actions</code> are distinct from plain items to ensure that click or tap
                affordances aren't applied to non-interactive items.</p>

            <ListGroup defaultActiveKey="#link1">
                <ListGroup.Item action href="#link1">
                    Link 1
                </ListGroup.Item>
                <ListGroup.Item action href="#link2" disabled>
                    Link 2
                </ListGroup.Item>
                <ListGroup.Item action onClick={alertClicked}>
                    This one is a button
                </ListGroup.Item>
            </ListGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {actionableItems}
            </ReactPrism>

            <h5 className="subtitle">Flush</h5>

            <p>Add the <code>flush</code> variant to remove outer borders and rounded corners to render list group items
                edge-to-edge in a parent container <a href="/components/cards/#list-groups">such as a <code>Card</code></a>.</p>

            <ListGroup variant="flush">
                <ListGroup.Item>Cras justo odio</ListGroup.Item>
                <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                <ListGroup.Item>Morbi leo risus</ListGroup.Item>
                <ListGroup.Item>Porta ac consectetur ac</ListGroup.Item>
            </ListGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {flush}
            </ReactPrism>

            <h5 className="subtitle">Numbered</h5>

            <p>Add the <code>numbered</code> prop to opt into numbered list group items. Numbers are generated via CSS
                (as opposed to a <code>&lt;ol&gt;</code>s default browser styling) for better placement inside list group items
                and to allow for better customization.</p>

            <ListGroup as="ol" numbered>
                <ListGroup.Item as="li">Cras justo odio</ListGroup.Item>
                <ListGroup.Item as="li">Cras justo odio</ListGroup.Item>
                <ListGroup.Item as="li">Cras justo odio</ListGroup.Item>
            </ListGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {numbered}
            </ReactPrism>

            <p>These work great with custom content as well.</p>

            <ListGroup as="ol" numbered>
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Subheading</div>
                        Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                        14
                    </Badge>
                </ListGroup.Item>
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Subheading</div>
                        Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                        14
                    </Badge>
                </ListGroup.Item>
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Subheading</div>
                        Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                        14
                    </Badge>
                </ListGroup.Item>
            </ListGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {customContent}
            </ReactPrism>

            <h5 className="subtitle">Links and buttons</h5>

            <ListGroup defaultActiveKey="#link1">
                <ListGroup.Item action href="#link1">
                    Cras justo odio
                </ListGroup.Item>
                <ListGroup.Item action href="#link2">
                    Dapibus ac facilisis in
                </ListGroup.Item>
                <ListGroup.Item action onClick={alertClicked}>
                    Morbi leo risus
                </ListGroup.Item>
                <ListGroup.Item action href="#link3" disabled>
                    Porta ac consectetur ac
                </ListGroup.Item>
            </ListGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {list}
            </ReactPrism>

            <p>With buttons, you can also make use of the disabled attribute instead of the
                .disabled class. Sadly, a-s don’t support the disabled attribute.</p>

            <ListGroup defaultActiveKey="#link1">
                <Button active>Cras justo odio</Button>
                <Button disabled>Dapibus ac facilisis in</Button>
                <Button>Morbi leo risus</Button>
                <Button>Porta ac consectetur ac</Button>
            </ListGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {buttons}
            </ReactPrism>

            <h5 className="subtitle">Horizontal</h5>

            <p>Use the <code>horizontal</code> prop to make the ListGroup render horizontally. Currently <strong>horizontal list groups cannot be combined with flush list groups.</strong></p>

            <ListGroup horizontal>
                <ListGroup.Item>Cras justo odio</ListGroup.Item>
                <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                <ListGroup.Item>Morbi leo risus</ListGroup.Item>
            </ListGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {horizontal}
            </ReactPrism>

            <h5 className="subtitle">Contextual classes</h5>

            <p>Use contextual variants on <code>&lt;ListGroup.Item&gt;</code>s to style them with a stateful background and color.</p>

            <ListGroup>
                <ListGroup.Item>No style</ListGroup.Item>
                <ListGroup.Item variant="primary">A simple primary list group item</ListGroup.Item>
                <ListGroup.Item variant="secondary">A simple secondary list group item</ListGroup.Item>
                <ListGroup.Item variant="success">A simple success list group item</ListGroup.Item>
                <ListGroup.Item variant="danger">A simple danger list group item</ListGroup.Item>
                <ListGroup.Item variant="warning">A simple warning list group item</ListGroup.Item>
                <ListGroup.Item variant="info">A simple info list group item</ListGroup.Item>
                <ListGroup.Item variant="light">A simple light list group item</ListGroup.Item>
                <ListGroup.Item variant="dark">A simple dark list group item</ListGroup.Item>
            </ListGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {contextual}
            </ReactPrism>

            <ListGroup>
                <ListGroup.Item>No style</ListGroup.Item>
                <ListGroup.Item action variant="primary">
                    Primary
                </ListGroup.Item>
                <ListGroup.Item action variant="secondary">
                    Secondary
                </ListGroup.Item>
                <ListGroup.Item action variant="success">
                    Success
                </ListGroup.Item>
                <ListGroup.Item action variant="danger">
                    Danger
                </ListGroup.Item>
                <ListGroup.Item action variant="warning">
                    Warning
                </ListGroup.Item>
                <ListGroup.Item action variant="info">
                    Info
                </ListGroup.Item>
                <ListGroup.Item action variant="light">
                    Light
                </ListGroup.Item>
                <ListGroup.Item action variant="dark">
                    Dark
                </ListGroup.Item>
            </ListGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {contextualAction}
            </ReactPrism>

            <h5 className="subtitle">With badges</h5>

            <ListGroup as="ol">
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start">
                    <div className="ms-2 me-auto">
                        Cras justo odio
                    </div>
                    <Badge variant="primary" pill>
                        14
                    </Badge>
                </ListGroup.Item>
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start">
                    <div className="ms-2 me-auto">
                        Dapibus ac facilisis in
                    </div>
                    <Badge variant="primary" pill>
                        2
                    </Badge>
                </ListGroup.Item>
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start">
                    <div className="ms-2 me-auto">
                        Morbi leo risus
                    </div>
                    <Badge variant="primary" pill>
                        1
                    </Badge>
                </ListGroup.Item>
            </ListGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {withBadges}
            </ReactPrism>

            <h5 className="subtitle">Custom content</h5>

            <ListGroup>
                <ListGroup.Item href="#" active>
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">List group item heading</h5>
                        <div className="small-heading-list-group">3 days ago</div>
                    </div>
                    <p className="list-group-text">Donec id elit non mi porta gravida at eget metus.
                        Maecenas sed diam eget risus varius blandit.
                    </p>
                    <div className="small-footer-list-group">And some small print.</div>
                </ListGroup.Item>

                <ListGroup.Item href="#1" >
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">List group item heading</h5>
                        <div className="small-heading-list-group">3 days ago</div>
                    </div>
                    <p className="list-group-text">Donec id elit non mi porta gravida at eget metus.
                        Maecenas sed diam eget risus varius blandit.
                    </p>
                    <div className="small-footer-list-group">And some small print.</div>
                </ListGroup.Item>

                <ListGroup.Item href="#2" >
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">List group item heading</h5>
                        <div className="small-heading-list-group">3 days ago</div>
                    </div>
                    <p className="list-group-text">Donec id elit non mi porta gravida at eget metus.
                        Maecenas sed diam eget risus varius blandit.
                    </p>
                    <div className="small-footer-list-group">And some small print.</div>
                </ListGroup.Item>
            </ListGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {customContent2}
            </ReactPrism>

        </>
    );
}

export default ListGroupView;