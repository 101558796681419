import React, { useState, useMemo, useEffect } from 'react'
import { Table, Form, Pagination, Image, InputGroup, FormControl } from 'react-bootstrap'
import arrowIco from '../assets/sort-arrow.svg'
import searchTool from '../assets/search.svg'
import tableEdit from '../assets/table-edit.svg'
import tableDelete from '../assets/table-delete.svg'
import tableView from '../assets/table-view.svg'
import SelectComponent from './SelectComponent';

const fields = [
    {
        name: "index",
        sortable: false
    },
    {
        name: "name",
        sortable: true
    },
    {
        name: "street",
        sortable: true
    },
    {
        name: "city",
        sortable: true
    },
    {
        name: "income",
        sortable: true
    },
    {
        name: "zip",
        sortable: false
    }
]

const rawData = [
    {
        index: 1,
        name: "A company",
        street: "WWW",
        city: "Višegrad",
        income: 300789,
        zip: "431241324132"
    },
    {
        index: 2,
        name: "A wizard",
        street: "WWF",
        city: "Bijeljina",
        income: 5000000,
        zip: "431241324132"
    },
    {
        index: 3,
        name: "B media",
        street: "AAC",
        city: "Banja Luka",
        income: 2000,
        zip: "216515614654"
    },
    {
        index: 4,
        name: "C media",
        street: "AAA",
        city: "Šid",
        income: 100200,
        zip: "431241324132"
    },
    {
        index: 5,
        name: "W media",
        street: "BHG",
        city: "Kragujevac",
        income: 50500,
        zip: "3215619651"
    },
    {
        index: 6,
        name: "Alfa company",
        street: "Compa",
        city: "Višegrad",
        income: 30789,
        zip: "4312413324132"
    },
    {
        index: 7,
        name: "Art wizard",
        street: "Wist",
        city: "Bijeljina",
        income: 5080000,
        zip: "431241324132"
    },
    {
        index: 8,
        name: "Bač media",
        street: "AAC",
        city: "Banja Luka",
        income: 2000,
        zip: "216515614654"
    },
    {
        index: 9,
        name: "CA media",
        street: "AAA",
        city: "Šipovo",
        income: 1001200,
        zip: "431241324132"
    },
    {
        index: 10,
        name: "Lista media",
        street: "BHG",
        city: "Rim",
        income: 70500,
        zip: "32115619651"
    },
    {
        index: 11,
        name: "W media",
        street: "BHG",
        city: "Kragujevac",
        income: 50500,
        zip: "3215619651"
    },
    {
        index: 12,
        name: "Alfa company",
        street: "Compa",
        city: "Višegrad",
        income: 30789,
        zip: "4312413324132"
    },
    {
        index: 13,
        name: "Art wizard",
        street: "Wist",
        city: "Bijeljina",
        income: 5080000,
        zip: "431241324132"
    },
    {
        index: 14,
        name: "Bač media",
        street: "AAC",
        city: "Banja Luka",
        income: 2000,
        zip: "216515614654"
    },
    {
        index: 15,
        name: "CA media",
        street: "AAA",
        city: "Šipovo",
        income: 1001200,
        zip: "431241324132"
    },
    {
        index: 16,
        name: "Lista media",
        street: "BHG",
        city: "Rim",
        income: 70500,
        zip: "32115619651"
    }

]

const TableView = () => {

    useEffect(() => {
        
    }, [])

    const [data, setData] = useState(rawData);
    const [order, setOrder] = useState("ASC");
    const [searchText, setSearchText] = useState("")
    const [pageSize, setPageSize] = useState(3);
    const [currentPage, setCurrentPage] = useState(1);

    const renderPagination = () => {
        var rows = []
        for (let i = 1; i < Math.ceil(filteredData.length / pageSize) + 1; i++) { //math.ceil - if there is 16 data and 5 is number per page, there will be 4 pagination button, or if there is 15 there will be 3
            rows.push(<Pagination.Item key={i} onClick={(e) => currentPageSelect(e)}>{i}</Pagination.Item>)
        }
        return rows
    }

    const currentPageSelect = (e) => {
        setCurrentPage(prev => parseInt(e.target.text))

    }

    const selectChange = (e) => {
        setPageSize(e.target.value)
        setCurrentPage(1);

    }

    const filtering = (text, data) => {

        setCurrentPage(1); // to return on first page when filter, because it leaves on page number 4 but there is only one page of result

        return data.filter((row) =>
            fields.some((column) => row[column.name].toString().toLowerCase().indexOf(text.toLowerCase()) > -1))

    }

    const sorting = (field) => {

        fields.map(header => {

            if (header.name === field && header.sortable) { //sortable checking


                if (order === "ASC") { //ascending order sorting for numbers and strings
                    const sortedData = [...filteredData].sort((a, b) => {
                        if (typeof a[field] === "number") {
                            return a[field] - b[field] //number sorting
                        } else {
                            return a[field].toLowerCase() > b[field].toLowerCase() ? 1 : -1 //string sorting
                        }
                    }
                    )
                    setData(sortedData);
                    setOrder("DSC")
                }

                if (order === "DSC") { //descending order sorting for numbers and strings
                    const sortedData = [...filteredData].sort((a, b) => {
                        if (typeof a[field] === "number") {
                            return b[field] - a[field] //number sorting
                        } else {
                            return a[field].toLowerCase() < b[field].toLowerCase() ? 1 : -1 //string sorting
                        }
                    }
                    )
                    setData(sortedData);
                    setOrder("ASC")
                }
            }
        }
        )

    }

    const filteredData = useMemo(() => {
        return filtering(searchText, data);

    }, [searchText, data])

    // _______________________________________________

    return (

        <>
            <h1 className="title-section">Table</h1>

            <div className="custom-table">

                <div className="custom-table-menu">

                    <p>Show</p>
                  
                    <SelectComponent type="single" onChange={selectChange}>
                        <option value="3">3</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                    </SelectComponent>

                    <p>entries</p>
                    <div className="custom-search">
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1"><Image src={searchTool} /></InputGroup.Text>
                            <FormControl
                                placeholder="Filter results"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyDown={() => filteredData}
                            />
                        </InputGroup>
                    </div>

                </div>
                <Table responsive>
                    <thead>
                        <tr>

                            {fields.map((field) => (
                                <th onClick={() => sorting(field.name)}>{field.name}{field.sortable && <Image src={arrowIco} className="sort-icon" />}</th>
                            ))}
                            <th>action</th>
                        </tr>
                    </thead>

                    <tbody>

                        {filteredData.slice((currentPage * pageSize) - pageSize, currentPage * pageSize).map((obj) => //after filter, pagination slice
                            <tr>

                                {Object.entries(obj).map(([key, value]) => <td>{value}</td>)}
                                <td className="custom-table-menu-column"><Image src={tableView} /><Image src={tableEdit} /><Image src={tableDelete} /></td>
                            </tr>
                        )}

                    </tbody>
                </Table>
                <div className="custom-table-bottom">
                    <Pagination>
                        <Pagination.Prev className="prev-button" onClick={() => setCurrentPage(prev => prev !== 1 ? prev - 1 : prev)} />

                        {renderPagination()}

                        <Pagination.Next className="next-button" onClick={() => setCurrentPage(prev => prev !== Math.ceil(filteredData.length / pageSize) ? prev + 1 : prev)} />
                    </Pagination>



                    <div className="custom-table-stats">
                        <p>Showing <span>{(currentPage * pageSize) - pageSize + 1}</span> to <span>{(currentPage * pageSize) > filteredData.length ? filteredData.length : currentPage * pageSize}</span> of <span>{filteredData.length}</span> etries.</p>
                    </div>


                </div>
            </div>
        </>
    )
}

export default TableView