import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import ReactPrism from '@versant-digital/react-prismjs';

const baseNav = `<Nav
activeKey="/home"
onSelect={(selectedKey) => alert(\`selected \${selectedKey}\`)}
>
    <Nav.Item>
        <Nav.Link href="/home">Active</Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link eventKey="link-1">Link</Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link eventKey="link-2">Link</Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link eventKey="disabled" disabled>
                Disabled
            </Nav.Link>
    </Nav.Item>
</Nav>`

const baseNav2 = `<Nav defaultActiveKey="/home" as="ul">
    <Nav.Item as="li">
        <Nav.Link href="/home">Active</Nav.Link>
    </Nav.Item>
    <Nav.Item as="li">
        <Nav.Link eventKey="link-1">Link</Nav.Link>
    </Nav.Item>
    <Nav.Item as="li">
        <Nav.Link eventKey="link-2">Link</Nav.Link>
    </Nav.Item>
</Nav>`

const alignment = `<Nav className="justify-content-center" activeKey="/home">
  <Nav.Item>
    <Nav.Link href="/home">Active</Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="link-1">Link</Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="link-2">Link</Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="disabled" disabled>
      Disabled
    </Nav.Link>
  </Nav.Item>
</Nav>
<p className="text-center mt-4 mb-4">Or right-aligned</p>
<Nav className="justify-content-end" activeKey="/home">
  <Nav.Item>
    <Nav.Link href="/home">Active</Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="link-1">Link</Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="link-2">Link</Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="disabled" disabled>
      Disabled
    </Nav.Link>
  </Nav.Item>
</Nav>`

const verticalNav = `<Nav defaultActiveKey="#" className="flex-column">
    <Nav.Link href="#">Active</Nav.Link>
    <Nav.Link eventKey="link-1">Link</Nav.Link>
    <Nav.Link eventKey="link-2">Link</Nav.Link>
    <Nav.Link eventKey="disabled" disabled>
        Disabled
    </Nav.Link>
</Nav>`

const navs = `<Nav variant="pills" defaultActiveKey="/home">
    <Nav.Item>
        <Nav.Link href="/home">Active</Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link eventKey="link-1">Option 2</Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link eventKey="disabled" disabled>
            Disabled
        </Nav.Link>
    </Nav.Item>
</Nav>`

const navsButton = `<Nav defaultActiveKey="/home" variant="pills" className="flex-column">
    <Nav.Link href="#">Home</Nav.Link>
    <Nav.Link eventKey="link-1">Profile</Nav.Link>
    <Nav.Link eventKey="link-2">Messages</Nav.Link>
    <Nav.Link eventKey="disabled" disabled>
        Disabled
    </Nav.Link>
</Nav>`

const tabs = `<Nav variant="tabs" defaultActiveKey="/home">
    <Nav.Item>
        <Nav.Link href="#">This week</Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link eventKey="link-1">This month</Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link eventKey="disabled">
            This week
        </Nav.Link>
    </Nav.Item>
</Nav>`

const fill = `<Nav fill variant="tabs" defaultActiveKey="#">
    <Nav.Item>
        <Nav.Link href="#">Active</Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link eventKey="link-1">Loooonger NavLink</Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link eventKey="link-2">Link</Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link eventKey="disabled" disabled>
            Disabled
        </Nav.Link>
    </Nav.Item>
</Nav>`

const fillJustified = `<Nav justify variant="tabs" defaultActiveKey="#">
    <Nav.Item>
        <Nav.Link href="#">Active</Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link eventKey="link-1">Loooonger NavLink</Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link eventKey="link-2">Link</Nav.Link>
    </Nav.Item>
    <Nav.Item>
    <Nav.Link eventKey="disabled" disabled>
        Disabled
    </Nav.Link>
    </Nav.Item>
</Nav>`

const dropdownTabs = `<Nav variant="pills" activeKey="1" onSelect={handleSelect}>
    <Nav.Item>
        <Nav.Link eventKey="1" href="#">
            Active
        </Nav.Link>
    </Nav.Item>
    <NavDropdown title="Dropdown" id="nav-dropdown">
        <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>
        <NavDropdown.Item eventKey="4.2">Another action</NavDropdown.Item>
        <NavDropdown.Item eventKey="4.3">Something else here</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="4.4">Separated link</NavDropdown.Item>
    </NavDropdown>
    <Nav.Item>
        <Nav.Link eventKey="2" title="Item">
            Link
        </Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link eventKey="3" disabled>
            Disabled
        </Nav.Link>
    </Nav.Item>
</Nav>`

const dropdownTabs2 = `<Nav variant="tabs" activeKey="1" onSelect={handleSelect} className="bg-light">
    <Nav.Item>
        <Nav.Link eventKey="1" href="#">
            Active
        </Nav.Link>
    </Nav.Item>
    <NavDropdown title="Dropdown" id="nav-dropdown">
        <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>
        <NavDropdown.Item eventKey="4.2">Another action</NavDropdown.Item>
        <NavDropdown.Item eventKey="4.3">Something else here</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="4.4">Separated link</NavDropdown.Item>
    </NavDropdown>
    <Nav.Item>
        <Nav.Link eventKey="2" title="Item">
            Link
        </Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link eventKey="3" disabled>
            Disabled
        </Nav.Link>
    </Nav.Item>
</Nav>`

const NavsView = () => {


    const handleSelect = (eventKey) => alert(`selected ${eventKey}`);

    return (
        <>

            <h1 className="title-section">Base Nav</h1>

            <p>Navigation bits in Bootstrap all share a general <code>Nav </code>
                component and styles. Swap <code>variant</code>s to switch between each
                style. The base <code>Nav</code> component is built with flexbox and
                provide a strong foundation for building all types of navigation
                components.</p>

            <aside role="note" className="Callout-styles-module--warning--39Zw- Callout-styles-module--callout--1_K3h"><div>The basic, variant-less, <code>Nav</code> component does not include any <code>active</code> prop styling!</div></aside>

            <br />

            <Nav
                activeKey="/home"
                onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
            >
                <Nav.Item>
                    <Nav.Link href="/home">Active</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-1">Link</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-2">Link</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="disabled" disabled>
                        Disabled
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {baseNav}
            </ReactPrism>

            <p><code>&lt;Nav&gt;</code> markup is very flexible and styling is controlled via classes so you can
                use whatever elements you like to build your navs. By default <code>&lt;Nav&gt;</code> and <code>&lt;Nav.Item&gt;</code> both
                render <code>&lt;div&gt;</code>s instead of <code>&lt;ul&gt;</code> and <code>&lt;li&gt;</code> elements respectively.
                This because it's possible (and common) to leave off the <code>&lt;Nav.Item&gt;</code>'s and
                render a <code>&lt;Nav.Link&gt;</code> directly, which would create invalid markup by default (<code>ul &gt; a</code>).</p>

            <p>When a <code>&lt;ul&gt;</code> is appropriate you can render one via the <code>as</code> prop; be sure to
                also set your items to <code>&lt;li&gt;</code> as well!</p>

            <Nav defaultActiveKey="/home" as="ul">
                <Nav.Item as="li">
                    <Nav.Link href="/home">Active</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Nav.Link eventKey="link-1">Link</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Nav.Link eventKey="link-2">Link</Nav.Link>
                </Nav.Item>
            </Nav>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {baseNav2}
            </ReactPrism>

            <h5 className="subtitle">Alignment and orientation</h5>

            <p>You can control the the direction and orientation of the
                <code> Nav</code> by making use of the <a href="https://getbootstrap.com/docs/4.0/layout/grid/#horizontal-alignment">flexbox layout</a> utility classes.
                By default, navs are left-aligned, but that is easily changed to center or right-aligned.</p>


            <Nav className="justify-content-center" activeKey="/home">
                <Nav.Item>
                    <Nav.Link href="/home">Active</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-1">Link</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-2">Link</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="disabled" disabled>
                        Disabled
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <p className="text-center mt-4 mb-4">Or right-aligned</p>
            <Nav className="justify-content-end" activeKey="/home">
                <Nav.Item>
                    <Nav.Link href="/home">Active</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-1">Link</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-2">Link</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="disabled" disabled>
                        Disabled
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {alignment}
            </ReactPrism>

            <h5 className="subtitle">Vertical Nav</h5>

            <p>Create stacked navs by changing the flex item direction with the .flex-column class, or your own css. You can even use the responsive versions to stack in some viewports but not others (e.g. .flex-sm-column).</p>

            <Nav defaultActiveKey="#" className="flex-column">
                <Nav.Link href="#">Active</Nav.Link>
                <Nav.Link eventKey="link-1">Link</Nav.Link>
                <Nav.Link eventKey="link-2">Link</Nav.Link>
                <Nav.Link eventKey="disabled" disabled>
                    Disabled
                </Nav.Link>
            </Nav>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {verticalNav}
            </ReactPrism>

            <h5 className="subtitle">Pills</h5>

            <p>An alternative visual variant.</p>

            <Nav variant="pills" defaultActiveKey="/home">
                <Nav.Item>
                    <Nav.Link href="/home">Active</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-1">Option 2</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="disabled" disabled>
                        Disabled
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {navs}
            </ReactPrism>

            <h5 className="subtitle">Vertical Pill Navs</h5>

            <Nav defaultActiveKey="/home" variant="pills" className="flex-column">
                <Nav.Link href="/home">Home</Nav.Link>
                <Nav.Link eventKey="link-1">Profile</Nav.Link>
                <Nav.Link eventKey="link-2">Messages</Nav.Link>
                <Nav.Link eventKey="disabled" disabled>
                    Disabled
                </Nav.Link>
            </Nav>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {navsButton}
            </ReactPrism>

            <h5 className="subtitle">Tabs</h5>

            <p>Visually represent nav items as "tabs". This style pairs nicely with
                tabbable regions created by our <a href="../tabs/">Tab components</a>.</p>

            <p>Note: creating a vertical nav (<code>.flex-column</code>) with tabs styling is unsupported by Bootstrap's default stylesheet.</p>

            <Nav variant="tabs" defaultActiveKey="#" className="bg-light">
                <Nav.Item>
                    <Nav.Link href="#">This week</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-1">This month</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="disabled">
                        This week
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {tabs}
            </ReactPrism>


            <h5 className="subtitle">Fill and justify</h5>

            <p>Force the contents of your nav to extend the full available width. To
                proportionately fill the space use <code>fill</code>. Notice that the
                nav is the entire width but each nav item is a different size.</p>

            <Nav fill variant="tabs" defaultActiveKey="#">
                <Nav.Item>
                    <Nav.Link href="#">Active</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-1">Loooonger NavLink</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-2">Link</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="disabled" disabled>
                        Disabled
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {fill}
            </ReactPrism>

            <p>If you want each NavItem to be the same size use justify.</p>

            <Nav justify variant="tabs" defaultActiveKey="#">
                <Nav.Item>
                    <Nav.Link href="#">Active</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-1">Loooonger NavLink</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-2">Link</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="disabled" disabled>
                        Disabled
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {fillJustified}
            </ReactPrism>

            <h5 className="subtitle">Using dropdowns</h5>

            <p>You can mix and match the Dropdown components with the NavLink and NavItem components to create a Dropdown that plays well in a Nav component</p>

            <p>The above demonstrates how flexible the component model can be. But if
                you didn't want to roll your own versions we've included a
                straight-forward <code>&lt;NavDropdown&gt;</code> that works for most cases.</p>

            <Nav variant="pills" activeKey="1" onSelect={handleSelect}>
                <Nav.Item>
                    <Nav.Link eventKey="1" href="#">
                        Active
                    </Nav.Link>
                </Nav.Item>
                <NavDropdown title="Dropdown" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.2">Another action</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.3">Something else here</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.4">Separated link</NavDropdown.Item>
                </NavDropdown>
                <Nav.Item>
                    <Nav.Link eventKey="2" title="Item">
                        Link
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="3" disabled>
                        Disabled
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {dropdownTabs}
            </ReactPrism>

            <Nav variant="tabs" activeKey="1" onSelect={handleSelect} className="bg-light">
                <Nav.Item>
                    <Nav.Link eventKey="1" href="#">
                        Active
                    </Nav.Link>
                </Nav.Item>
                <NavDropdown title="Dropdown" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.2">Another action</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.3">Something else here</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.4">Separated link</NavDropdown.Item>
                </NavDropdown>
                <Nav.Item>
                    <Nav.Link eventKey="2" title="Item">
                        Link
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="3" disabled>
                        Disabled
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {dropdownTabs2}
            </ReactPrism>
        </>
    )
}

export default NavsView;