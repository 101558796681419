import React, { useRef, useEffect, useState, useCallback } from 'react'
import { Form, Col, Row, FloatingLabel, InputGroup, FormControl, Image, Button } from 'react-bootstrap'
import InputRange from 'react-input-range'
import ReactPrism from '@versant-digital/react-prismjs';
import searchTool from '../assets/search.svg'
import { Formik } from 'formik';
import * as yup from 'yup';
import SelectComponent from '../views/SelectComponent'
import 'react-input-range/lib/css/index.css';

const formControls = `<Form>
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="name@example.com" />
    </Form.Group>
</Form>`

const roundFormControls = `<Form>
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="name@example.com" className="rounded-pill" />
    </Form.Group>
</Form>`

const exampleTextArea = `<Form>
    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Example textarea</Form.Label>
        <Form.Control as="textarea" rows={3} placeholder="Your message here..." />
    </Form.Group>
</Form>`

const fileInput = `<Form.Group controlId="formFile" className="mb-3">
    <Form.Label>Default file input example</Form.Label>
    <Form.Control type="file" />
</Form.Group>`

const select = `<SelectComponent type="single">
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
    <option value="3">Option 3</option>
    <option value="4">Option 4</option>
    <option value="5">Option 5</option>
</SelectComponent>`

const exampleSelect = `<SelectComponent type="multiple">
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
    <option value="3">Option 3</option>
    <option value="4">Option 4</option>
    <option value="5">Option 5</option>
</SelectComponent>`

const fileInputRounded = `<Form.Group controlId="formFile" className="mb-3 rounded-pill">
    <Form.Label>Default file input example</Form.Label>
    <Form.Control type="file" />
</Form.Group>`

const inputRange = `const [inputRangeValue, setInputRangeValue] = useState({ value: 10 });

return(
    <div className="custom-input-range-wrapper">
        <div className="custom-input-range">
            <InputRange
                maxValue={20}
                minValue={0}
                value={inputRangeValue.value}
                onChange={value => setInputRangeValue({ value })}
            />
        </div>
    </div>
)`

const search = `<div className="custom-search">
    <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1"><Image src={searchTool} /></InputGroup.Text>
        <FormControl
            placeholder="Search for..."
            aria-label="search"
            aria-describedby="basic-addon1"
        />
    </InputGroup>
</div>`

const searchRounded = `<div className="custom-search rounded">
    <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1"><Image src={searchTool} /></InputGroup.Text>
        <FormControl
            placeholder="Search for..."
            aria-label="search"
            aria-describedby="basic-addon1"
        />
    </InputGroup>
</div>`

const formControl1 = `<Form>
    <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" />
        <Form.Text className="text-muted">
            We'll never share your email with anyone else.
        </Form.Text>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="Password" />
    </Form.Group>
    <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <div className="checkbox-wrapper">
            <Form.Check type="checkbox" label="Check me out" />
        </div>
    </Form.Group>
    <Button variant="primary" type="submit">
        Submit
    </Button>
</Form>`

const disabledForms = `<>
    <Form.Group className="mb-3">
        <Form.Label>Disabled input</Form.Label>
        <Form.Control placeholder="Disabled input" disabled />
    </Form.Group>
    <Form.Group className="mb-3">
        <Form.Label>Disabled select menu</Form.Label>
        <Form.Select disabled>
            <option>Disabled select</option>
        </Form.Select>
    </Form.Group>
    <Form.Group className="mb-3">
        <div className="checkbox-wrapper">
            <Form.Check type="checkbox" label="Cant check this!" disabled />
        </div>
    </Form.Group>
</>`

const disableFieldset = `<Form>
    <fieldset disabled>
        <Form.Group className="mb-3">
            <Form.Label htmlFor="disabledTextInput">Disabled input</Form.Label>
            <Form.Control id="disabledTextInput" placeholder="Disabled input" />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label htmlFor="disabledSelect">Disabled select menu</Form.Label>
            <Form.Select id="disabledSelect">
                <option>Disabled select</option>
            </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
            <div className="checkbox-wrapper">
                <Form.Check type="checkbox" label="Check me out"/>
            </div>
        </Form.Group>
        <Button type="submit">Submit</Button>
    </fieldset>
</Form>`

const selectSizes = `<SelectComponent type="single" size="lg">
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
    <option value="3">Option 3</option>
    <option value="4">Option 4</option>
    <option value="5">Option 5</option>
</SelectComponent>
<br />
<SelectComponent type="single" >
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
    <option value="3">Option 3</option>
    <option value="4">Option 4</option>
    <option value="5">Option 5</option>
</SelectComponent>
<br />
<SelectComponent type="single" size="sm">
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
    <option value="3">Option 3</option>
    <option value="4">Option 4</option>
    <option value="5">Option 5</option>
</SelectComponent>`

const selectSizesRounded = `<SelectComponent type="single" rounded size="lg">
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
    <option value="3">Option 3</option>
    <option value="4">Option 4</option>
    <option value="5">Option 5</option>
</SelectComponent>
<br />
<SelectComponent type="single" rounded>
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
    <option value="3">Option 3</option>
    <option value="4">Option 4</option>
    <option value="5">Option 5</option>
</SelectComponent>
<br />
<SelectComponent type="single" rounded size="sm">
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
    <option value="3">Option 3</option>
    <option value="4">Option 4</option>
    <option value="5">Option 5</option>
</SelectComponent>`

const readOnlyInput = `<Form.Control type="text" placeholder="Readonly input here..." readOnly />`

const checkboxStandard = `<InputGroup className="mb-3 checkbox" variant="primary">
    <InputGroup.Checkbox aria-label="Checkbox for following text input" />
    <FormControl aria-label="Text input with radio button" />
</InputGroup>

<InputGroup className="mb-3 checkbox" variant="light">
    <InputGroup.Checkbox aria-label="Checkbox for following text input" />
    <FormControl aria-label="Text input with radio button" />
</InputGroup>`

const checkboxBordered = `<InputGroup className="mb-3 checkbox" variant="primary bordered">
    <InputGroup.Checkbox aria-label="Checkbox for following text input" />
    <FormControl aria-label="Text input with radio button" />
</InputGroup>

<InputGroup className="mb-3 checkbox" variant="light bordered">
    <InputGroup.Checkbox aria-label="Checkbox for following text input" />
    <FormControl aria-label="Text input with radio button" />
</InputGroup>`

const radio = `<InputGroup className="radio" variant="primary">
    <InputGroup.Radio aria-label="Radio button for following text input" />
    <FormControl aria-label="Text input with radio button" />
</InputGroup>

<InputGroup className="radio" variant="light">
    <InputGroup.Radio aria-label="Radio button for following text input" />
    <FormControl aria-label="Text input with radio button" />
</InputGroup>`

const radioBordered = `<InputGroup className="radio" variant="primary bordered">
    <InputGroup.Radio aria-label="Radio button for following text input" />
    <FormControl aria-label="Text input with radio button" />
</InputGroup>

<InputGroup className="radio" variant="light bordered">
    <InputGroup.Radio aria-label="Radio button for following text input" />
    <FormControl aria-label="Text input with radio button" />
</InputGroup>`

const checkboxNormal = `<Form>
    <div className="checkbox-wrapper">
        <Form.Check
            type="checkbox"
            variant="primary"
        />
    </div>
    <br/>
    <div className="checkbox-wrapper">
        <Form.Check
            disabled
            type="checkbox"
            variant="primary"
        />
    </div>
    <br/>
    <div className="checkbox-wrapper">
        <Form.Check
            type="checkbox"
            variant="light"
        />
    </div>
    <br/>
    <div className="checkbox-wrapper">
        <Form.Check
            disabled
            type="checkbox"
            variant="light"
        />
    </div>
</Form>`

const checkboxNormalBordered = `<Form>
<div className="checkbox-wrapper">
    <Form.Check
        type="checkbox"
        variant="primary bordered"
    />
</div>
<br/>
<div className="checkbox-wrapper">
    <Form.Check
        disabled
        type="checkbox"
        variant="primary bordered"
    />
</div>
<br/>
<div className="checkbox-wrapper">
    <Form.Check
        type="checkbox"
        variant="light bordered"
    />
</div>
<br/>
<div className="checkbox-wrapper">
    <Form.Check
        disabled
        type="checkbox"
        variant="light bordered"
    />
</div>
</Form>`

const radioNormal = `<div className="radio-wrapper">
    <Form.Check
        type="radio"
        variant="primary"
    />
    </div>

    <div className="radio-wrapper">
    <Form.Check
        disabled
        type="radio"
        variant="primary"
    />
    </div>

    <div className="radio-wrapper">
    <Form.Check
        type="radio"
        variant="light"
    />
    </div>

    <div className="radio-wrapper">
    <Form.Check
        disabled
        type="radio"
        variant="light"
    />
</div>`

const radioNormalBordered = `  <div className="radio-wrapper">
    <Form.Check
        type="radio"
        variant="primary bordered"
    />
    </div>

    <div className="radio-wrapper">
    <Form.Check
        disabled
        type="radio"
        variant="primary bordered"
    />
    </div>

    <div className="radio-wrapper">
    <Form.Check
        type="radio"
        variant="light bordered"
    />
    </div>

    <div className="radio-wrapper">
    <Form.Check
        disabled
        type="radio"
        variant="light bordered"
    />
</div>`

const floatingLabel = `<>
    <FloatingLabel
    controlId="floatingInput"
    label="Email address"
    className="mb-3"
    >
        <Form.Control type="email" placeholder="name@example.com" />
    </FloatingLabel>
    <FloatingLabel controlId="floatingPassword" label="Password">
        <Form.Control type="password" placeholder="Password" />
    </FloatingLabel>
</>`

const floatingLabel2 = `<>
    <FloatingLabel controlId="floatingTextarea" label="Comments" className="mb-3">
        <Form.Control as="textarea" placeholder="Leave a comment here" />
    </FloatingLabel>
    <FloatingLabel controlId="floatingTextarea2" label="Comments">
        <Form.Control
            as="textarea"
            placeholder="Leave a comment here"
            style={{ height: '100px' }}
        />
    </FloatingLabel>
</>`

const example2 = `<Form>
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
    <Form.Label>Email address</Form.Label>
    <Form.Control type="email" placeholder="name@example.com" />
    </Form.Group>
    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    <Form.Label>Example textarea</Form.Label>
    <Form.Control as="textarea" rows={3} />
    </Form.Group>
</Form>`

const sizing = `<>
    <Form.Control size="lg" type="text" placeholder="Large text" />
    <br />
    <Form.Control type="text" placeholder="Normal text" />
    <br />
    <Form.Control size="sm" type="text" placeholder="Small text" />
</>`

const disabled1 = `<>
    <Form.Control
    type="text"
    placeholder="Disabled input"
    aria-label="Disabled input example"
    disabled
    readOnly
    />
    <br />
    <Form.Control
    type="text"
    placeholder="Disabled readonly input"
    aria-label="Disabled input example"
    readOnly
    />
</>`

const plain = `<Form>
    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
    <Form.Label column sm="2">
        Email
    </Form.Label>
    <Col sm="10">
        <Form.Control plaintext readOnly defaultValue="email@example.com" />
    </Col>
    </Form.Group>

    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
    <Form.Label column sm="2">
        Password
    </Form.Label>
    <Col sm="10">
        <Form.Control type="password" placeholder="Password" />
    </Col>
    </Form.Group>
</Form>`

const fileInput1 = `<>
    <Form.Group controlId="formFile" className="mb-3">
    <Form.Label>Default file input example</Form.Label>
    <Form.Control type="file" />
    </Form.Group>
    <Form.Group controlId="formFileMultiple" className="mb-3">
    <Form.Label>Multiple files input example</Form.Label>
    <Form.Control type="file" multiple />
    </Form.Group>
    <Form.Group controlId="formFileDisabled" className="mb-3">
    <Form.Label>Disabled file input example</Form.Label>
    <Form.Control type="file" disabled />
    </Form.Group>
    <Form.Group controlId="formFileSm" className="mb-3">
    <Form.Label>Small file input example</Form.Label>
    <Form.Control type="file" size="sm" />
    </Form.Group>
    <Form.Group controlId="formFileLg" className="mb-3">
    <Form.Label>Large file input example</Form.Label>
    <Form.Control type="file" size="lg" />
    </Form.Group>
</>`

const color = `<>
    <Form.Label htmlFor="exampleColorInput">Color picker</Form.Label>
    <Form.Control
    type="color"
    id="exampleColorInput"
    defaultValue="#563d7c"
    title="Choose your color"
    />
</>`

const password1 = `<>
    <Form.Label htmlFor="inputPassword5">Password</Form.Label>
    <Form.Control
    type="password"
    id="inputPassword5"
    aria-describedby="passwordHelpBlock"
    />
    <Form.Text id="passwordHelpBlock" muted>
     Your password must be 8-20 characters long, contain letters and numbers, and
     must not contain spaces, special characters, or emoji.
    </Form.Text>
</>`

const customRend = `<>
    <Form.Floating className="mb-3">
    <Form.Control
        id="floatingInputCustom"
        type="email"
        placeholder="name@example.com"
    />
    <label htmlFor="floatingInputCustom">Email address</label>
    </Form.Floating>
    <Form.Floating>
    <Form.Control
        id="floatingPasswordCustom"
        type="password"
        placeholder="Password"
    />
    <label htmlFor="floatingPasswordCustom">Password</label>
    </Form.Floating>
</>`

const mailPass = `<Form>
    <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" />
    </Form.Group>
    <Form.Group className="mb-3" controlId="formGroupPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="Password" />
    </Form.Group>
</Form>`

const formgrid = `<Form>
    <Row>
    <Col>
        <Form.Control placeholder="First name" />
    </Col>
    <Col>
        <Form.Control placeholder="Last name" />
    </Col>
    </Row>
</Form>`

const formgrid2 = `<Form>
    <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="Enter email" />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" />
        </Form.Group>
    </Row>

    <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label>Address</Form.Label>
        <Form.Control placeholder="1234 Main St" />
    </Form.Group>

    <Form.Group className="mb-3" controlId="formGridAddress2">
        <Form.Label>Address 2</Form.Label>
        <Form.Control placeholder="Apartment, studio, or floor" />
    </Form.Group>

    <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>City</Form.Label>
            <Form.Control />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridZip">
            <Form.Label>Zip</Form.Label>
            <Form.Control />
        </Form.Group>
    </Row>

    <Form.Group className="mb-3" id="formGridCheckbox">
        <div className="checkbox-wrapper">
            <Form.Check type="checkbox" label="Check me out" />
        </div>
    </Form.Group>

    <Button variant="primary" type="submit">
        Submit
    </Button>
</Form>`

const horizontalForm = `<Form>
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
        <Form.Label column sm={2}>
            Email
        </Form.Label>
        <Col sm={10}>
            <Form.Control type="email" placeholder="Email" />
        </Col>
    </Form.Group>

    <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
        <Form.Label column sm={2}>
            Password
        </Form.Label>
        <Col sm={10}>
            <Form.Control type="password" placeholder="Password" />
        </Col>
    </Form.Group>
    <fieldset>
        <Form.Group as={Row} className="mb-3">
            <Form.Label as="legend" column sm={2}>
                Radios
            </Form.Label>
            <Col sm={10}>
                <div className="radio-wrapper">
                    <Form.Check
                        type="radio"
                        label="first radio"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                    />
                    <Form.Check
                        type="radio"
                        label="second radio"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios2"
                    />
                    <Form.Check
                        type="radio"
                        label="third radio"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios3"
                    />
                </div>
            </Col>
        </Form.Group>
    </fieldset>
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
        <Col sm={{ span: 10, offset: 2 }}>
            <div className="checkbox-wrapper">
                <Form.Check label="Remember me" />
            </div>
        </Col>
    </Form.Group>

    <Form.Group as={Row} className="mb-3">
        <Col sm={{ span: 10, offset: 2 }}>
            <Button type="submit">Sign in</Button>
        </Col>
    </Form.Group>
</Form>`

const horizFormLabel = `<>
    <Row>
        <Form.Label column="lg" lg={2}>
            Large Text
        </Form.Label>
        <Col>
            <Form.Control size="lg" type="text" placeholder="Large text" />
        </Col>
    </Row>
    <br />
    <Row>
        <Form.Label column lg={2}>
            Normal Text
        </Form.Label>
        <Col>
            <Form.Control type="text" placeholder="Normal text" />
        </Col>
        </Row>
        <br />
    <Row>
        <Form.Label column="sm" lg={2}>
            Small Text
        </Form.Label>
        <Col>
            <Form.Control size="sm" type="text" placeholder="Small text" />
        </Col>
    </Row>
</>`

const columnSizing = `<Form>
    <Row>
        <Col xs={7}>
            <Form.Control placeholder="City" />
        </Col>
        <Col>
            <Form.Control placeholder="State" />
        </Col>
        <Col>
            <Form.Control placeholder="Zip" />
        </Col>
    </Row>
</Form>`

const autoSizing = ` <Form>
    <Row className="align-items-center">
        <Col xs="auto">
            <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                Name
            </Form.Label>
            <Form.Control
                className="mb-2"
                id="inlineFormInput"
                placeholder="Jane Doe"
            />
        </Col>
        <Col xs="auto">
            <Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>
                Username
            </Form.Label>
            <InputGroup className="mb-2">
                <InputGroup.Text>@</InputGroup.Text>
                <FormControl id="inlineFormInputGroup" placeholder="Username" />
            </InputGroup>
        </Col>
        <Col xs="auto">
            <div className="checkbox-wrapper">
                <Form.Check
                    type="checkbox"
                    id="autoSizingCheck"
                    className="mb-2"
                    label="Remember me"
                />
            </div>
        </Col>
        <Col xs="auto">
            <Button type="submit" className="mb-2">
                Submit
            </Button>
        </Col>
    </Row>
</Form>
`

const autoSizing2 = `<Form>
    <Row className="align-items-center">
        <Col sm={3} className="my-1">
            <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                Name
            </Form.Label>
            <Form.Control id="inlineFormInputName" placeholder="Jane Doe" />
        </Col>
        <Col sm={3} className="my-1">
            <Form.Label htmlFor="inlineFormInputGroupUsername" visuallyHidden>
                Username
            </Form.Label>
            <InputGroup>
                <InputGroup.Text>@</InputGroup.Text>
                <FormControl id="inlineFormInputGroupUsername" placeholder="Username" />
            </InputGroup>
        </Col>
        <Col xs="auto" className="my-1">
            <div className="checkbox-wrapper">
                <Form.Check type="checkbox" id="autoSizingCheck2" label="Remember me" />
            </div>
        </Col>
        <Col xs="auto" className="my-1">
            <Button type="submit">Submit</Button>
        </Col>
    </Row>
</Form>`

const validate = `function FormExample() {
    const [validated, setValidated] = useState(false);
  
    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
  
      setValidated(true);
    };
  
    return (
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>First name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="First name"
              defaultValue="Mark"
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom02">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Last name"
              defaultValue="Otto"
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustomUsername">
            <Form.Label>Username</Form.Label>
            <InputGroup hasValidation>
              <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Username"
                aria-describedby="inputGroupPrepend"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please choose a username.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom03">
            <Form.Label>City</Form.Label>
            <Form.Control type="text" placeholder="City" required />
            <Form.Control.Feedback type="invalid">
              Please provide a valid city.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="validationCustom04">
            <Form.Label>State</Form.Label>
            <Form.Control type="text" placeholder="State" required />
            <Form.Control.Feedback type="invalid">
              Please provide a valid state.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="validationCustom05">
            <Form.Label>Zip</Form.Label>
            <Form.Control type="text" placeholder="Zip" required />
            <Form.Control.Feedback type="invalid">
              Please provide a valid zip.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Form.Group className="mb-3">
            <div className="checkbox-wrapper">
                <Form.Check
                    required
                    label="Agree to terms and conditions"
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                />
            </div>
        </Form.Group>
        <Button type="submit">Submit form</Button>
      </Form>
    );
  }
  
  render(<FormExample />);`

const formik = `const { Formik } = formik;

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  username: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zip: yup.string().required(),
  terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
});

function FormExample() {
  return (
    <Formik
      validationSchema={schema}
      onSubmit={console.log}
      initialValues={{
        firstName: 'Mark',
        lastName: 'Otto',
        username: '',
        city: '',
        state: '',
        zip: '',
        terms: false,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik01">
              <Form.Label>First name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                isValid={touched.firstName && !errors.firstName}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationFormik02">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                isValid={touched.lastName && !errors.lastName}
              />

              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationFormikUsername">
              <Form.Label>Username</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  aria-describedby="inputGroupPrepend"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  isInvalid={!!errors.username}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationFormik03">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                name="city"
                value={values.city}
                onChange={handleChange}
                isInvalid={!!errors.city}
              />

              <Form.Control.Feedback type="invalid">
                {errors.city}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationFormik04">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="State"
                name="state"
                value={values.state}
                onChange={handleChange}
                isInvalid={!!errors.state}
              />
              <Form.Control.Feedback type="invalid">
                {errors.state}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationFormik05">
              <Form.Label>Zip</Form.Label>
              <Form.Control
                type="text"
                placeholder="Zip"
                name="zip"
                value={values.zip}
                onChange={handleChange}
                isInvalid={!!errors.zip}
              />

              <Form.Control.Feedback type="invalid">
                {errors.zip}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group className="mb-3">
            <div className="checkbox-wrapper">
                <Form.Check
                required
                name="terms"
                label="Agree to terms and conditions"
                onChange={handleChange}
                isInvalid={!!errors.terms}
                feedback={errors.terms}
                feedbackType="invalid"
                id="validationFormik0"
                />
                </div>
          </Form.Group>
          <Button type="submit">Submit form</Button>
        </Form>
      )}
    </Formik>
  );
}

render(<FormExample />);`

const formik2 = `const { Formik } = formik;

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  username: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zip: yup.string().required(),
  file: yup.mixed().required(),
  terms: yup.bool().required().oneOf([true], 'terms must be accepted'),
});

function FormExample() {
  return (
    <Formik
      validationSchema={schema}
      onSubmit={console.log}
      initialValues={{
        firstName: 'Mark',
        lastName: 'Otto',
        username: '',
        city: '',
        state: '',
        zip: '',
        file: null,
        terms: false,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              md="4"
              controlId="validationFormik101"
              className="position-relative"
            >
              <Form.Label>First name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                isValid={touched.firstName && !errors.firstName}
              />
              <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              md="4"
              controlId="validationFormik102"
              className="position-relative"
            >
              <Form.Label>Last name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                isValid={touched.lastName && !errors.lastName}
              />

              <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationFormikUsername2">
              <Form.Label>Username</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  aria-describedby="inputGroupPrepend"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  isInvalid={!!errors.username}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.username}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik103"
              className="position-relative"
            >
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                name="city"
                value={values.city}
                onChange={handleChange}
                isInvalid={!!errors.city}
              />

              <Form.Control.Feedback type="invalid" tooltip>
                {errors.city}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              md="3"
              controlId="validationFormik104"
              className="position-relative"
            >
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="State"
                name="state"
                value={values.state}
                onChange={handleChange}
                isInvalid={!!errors.state}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.state}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              md="3"
              controlId="validationFormik105"
              className="position-relative"
            >
              <Form.Label>Zip</Form.Label>
              <Form.Control
                type="text"
                placeholder="Zip"
                name="zip"
                value={values.zip}
                onChange={handleChange}
                isInvalid={!!errors.zip}
              />

              <Form.Control.Feedback type="invalid" tooltip>
                {errors.zip}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group className="position-relative mb-3">
            <Form.Label>File</Form.Label>
            <Form.Control
              type="file"
              required
              name="file"
              onChange={handleChange}
              isInvalid={!!errors.file}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {errors.file}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="position-relative mb-3">
            <div className="checkbox-wrapper">
                <Form.Check
                required
                name="terms"
                label="Agree to terms and conditions"
                onChange={handleChange}
                isInvalid={!!errors.terms}
                feedback={errors.terms}
                feedbackType="invalid"
                id="validationFormik106"
                feedbackTooltip
                />
            </div>
          </Form.Group>
          <Button type="submit">Submit form</Button>
        </Form>
      )}
    </Formik>
  );
}

render(<FormExample />);`

const FormsView = () => {

    const wrapper = useRef(null);

    const [inputRangeValue, setInputRangeValue] = useState({ value: 10 });

    useEffect(() => {

    }, [])

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    const schema = yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        username: yup.string().required(),
        city: yup.string().required(),
        state: yup.string().required(),
        zip: yup.string().required(),
        terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
    });

    return (
        <>
            <h1 className="title">Forms</h1>

            <p>The <code>&lt;FormControl&gt;</code> component renders a form control with Bootstrap styling.
                The <code>&lt;FormGroup&gt;</code> component wraps a form control with proper spacing, along
                with support for a label, help text, and validation state. To ensure
                accessibility, set <code>controlId</code> on <code>&lt;FormGroup&gt;</code>, and use <code>&lt;FormLabel&gt;</code> for
                the label.</p>

            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                    <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <div className="checkbox-wrapper">
                        <Form.Check type="checkbox" label="Check me out" />
                    </div>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {formControl1}
            </ReactPrism>

            <p>The <code>&lt;FormControl&gt;</code> component directly renders the <code>&lt;input&gt;</code> or other specified
                component. If you need to access the value of an uncontrolled <code>&lt;FormControl&gt;</code>,
                attach a <code>ref</code> to it as you would with an uncontrolled input, then call
                <code>ReactDOM.findDOMNode(ref)</code> to get the DOM node. You can then interact with that
                node as you would with any other uncontrolled input.</p>

            <p>If your application contains a large number of form groups, we recommend
                building a higher-level component encapsulating a complete field group
                that renders the label, the control, and any other necessary components.
                We don't provide this out-of-the-box, because the composition of those
                field groups is too specific to an individual application to admit a
                good one-size-fits-all solution.</p>

            <h5 className="subtitle">Disabled forms</h5>

            <p>Add the <code>disabled</code> boolean attribute on an input to prevent user interactions and
                make it appear lighter.</p>

            <>
                <Form.Group className="mb-3">
                    <Form.Label>Disabled input</Form.Label>
                    <Form.Control placeholder="Disabled input" disabled />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Disabled select menu</Form.Label>
                    <Form.Select disabled>
                        <option>Disabled select</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <div className="checkbox-wrapper">
                        <Form.Check type="checkbox" label="Cant check this!" disabled />
                    </div>
                </Form.Group>
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {disabledForms}
            </ReactPrism>

            <p>Add the <code>disabled</code> attribute to a <code>&lt;fieldset&gt;</code> to disable all the controls within.</p>

            <Form>
                <fieldset disabled>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="disabledTextInput">Disabled input</Form.Label>
                        <Form.Control id="disabledTextInput" placeholder="Disabled input" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="disabledSelect">Disabled select menu</Form.Label>
                        <Form.Select id="disabledSelect">
                            <option>Disabled select</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <div className="checkbox-wrapper">
                            <Form.Check type="checkbox" label="Check me out" />
                        </div>
                    </Form.Group>
                    <Button type="submit">Submit</Button>
                </fieldset>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {disableFieldset}
            </ReactPrism>

            <h5 className="subtitle">Basic Input</h5>

            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="name@example.com" />
                </Form.Group>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {formControls}
            </ReactPrism>

            <h5 className="subtitle">Form Controls</h5>

            <p class="lead">Give textual form controls like <code>&lt;input&gt;</code>s and <code>&lt;textarea&gt;</code>s an upgrade with custom styles, sizing, focus states, and more.</p>

            <h5 className="subtitle">Example</h5>

            <p>For textual form controls—like <code>input</code>s and <code>textarea</code>s—use the <code>FormControl</code> component.
                FormControl adds some additional styles for general appearance, focus
                state, sizing, and more.</p>


            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="name@example.com" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Example textarea</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                </Form.Group>
            </Form>


            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {example2}
            </ReactPrism>

            <h5 className="subtitle">Sizing</h5>

            <p>Use <code>size</code> on <code>&lt;FormControl&gt;</code> to change the size of the input.</p>

            <>
                <Form.Control size="lg" type="text" placeholder="Large text" />
                <br />
                <Form.Control type="text" placeholder="Normal text" />
                <br />
                <Form.Control size="sm" type="text" placeholder="Small text" />
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {sizing}
            </ReactPrism>

            <h5 className="subtitle">Disabled</h5>

            <p>Add the <code>disabled</code> prop on an input to give it a grayed out appearance and remove pointer events.</p>

            <>
                <Form.Control
                    type="text"
                    placeholder="Disabled input"
                    aria-label="Disabled input example"
                    disabled
                    readOnly
                />
                <br />
                <Form.Control
                    type="text"
                    placeholder="Disabled readonly input"
                    aria-label="Disabled input example"
                    readOnly
                />
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {disabled1}
            </ReactPrism>

            <h5 className="subtitle">Read Only</h5>

            <p>
                Add the readOnly prop on an input to prevent modification of the input's value. Read-only inputs appear lighter
                (just like disabled inputs), but retain the standard cursor.
            </p>


            <Form.Control type="text" placeholder="Readonly input here..." readOnly />

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {readOnlyInput}
            </ReactPrism>

            <h5 className="subtitle">Readonly plain text</h5>

            <p>If you want to have readonly elements in your form styled as plain text,
                use the <code>plaintext</code> prop on FormControls to remove the
                default form field styling and preserve the correct margin and padding.</p>

            <Form>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="2">
                        Email
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control plaintext readOnly defaultValue="email@example.com" />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm="2">
                        Password
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control type="password" placeholder="Password" />
                    </Col>
                </Form.Group>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {plain}
            </ReactPrism>

            <h5 className="subtitle">File input</h5>

            <>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Default file input example</Form.Label>
                    <Form.Control type="file" />
                </Form.Group>
                <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Label>Multiple files input example</Form.Label>
                    <Form.Control type="file" multiple />
                </Form.Group>
                <Form.Group controlId="formFileDisabled" className="mb-3">
                    <Form.Label>Disabled file input example</Form.Label>
                    <Form.Control type="file" disabled />
                </Form.Group>
                <Form.Group controlId="formFileSm" className="mb-3">
                    <Form.Label>Small file input example</Form.Label>
                    <Form.Control type="file" size="sm" />
                </Form.Group>
                <Form.Group controlId="formFileLg" className="mb-3">
                    <Form.Label>Large file input example</Form.Label>
                    <Form.Control type="file" size="lg" />
                </Form.Group>
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {fileInput1}
            </ReactPrism>

            <h5 className="subtitle">Color</h5>

            <>
                <Form.Label htmlFor="exampleColorInput">Color picker</Form.Label>
                <Form.Control
                    type="color"
                    id="exampleColorInput"
                    defaultValue="#563d7c"
                    title="Choose your color"
                />
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {color}
            </ReactPrism>

            <h5 className="subtitle">Form text</h5>

            <p>Block-level help text in forms can be created using <code>&lt;Form.Text&gt;</code>. Inline help
                text can be flexibly implemented using any inline HTML element and utility classes
                like <code>.text-muted</code>.</p>

            <div><h5 class="Heading-styles-module--heading--3cEKx __heading"><span class="Anchor-styles-module--wrapper--2R_tQ">Associating help text with form controls<a href="#undefined" class="Anchor-styles-module--anchor--2zQH2" aria-hidden="true"><span aria-hidden="true">#</span></a></span></h5>Help text should be explicitly associated with the form control it relates to using the <code>aria-describedby</code> attribute. This will ensure that assistive technologies—such as screen readers—will announce this help text when the user focuses or enters the control.</div>

            <p>Form text below inputs can be styled with <code>&lt;Form.Text&gt;</code>. This component includes
                <code>display: block</code> and adds some top margin for easy spacing from the inputs above.</p>

            <>
                <Form.Label htmlFor="inputPassword5">Password</Form.Label>
                <Form.Control
                    type="password"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                />
                <Form.Text id="passwordHelpBlock" muted>
                    Your password must be 8-20 characters long, contain letters and numbers, and
                    must not contain spaces, special characters, or emoji.
                </Form.Text>
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {password1}
            </ReactPrism>

            <h5 className="subtitle">Basic Input Round</h5>

            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="name@example.com" className="rounded-pill" />
                </Form.Group>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {roundFormControls}
            </ReactPrism>

            <h5 className="subtitle">Select</h5>

            <p>
                Other than Form.Control, floating labels are only available on Form.Selects.
                They work in the same way, but unlike inputs,
                they’ll always show the label in its floated state.
                For use you have to import selectFunction from select.js file.
            </p>

            <SelectComponent type="single">
                <option value="1">Option 1</option>
                <option value="2">Option 2</option>
                <option value="3">Option 3</option>
                <option value="4">Option 4</option>
                <option value="5">Option 5</option>
            </SelectComponent>


            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {select}
            </ReactPrism>

            <h5 className="subtitle">Select sizes</h5>

            <SelectComponent type="single" size="lg">
                <option value="1">Option 1</option>
                <option value="2">Option 2</option>
                <option value="3">Option 3</option>
                <option value="4">Option 4</option>
                <option value="5">Option 5</option>
            </SelectComponent>
            <br />
            <SelectComponent type="single" >
                <option value="1">Option 1</option>
                <option value="2">Option 2</option>
                <option value="3">Option 3</option>
                <option value="4">Option 4</option>
                <option value="5">Option 5</option>
            </SelectComponent>
            <br />
            <SelectComponent type="single" size="sm">
                <option value="1">Option 1</option>
                <option value="2">Option 2</option>
                <option value="3">Option 3</option>
                <option value="4">Option 4</option>
                <option value="5">Option 5</option>
            </SelectComponent>


            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {selectSizes}
            </ReactPrism>

            <h5 className="subtitle">Select sizes rounded</h5>

            <SelectComponent type="single" rounded size="lg">
                <option value="1">Option 1</option>
                <option value="2">Option 2</option>
                <option value="3">Option 3</option>
                <option value="4">Option 4</option>
                <option value="5">Option 5</option>
            </SelectComponent>
            <br />
            <SelectComponent type="single" rounded>
                <option value="1">Option 1</option>
                <option value="2">Option 2</option>
                <option value="3">Option 3</option>
                <option value="4">Option 4</option>
                <option value="5">Option 5</option>
            </SelectComponent>
            <br />
            <SelectComponent type="single" rounded size="sm">
                <option value="1">Option 1</option>
                <option value="2">Option 2</option>
                <option value="3">Option 3</option>
                <option value="4">Option 4</option>
                <option value="5">Option 5</option>
            </SelectComponent>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {selectSizesRounded}
            </ReactPrism>

            <h5 className="subtitle">Multiple select</h5>
            <SelectComponent type="multiple">
                <option value="1">Option 1</option>
                <option value="2">Option 2</option>
                <option value="3">Option 3</option>
                <option value="4">Option 4</option>
                <option value="5">Option 5</option>
            </SelectComponent>


            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {exampleSelect}
            </ReactPrism>

            <h5 className="subtitle">Example textarea</h5>
            <p>
                By default, textareas will be the same height as inputs. To set a custom height
                on your textarea, do not use the rows attribute.
                Instead, set an explicit height (either inline or via custom CSS).
            </p>

            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Example textarea</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Your message here..." />
                </Form.Group>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {exampleTextArea}
            </ReactPrism>

            <h5 className="subtitle">File input</h5>

            <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Default file input example</Form.Label>
                <Form.Control type="file" />
            </Form.Group>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {fileInput}
            </ReactPrism>

            <h5 className="subtitle">File input rounded</h5>

            <Form.Group controlId="formFile" className="mb-3 rounded-pill">
                <Form.Label>Default file input example</Form.Label>
                <Form.Control type="file" />
            </Form.Group>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {fileInputRounded}
            </ReactPrism>

            <h5 className="subtitle">Checks and radios</h5>

            <p className="lead">Create consistent cross-browser and cross-device checkboxes and radios with our completely rewritten checks component.</p>
            <p>For the non-textual checkbox and radio controls, <code>FormCheck</code>
                provides a single component for both types that adds some additional
                styling and improved layout.</p>

            <h5 className="subtitle">Default checkbox</h5>

            <p>By default, any number of checkboxes and radios that are immediate
                sibling will be vertically stacked and appropriately spaced with
                FormCheck.</p>

            <Form>
                <div className="checkbox-wrapper">
                    <Form.Check
                        type="checkbox"
                        variant="primary"
                    />
                </div>
                <br />
                <div className="checkbox-wrapper">
                    <Form.Check
                        disabled
                        type="checkbox"
                        variant="primary"
                    />
                </div>
                <br />
                <div className="checkbox-wrapper">
                    <Form.Check
                        type="checkbox"
                        variant="light"
                    />
                </div>
                <br />
                <div className="checkbox-wrapper">
                    <Form.Check
                        disabled
                        type="checkbox"
                        variant="light"
                    />
                </div>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {checkboxNormal}
            </ReactPrism>

            <h5 className="subtitle">Bordered checkbox</h5>

            <Form>
                <div className="checkbox-wrapper">
                    <Form.Check
                        type="checkbox"
                        variant="primary bordered"
                    />
                </div>
                <br />
                <div className="checkbox-wrapper">
                    <Form.Check
                        disabled
                        type="checkbox"
                        variant="primary bordered"
                    />
                </div>
                <br />
                <div className="checkbox-wrapper">
                    <Form.Check
                        type="checkbox"
                        variant="light bordered"
                    />
                </div>
                <br />
                <div className="checkbox-wrapper">
                    <Form.Check
                        disabled
                        type="checkbox"
                        variant="light bordered"
                    />
                </div>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {checkboxNormalBordered}
            </ReactPrism>

            <h5 className="subtitle">Default Radio</h5>

            <div className="radio-wrapper">
                <Form.Check
                    type="radio"
                    variant="primary"
                />
            </div>
            <br />

            <div className="radio-wrapper">
                <Form.Check
                    disabled
                    type="radio"
                    variant="primary"
                />
            </div>
            <br />

            <div className="radio-wrapper">
                <Form.Check
                    type="radio"
                    variant="light"
                />
            </div>

            <br />

            <div className="radio-wrapper">
                <Form.Check
                    disabled
                    type="radio"
                    variant="light"
                />
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {radioNormal}
            </ReactPrism>

            <div className="radio-wrapper">
                <Form.Check
                    type="radio"
                    variant="primary bordered"
                />
            </div>

            <br />

            <div className="radio-wrapper">
                <Form.Check
                    disabled
                    type="radio"
                    variant="primary bordered"
                />
            </div>

            <br />

            <div className="radio-wrapper">
                <Form.Check
                    type="radio"
                    variant="light bordered"
                />
            </div>

            <br />

            <div className="radio-wrapper">
                <Form.Check
                    disabled
                    type="radio"
                    variant="light bordered"
                />
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {radioNormalBordered}
            </ReactPrism>

            <h5 className="subtitle">Checkbox Form Control</h5>

            <p>Use the <code>InputGroup.Radio</code> or
                <code>InputGroup.Checkbox</code> to add options to an input group.</p>

            <InputGroup className="mb-3 checkbox" variant="primary">
                <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                <FormControl aria-label="Text input with radio button" />
            </InputGroup>

            <InputGroup className="mb-3 checkbox" variant="light">
                <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                <FormControl aria-label="Text input with radio button" />
            </InputGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {checkboxStandard}
            </ReactPrism>

            <h5 className="subtitle">Checkbox with border - Form Control</h5>


            <InputGroup className="mb-3 checkbox" variant="primary bordered">
                <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                <FormControl aria-label="Text input with radio button" />
            </InputGroup>

            <InputGroup className="mb-3 checkbox" variant="light bordered">
                <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                <FormControl aria-label="Text input with radio button" />
            </InputGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {checkboxBordered}
            </ReactPrism>

            <h5 className="subtitle">Radio Form Control</h5>

            <InputGroup className="radio" variant="primary">
                <InputGroup.Radio aria-label="Radio button for following text input" />
                <FormControl aria-label="Text input with radio button" />
            </InputGroup>

            <br />

            <InputGroup className="radio" variant="light">
                <InputGroup.Radio aria-label="Radio button for following text input" />
                <FormControl aria-label="Text input with radio button" />
            </InputGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {radio}
            </ReactPrism>

            <h5 className="subtitle">Radio with border - Form Control</h5>

            <InputGroup className="radio" variant="primary bordered">
                <InputGroup.Radio aria-label="Radio button for following text input" />
                <FormControl aria-label="Text input with radio button" />
            </InputGroup>
            <br />
            <InputGroup className="radio" variant="light bordered">
                <InputGroup.Radio aria-label="Radio button for following text input" />
                <FormControl aria-label="Text input with radio button" />
            </InputGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {radioBordered}
            </ReactPrism>

            <h5 className="subtitle">Range input</h5>

            <p>For this usage you need to install react-input-range (npm install react-input-range). </p>
            <p>
                Create custom input type="range" controls with FormRange. The track (the background)
                and thumb (the value) are both styled to appear the same across browsers. As only Firefox supports
                “filling” their track from the left or right of the thumb as a means to visually indicate progress,
                we do not currently support it.
            </p>

            <div className="custom-input-range-wrapper">
                <div className="custom-input-range">
                    <InputRange
                        maxValue={20}
                        minValue={0}
                        value={inputRangeValue.value}
                        onChange={value => setInputRangeValue({ value })}
                    />
                </div>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {inputRange}
            </ReactPrism>

            <h5 className="subtitle">Search</h5>

            <div className="custom-search">
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><Image src={searchTool} /></InputGroup.Text>
                    <FormControl
                        placeholder="Search for..."
                        aria-label="search"
                        aria-describedby="basic-addon1"
                    />
                </InputGroup>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {search}
            </ReactPrism>

            <h5 className="subtitle">Search rounded</h5>

            <div className="custom-search rounded">
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><Image src={searchTool} /></InputGroup.Text>
                    <FormControl
                        placeholder="Search for..."
                        aria-label="search"
                        aria-describedby="basic-addon1"
                    />
                </InputGroup>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {searchRounded}
            </ReactPrism>

            <h5 className="subtitle">Floating label</h5>

            <p>Create beautifully simple form labels that float over your input fields.</p>

            <h5 className="subtitle">Examples</h5>

            <p>Wrap a <code>&lt;Form.Control&gt;</code> element in <code>&lt;FloatingLabel&gt;</code> to enable floating labels with
                Bootstrap’s textual form fields. A <code>placeholder</code> is required
                on each <code>&lt;Form.Control&gt;</code> as our method of CSS-only
                floating labels uses the <code>:placeholder-shown</code> pseudo-element.</p>

            <>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Email address"
                    className="mb-3"
                >
                    <Form.Control type="email" placeholder="name@example.com" />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label="Password">
                    <Form.Control type="password" placeholder="Password" />
                </FloatingLabel>
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {floatingLabel}
            </ReactPrism>

            <h5 className="subtitle">Text Areas</h5>

            <p>By default, <code>&lt;textarea&gt;</code>s will be the same height as <code>&lt;input&gt;</code>s. To set a custom
                height on your <code>&lt;textarea&gt;</code>, do not use the <code>rows</code> attribute. Instead, set an
                explicit <code>height</code> (either inline or via custom CSS).</p>

            <>
                <FloatingLabel controlId="floatingTextarea" label="Comments" className="mb-3">
                    <Form.Control as="textarea" placeholder="Leave a comment here" />
                </FloatingLabel>
                <FloatingLabel controlId="floatingTextarea2" label="Comments">
                    <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        style={{ height: '100px' }}
                    />
                </FloatingLabel>
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {floatingLabel2}
            </ReactPrism>

            <h5 className="subtitle">Customizing rendering</h5>

            <p>If you need greater control over the rendering, use the <code>&lt;FormFloating&gt;</code> component
                to wrap your input and label. Also note that the <code>&lt;Form.Control&gt;</code> must come first
                so we can utilize a sibling selector (e.g., ~).</p>

            <>
                <Form.Floating className="mb-3">
                    <Form.Control
                        id="floatingInputCustom"
                        type="email"
                        placeholder="name@example.com"
                    />
                    <label htmlFor="floatingInputCustom">Email address</label>
                </Form.Floating>
                <Form.Floating>
                    <Form.Control
                        id="floatingPasswordCustom"
                        type="password"
                        placeholder="Password"
                    />
                    <label htmlFor="floatingPasswordCustom">Password</label>
                </Form.Floating>
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {customRend}
            </ReactPrism>

            <h5 className="subtitle">Layout</h5>
            <p class="lead">Give your forms some structure—from inline to horizontal to custom grid implementations—with our form layout options.</p>
            <h5 className="subtitle">Forms</h5>

            <p>Every group of form fields should reside in a <code>&lt;Form&gt;</code> element. Bootstrap
                provides no default styling for the <code>&lt;Form&gt;</code> element, but there are some
                powerful browser features that are provided by default.</p>

            <ul><li>New to browser forms? Consider reviewing <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form">the MDN form docs</a>
                for an overview and complete list of available attributes.</li><li><code>&lt;button&gt;</code>s within a <code>&lt;Form&gt;</code> default to <code>type="submit"</code>, so strive to be
                    specific and always include a <code>type</code>.</li><li>You can disable every form element within a form with the <code>disabled</code> attribute
                        on the <code>&lt;Form&gt;</code>.</li></ul>

            <p>Since Bootstrap applies <code>display: block</code> and <code>width: 100%</code> to almost all our
                form controls, forms will by default stack vertically. Additional classes can
                be used to vary this layout on a per-form basis.</p>

            <h5 className="subtitle">Form groups</h5>

            <p>The <code>FormGroup</code> component is the easiest way to add some structure to forms.
                It provides a flexible container for grouping of labels, controls, optional
                help text, and form validation messaging. Use it with <code>fieldset</code>s, <code>div</code>s,
                or nearly any other element.</p>

            <p>You also add the <code>controlId</code> prop to accessibly wire the nested label and
                input together via the <code>id</code>.</p>

            <Form>
                <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" />
                </Form.Group>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {mailPass}
            </ReactPrism>

            <h5 className="subtitle">Form grid</h5>

            <p>More complex forms can be built using the grid components. Use these for
                form layouts that require multiple columns, varied widths, and additional
                alignment options.</p>

            <Form>
                <Row>
                    <Col>
                        <Form.Control placeholder="First name" />
                    </Col>
                    <Col>
                        <Form.Control placeholder="Last name" />
                    </Col>
                </Row>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {formgrid}
            </ReactPrism>

            <p>More complex layouts can also be created with the grid system.</p>

            <Form>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                </Row>

                <Form.Group className="mb-3" controlId="formGridAddress1">
                    <Form.Label>Address</Form.Label>
                    <Form.Control placeholder="1234 Main St" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridAddress2">
                    <Form.Label>Address 2</Form.Label>
                    <Form.Control placeholder="Apartment, studio, or floor" />
                </Form.Group>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridCity">
                        <Form.Label>City</Form.Label>
                        <Form.Control />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridZip">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control />
                    </Form.Group>
                </Row>

                <Form.Group className="mb-3" id="formGridCheckbox">
                    <div className="checkbox-wrapper">
                        <Form.Check type="checkbox" label="Check me out" />
                    </div>
                </Form.Group>

                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {formgrid2}
            </ReactPrism>

            <h5 className="subtitle">Horizontal form</h5>

            <p>Create horizontal forms with the grid by adding <code> Row</code> to form groups and
                using <code>Col</code> to specify the width of your labels and controls. Be sure to add
                the <code>column </code> prop to your <code>FormLabel</code>s as well so they’re vertically centered
                with their associated form controls.</p>

            <p>At times, you maybe need to use margin or padding utilities to create that
                perfect alignment you need. For example, we’ve removed the <code>padding-top</code> on our
                stacked radio inputs label to better align the text baseline.</p>

            <Form>
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={2}>
                        Email
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Control type="email" placeholder="Email" />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                    <Form.Label column sm={2}>
                        Password
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Control type="password" placeholder="Password" />
                    </Col>
                </Form.Group>
                <fieldset>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label as="legend" column sm={2}>
                            Radios
                        </Form.Label>
                        <Col sm={10}>
                            <div className="radio-wrapper">
                                <Form.Check
                                    type="radio"
                                    label="first radio"
                                    name="formHorizontalRadios"
                                    id="formHorizontalRadios1"
                                />
                                <Form.Check
                                    type="radio"
                                    label="second radio"
                                    name="formHorizontalRadios"
                                    id="formHorizontalRadios2"
                                />
                                <Form.Check
                                    type="radio"
                                    label="third radio"
                                    name="formHorizontalRadios"
                                    id="formHorizontalRadios3"
                                />
                            </div>
                        </Col>
                    </Form.Group>
                </fieldset>
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
                    <Col sm={{ span: 10, offset: 2 }}>
                        <div className="checkbox-wrapper">
                            <Form.Check label="Remember me" />
                        </div>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 10, offset: 2 }}>
                        <Button type="submit">Sign in</Button>
                    </Col>
                </Form.Group>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {horizontalForm}
            </ReactPrism>

            <h5 className="subtitle">Horizontal form label sizing</h5>

            <p>You can size the <code>&lt;FormLabel&gt;</code> using the <code>column</code> prop as shown.</p>

            <>
                <Row>
                    <Form.Label column="lg" lg={2}>
                        Large Text
                    </Form.Label>
                    <Col>
                        <Form.Control size="lg" type="text" placeholder="Large text" />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Form.Label column lg={2}>
                        Normal Text
                    </Form.Label>
                    <Col>
                        <Form.Control type="text" placeholder="Normal text" />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Form.Label column="sm" lg={2}>
                        Small Text
                    </Form.Label>
                    <Col>
                        <Form.Control size="sm" type="text" placeholder="Small text" />
                    </Col>
                </Row>
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {horizFormLabel}
            </ReactPrism>

            <h5 className="subtitle">Column sizing</h5>

            <p>As shown in the previous examples, our grid system allows you to place any number
                of <code>&lt;Col&gt;</code>s within a <code>&lt;Row&gt;</code>. They'll split the available width equally between
                them. You may also pick a subset of your columns to take up more or less space,
                while the remaining <code>&lt;Col&gt;</code>s equally split the rest, with specific column classes
                like <code>&lt;Col xs={7}&gt;</code>.</p>

            <Form>
                <Row>
                    <Col xs={7}>
                        <Form.Control placeholder="City" />
                    </Col>
                    <Col>
                        <Form.Control placeholder="State" />
                    </Col>
                    <Col>
                        <Form.Control placeholder="Zip" />
                    </Col>
                </Row>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {columnSizing}
            </ReactPrism>

            <h5 className="subtitle">Auto-sizing</h5>

            <p>The example below uses a flexbox utility to vertically center the contents and
                changes <code>&lt;Col&gt;</code> to <code>&lt;Col xs="auto"&gt;</code> so that your columns only take up as much
                space as needed. Put another way, the column sizes itself based on on the contents.</p>

            <Form>
                <Row className="align-items-center">
                    <Col xs="auto">
                        <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                            Name
                        </Form.Label>
                        <Form.Control
                            className="mb-2"
                            id="inlineFormInput"
                            placeholder="Jane Doe"
                        />
                    </Col>
                    <Col xs="auto">
                        <Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>
                            Username
                        </Form.Label>
                        <InputGroup className="mb-2">
                            <InputGroup.Text>@</InputGroup.Text>
                            <FormControl id="inlineFormInputGroup" placeholder="Username" />
                        </InputGroup>
                    </Col>
                    <Col xs="auto">
                        <div className="checkbox-wrapper">
                            <Form.Check
                                type="checkbox"
                                id="autoSizingCheck"
                                className="mb-2"
                                label="Remember me"
                            />
                        </div>
                    </Col>
                    <Col xs="auto">
                        <Button type="submit" className="mb-2">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {autoSizing}
            </ReactPrism>

            <p>You can then remix that once again with size-specific column classes.</p>

            <Form>
                <Row className="align-items-center">
                    <Col sm={3} className="my-1">
                        <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                            Name
                        </Form.Label>
                        <Form.Control id="inlineFormInputName" placeholder="Jane Doe" />
                    </Col>
                    <Col sm={3} className="my-1">
                        <Form.Label htmlFor="inlineFormInputGroupUsername" visuallyHidden>
                            Username
                        </Form.Label>
                        <InputGroup>
                            <InputGroup.Text>@</InputGroup.Text>
                            <FormControl id="inlineFormInputGroupUsername" placeholder="Username" />
                        </InputGroup>
                    </Col>
                    <Col xs="auto" className="my-1">
                        <div className="checkbox-wrapper">
                            <Form.Check type="checkbox" id="autoSizingCheck2" label="Remember me" />
                        </div>
                    </Col>
                    <Col xs="auto" className="my-1">
                        <Button type="submit">Submit</Button>
                    </Col>
                </Row>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {autoSizing2}
            </ReactPrism>

            <h5 className="subtitle">Validation</h5>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="First name"
                            defaultValue="Mark"
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Last name"
                            defaultValue="Otto"
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                        <Form.Label>Username</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Username"
                                aria-describedby="inputGroupPrepend"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please choose a username.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" placeholder="City" required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid city.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom04">
                        <Form.Label>State</Form.Label>
                        <Form.Control type="text" placeholder="State" required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid state.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom05">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control type="text" placeholder="Zip" required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid zip.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Form.Group className="mb-3">
                    <div className="checkbox-wrapper">
                        <Form.Check
                            required
                            label="Agree to terms and conditions"
                            feedback="You must agree before submitting."
                            feedbackType="invalid"
                        />
                    </div>
                </Form.Group>
                <Button type="submit">Submit form</Button>
            </Form>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {validate}
            </ReactPrism>

            <h5 className="subtitle">Form libraries and server-rendered styles</h5>

            <p>It's often beneficial (especially in React) to handle form validation
                via a library like Formik, or react-formal. In those cases, <code>isValid</code>
                and <code>isInvalid</code> props can be added to form controls to manually apply
                validation styles. Below is a quick example integrating with
                <a href="https://github.com/jaredpalmer/formik">Formik</a>.</p>

            <Formik
                validationSchema={schema}
                onSubmit={console.log}
                initialValues={{
                    firstName: 'Mark',
                    lastName: 'Otto',
                    username: '',
                    city: '',
                    state: '',
                    zip: '',
                    terms: false,
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="validationFormik01">
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    isValid={touched.firstName && !errors.firstName}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="validationFormik02">
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    isValid={touched.lastName && !errors.lastName}
                                />

                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="validationFormikUsername">
                                <Form.Label>Username</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        placeholder="Username"
                                        aria-describedby="inputGroupPrepend"
                                        name="username"
                                        value={values.username}
                                        onChange={handleChange}
                                        isInvalid={!!errors.username}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.username}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="validationFormik03">
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="City"
                                    name="city"
                                    value={values.city}
                                    onChange={handleChange}
                                    isInvalid={!!errors.city}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.city}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="validationFormik04">
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="State"
                                    name="state"
                                    value={values.state}
                                    onChange={handleChange}
                                    isInvalid={!!errors.state}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.state}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="validationFormik05">
                                <Form.Label>Zip</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Zip"
                                    name="zip"
                                    value={values.zip}
                                    onChange={handleChange}
                                    isInvalid={!!errors.zip}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.zip}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Form.Group className="mb-3">
                            <div className="checkbox-wrapper">
                                <Form.Check
                                    required
                                    name="terms"
                                    label="Agree to terms and conditions"
                                    onChange={handleChange}
                                    isInvalid={!!errors.terms}
                                    feedback={errors.terms}
                                    feedbackType="invalid"
                                    id="validationFormik0"
                                />
                            </div>
                        </Form.Group>
                        <Button type="submit">Submit form</Button>
                    </Form>
                )}
            </Formik>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {formik}
            </ReactPrism>

            <h5 className="subtitle">Tooltips</h5>

            <p>If your form layout allows it, you can use the <code>tooltip</code> prop to display
                validation feedback in a styled tooltip. Be sure to have a parent with
                <code>position: relative</code> on it for tooltip positioning. In the example below,
                our column classes have this already, but your project may require an
                alternative setup.</p>

            <Formik
                validationSchema={schema}
                onSubmit={console.log}
                initialValues={{
                    firstName: 'Mark',
                    lastName: 'Otto',
                    username: '',
                    city: '',
                    state: '',
                    zip: '',
                    file: null,
                    terms: false,
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationFormik101"
                                className="position-relative"
                            >
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    isValid={touched.firstName && !errors.firstName}
                                />
                                <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationFormik102"
                                className="position-relative"
                            >
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    isValid={touched.lastName && !errors.lastName}
                                />

                                <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="validationFormikUsername2">
                                <Form.Label>Username</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        placeholder="Username"
                                        aria-describedby="inputGroupPrepend"
                                        name="username"
                                        value={values.username}
                                        onChange={handleChange}
                                        isInvalid={!!errors.username}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {errors.username}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationFormik103"
                                className="position-relative"
                            >
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="City"
                                    name="city"
                                    value={values.city}
                                    onChange={handleChange}
                                    isInvalid={!!errors.city}
                                />

                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.city}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md="3"
                                controlId="validationFormik104"
                                className="position-relative"
                            >
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="State"
                                    name="state"
                                    value={values.state}
                                    onChange={handleChange}
                                    isInvalid={!!errors.state}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.state}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md="3"
                                controlId="validationFormik105"
                                className="position-relative"
                            >
                                <Form.Label>Zip</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Zip"
                                    name="zip"
                                    value={values.zip}
                                    onChange={handleChange}
                                    isInvalid={!!errors.zip}
                                />

                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.zip}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Form.Group className="position-relative mb-3">
                            <Form.Label>File</Form.Label>
                            <Form.Control
                                type="file"
                                required
                                name="file"
                                onChange={handleChange}
                                isInvalid={!!errors.file}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                                {errors.file}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="position-relative mb-3">
                            <div className="checkbox-wrapper">
                                <Form.Check
                                    required
                                    name="terms"
                                    label="Agree to terms and conditions"
                                    onChange={handleChange}
                                    isInvalid={!!errors.terms}
                                    feedback={errors.terms}
                                    feedbackType="invalid"
                                    id="validationFormik106"
                                    feedbackTooltip
                                />
                            </div>
                        </Form.Group>
                        <Button type="submit">Submit form</Button>
                    </Form>
                )}
            </Formik>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {formik2}
            </ReactPrism>




        </>
    )
}
export default FormsView;