import React from 'react'
import { Spinner, Button } from 'react-bootstrap';
import ReactPrism from '@versant-digital/react-prismjs';

const example = `<Spinner animation="border" role="status">
    <span className="visually-hidden">Loading...</span>
</Spinner>`

const border = `<Spinner animation="border" />`

const grow = `<Spinner animation="grow" />`

const standardVariants = `<>
    <Spinner animation="border" variant="primary" />
    <Spinner animation="border" variant="secondary" />
    <Spinner animation="border" variant="success" />
    <Spinner animation="border" variant="danger" />
    <Spinner animation="border" variant="warning" />
    <Spinner animation="border" variant="info" />
    <Spinner animation="border" variant="light" />
    <Spinner animation="border" variant="dark" />
</>`

const growVariants = `<>
    <Spinner animation="grow" variant="primary" />
    <Spinner animation="grow" variant="secondary" />
    <Spinner animation="grow" variant="success" />
    <Spinner animation="grow" variant="danger" />
    <Spinner animation="grow" variant="warning" />
    <Spinner animation="grow" variant="info" />
    <Spinner animation="grow" variant="light" />
    <Spinner animation="grow" variant="dark" />
</>`

const sizing = `<>
<Spinner animation="border" size="sm" />
<Spinner animation="border" />
<Spinner animation="grow" size="sm" />
<Spinner animation="grow" />
</>`

const buttons = `<>
<Button variant="primary rounded-pill" className="button-spinner">
    <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        style={{ marginRight: "0px" }}
    />
    <span className="visually-hidden">Loading...</span>
</Button>{' '}
<Button variant="primary rounded-pill">
    <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
    />
    Loading...
</Button>

</>{' '}
<>
<Button variant="primary rounded-pill">
    <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
    />
    <span className="visually-hidden">Loading...</span>
</Button>{' '}
<Button variant="primary rounded-pill">
    <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
        style={{ marginRight: "9px" }}
    />
    Loading...
</Button>
</>`

const acc = `<Spinner animation="border" role="status">
    <span className="visually-hidden">Loading...</span>
</Spinner>`

const SpinerView = () => {
    return (
        <>
            <h1 className="title-section">Spinners</h1>

            <p>Spinners can be used to show the loading state in your projects.</p>

            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {example}
            </ReactPrism>

            <h5 className="subtitle">Animations</h5>

            <p>Bootstrap offers two animation styles for spinners. The animation style
                can be configured with the <code>animation</code> property. An animation style
                must always be provided when creating a spinner.</p>

            <p><strong> Border Spinner - <code>border</code></strong></p>

            <Spinner animation="border" />

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {border}
            </ReactPrism>

            <p><strong> Grow Spinner - <code>grow</code> </strong></p>

            <Spinner animation="grow" />

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {grow}
            </ReactPrism>

            <h5 className="subtitle">Variants</h5>

            <p>All standard visual variants are available for both animation styles by
                setting the <code>variant</code> property. Alternatively spinners can be custom
                sized with the <code>style</code> property, or custom CSS classes.</p>

            <>
                <Spinner animation="border" variant="primary" />
                <Spinner animation="border" variant="secondary" />
                <Spinner animation="border" variant="success" />
                <Spinner animation="border" variant="danger" />
                <Spinner animation="border" variant="warning" />
                <Spinner animation="border" variant="info" />
                <Spinner animation="border" variant="light" />
                <Spinner animation="border" variant="dark" />
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {standardVariants}
            </ReactPrism>

            <>
                <Spinner animation="grow" variant="primary" />
                <Spinner animation="grow" variant="secondary" />
                <Spinner animation="grow" variant="success" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="info" />
                <Spinner animation="grow" variant="light" />
                <Spinner animation="grow" variant="dark" />
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {growVariants}
            </ReactPrism>

            <h5 className="subtitle">Sizing</h5>

            <p>In addition to the standard size, a smaller additional preconfigured
                size is available by configuring the <code>size</code> property to <code>sm</code>.</p>

            <>
                <Spinner animation="border" size="sm" />
                <Spinner animation="border" />
                <Spinner animation="grow" size="sm" />
                <Spinner animation="grow" />
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {sizing}
            </ReactPrism>

            <h5 className="subtitle">Buttons</h5>
            <p>Like the original Bootstrap spinners, these can also be used with
                buttons. To use this component out-of-the-box it is recommended you
                change the element type to <code>span</code> by configuring the <code>as</code> property when
                using spinners inside buttons.</p>

            <>
                <Button variant="primary rounded-pill" className="button-spinner">
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: "0px" }}
                    />
                    <span className="visually-hidden">Loading...</span>
                </Button>{' '}
                <Button variant="primary rounded-pill">
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    Loading...
                </Button>

            </>{' '}
            <>
                <Button variant="primary rounded-pill">
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="visually-hidden">Loading...</span>
                </Button>{' '}
                <Button variant="primary rounded-pill">
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: "9px" }}
                    />
                    Loading...
                </Button>
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {buttons}
            </ReactPrism>

            <h5 className="subtitle">Accessibility</h5>

            <p>To ensure the maximum accessibility for spinner components it is
                recommended you provide a relevant ARIA <code>role</code> property,
                and include screenreader-only readable text representation of the
                spinner's meaning inside the component using Bootstrap's <code>visually-hidden</code>
                class.</p>
            <p>The example below provides an example of accessible usage of this
                component.</p>

            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {acc}
            </ReactPrism>

        </>
    )
}
export default SpinerView