import React, { useState, useRef } from 'react';
import { Overlay, Tooltip, Button } from 'react-bootstrap';
import ReactPrism from '@versant-digital/react-prismjs';

const TooltipView = () => {

    const [showLeft, setShowLeft] = useState(false);
    const [showRight, setShowRight] = useState(false);
    const [showTop, setShowTop] = useState(false);
    const [showBottom, setShowBottom] = useState(false);

    const targetLeft = useRef(null);
    const targetRight = useRef(null);
    const targetTop = useRef(null);
    const targetBottom = useRef(null);

    const popRight = `const [showRight, setShowRight] = useState(false);
const targetRight = useRef(null);
return(
    <Button ref={targetRight} onClick={() => setShowRight(!showRight)}>
        Click me!
    </Button>
    <Overlay target={targetRight.current} show={showRight} placement="right">
    {(props) => (
        <Tooltip id="overlay-example" {...props}>
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus.
        </Tooltip>
    )}
    </Overlay>
)`
    const popLeft = `const [showLeft, setShowLeft] = useState(false);
const targetLeft = useRef(null);
return(
    <Button ref={targetLeft} onClick={() => setShowLeft(!showLeft)}>
        Click me!
    </Button>
    <Overlay target={targetLeft.current} show={showLeft} placement="left">
        {(props) => (
            <Tooltip id="overlay-example" {...props}>
                Vivamus sagittis lacus vel augue laoreet rutrum faucibus.
            </Tooltip>
        )}
    </Overlay>
)`
    const popTop = `const [showTop, setShowTop] = useState(false);
const targetTop = useRef(null);
return(
<Button ref={targetTop} onClick={() => setShowTop(!showTop)}>
    Click me!
</Button>
<Overlay target={targetTop.current} show={showTop} placement="top">
    {(props) => (
        <Tooltip id="overlay-example" {...props}>
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus.
        </Tooltip>
    )}
</Overlay>
)`

    const popBottom = `const [showBottom, setShowBottom] = useState(false);
const targetBottom = useRef(null);
return(
<Button ref={targetBottom} onClick={() => setShowBottom(!showBottom)}>
    Click me!
</Button>
<Overlay target={targetBottom.current} show={showBottom} placement="bottom">
    {(props) => (
        <Tooltip id="overlay-example" {...props}>
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus.
        </Tooltip>
    )}
</Overlay>
)`

    return (
        <>

            <h1 className="title-section">Tooltip - Popovers</h1>
            <p className="lead">A tooltip component for a more stylish alternative to that anchor tag <code>title</code> attribute.</p>
            <h5 className="subtitle">Examples</h5>
            <p>Hover over the links below to see tooltips.</p>

            <p>Tight pants next level keffiyeh <a href="#">you probably</a> haven't heard of them. Farm-to-table seitan, mcsweeney's fixie sustainable quinoa 8-bit american apparel <a href="#">have a</a> terry richardson vinyl chambray. Beard stumptown, cardigans banh mi lomo thundercats. Tofu biodiesel williamsburg marfa, four loko mcsweeney's cleanse vegan chambray. A really ironic artisan <a href="#">whatever keytar</a>, scenester farm-to-table banksy Austin <a href="#">twitter handle</a> freegan cred raw denim single-origin coffee viral.</p>
            <p>You can pass the <code>Overlay</code> injected props directly to the
                Tooltip component.</p>

            <Button ref={targetRight} onClick={() => setShowRight(!showRight)}>
                Click me!
            </Button>
            <Overlay target={targetRight.current} show={showRight} placement="right">
                {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                        Vivamus sagittis lacus vel augue laoreet rutrum faucibus.
                    </Tooltip>
                )}
            </Overlay>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {popRight}
            </ReactPrism>

            <Button ref={targetLeft} onClick={() => setShowLeft(!showLeft)}>
                Click me!
            </Button>
            <Overlay target={targetLeft.current} show={showLeft} placement="left">
                {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                        Vivamus sagittis lacus vel augue laoreet rutrum faucibus.
                    </Tooltip>
                )}
            </Overlay>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {popLeft}
            </ReactPrism>

            <Button ref={targetTop} onClick={() => setShowTop(!showTop)}>
                Click me!
            </Button>
            <Overlay target={targetTop.current} show={showTop} placement="top">
                {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                        Vivamus sagittis lacus vel augue laoreet rutrum faucibus.
                    </Tooltip>
                )}
            </Overlay>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {popTop}
            </ReactPrism>

            <Button ref={targetBottom} onClick={() => setShowBottom(!showBottom)}>
                Click me!
            </Button>
            <Overlay target={targetBottom.current} show={showBottom} placement="bottom">
                {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                        Vivamus sagittis lacus vel augue laoreet rutrum faucibus.
                    </Tooltip>
                )}
            </Overlay>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {popBottom}
            </ReactPrism>
        </>
    );
}

export default TooltipView;