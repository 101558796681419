import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import './scss/main.scss';
import NavMain from './components/Nav';
import Sidebar from './components/Sidebar';
import Tree from './components/Tree'
import WebPage from './views/WebPage'
import WebPage2 from './views/WebPage2'
import Helmet from 'react-helmet';


function App() {

  const getTitle = (location) => {
    const smallTitle = location.substring(1)
    return smallTitle.charAt(0).toUpperCase()+smallTitle.slice(1)
  }

  const location = useLocation();

  const [navState, setNavState] = useState(false);

  const renderFunction = () => {
    if (location.pathname === "/webpage") {
      return <WebPage />
    } else if (location.pathname === "/webpage2") {
      return <WebPage2 />
    } else {
      return (
        <>
          <NavMain toggleMenu={toggleMenu} />
          <Container className="main-content-container">
            <Sidebar navState={navState} />
            <Tree />
          </Container>
        </>
      )
    }
  }

  const toggleMenu = () => {
    setNavState(prev => !prev)
  }

  return (
    <div className="App">
      <Helmet>
        <title>{getTitle(location.pathname)}</title>
      </Helmet>
      <div className="main-content">

        {
          renderFunction()
        }

      </div>
    </div>
  );
}

export default App;
