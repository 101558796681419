import React from 'react'
import {Row, Col, Image, Pagination, Button, Card, Form} from 'react-bootstrap'
import shoppingCart from '../assets/shopping-cart.svg'
import cardImage from '../assets/card_computer.jpg'
import addItem from '../assets/add-item.svg'
import visa from '../assets/visa.svg'
import master from '../assets/master.svg'
import paypal from '../assets/paypal.svg'

const CatalogView = () => {
    return (
        <>
            <div className="catalog">

                <div className="catalog-top-row">
                    <div className="catalog-info">
                        <div className="catalog-top-logo">
                            LOGO
                        </div>
                        <div className="catalog-top-smalltext">
                            One Page<br />
                            Web Shop Template
                        </div>
                    </div>
                    <div className="catalog-cart">
                        <Image src={shoppingCart} /> Cart

                    </div>
                </div>

                <div className="catalog-heading">
                    Catalog
                </div>

                <div className="catalog-categories">
                    <ul>
                        <li>All</li>
                        <li>Free</li>
                        <li>Premium</li>
                        <li>Special</li>
                        <li>On Sale</li>
                    </ul>
                </div>

                <div className="catalog-products">
                    <Row>
                        <Col>
                            <Card style={{ width: '100%' }}>
                                <Card.Img variant="top" src={cardImage} />
                                <Card.Body>
                                    <div className="item-data">
                                        <Card.Title>Discount On Sports Equipment</Card.Title>
                                        <Card.Text>$20</Card.Text>
                                    </div>
                                    <div className="add-idem">
                                        <Image src={addItem} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ width: '100%' }}>
                                <Card.Img variant="top" src={cardImage} />
                                <Card.Body>
                                    <div className="item-data">
                                        <Card.Title>Discount On Sports Equipment</Card.Title>
                                        <Card.Text>$20</Card.Text>
                                    </div>
                                    <div className="add-idem">
                                        <Image src={addItem} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ width: '100%' }}>
                                <Card.Img variant="top" src={cardImage} />
                                <Card.Body>
                                    <div className="item-data">
                                        <Card.Title>Discount On Sports Equipment</Card.Title>
                                        <Card.Text>$20</Card.Text>
                                    </div>
                                    <div className="add-idem">
                                        <Image src={addItem} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card style={{ width: '100%' }}>
                                <Card.Img variant="top" src={cardImage} />
                                <Card.Body>
                                    <div className="item-data">
                                        <Card.Title>Discount On Sports Equipment</Card.Title>
                                        <Card.Text>$20</Card.Text>
                                    </div>
                                    <div className="add-idem">
                                        <Image src={addItem} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ width: '100%' }}>
                                <Card.Img variant="top" src={cardImage} />
                                <Card.Body>
                                    <div className="item-data">
                                        <Card.Title>Discount On Sports Equipment</Card.Title>
                                        <Card.Text>$20</Card.Text>
                                    </div>
                                    <div className="add-idem">
                                        <Image src={addItem} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ width: '100%' }}>
                                <Card.Img variant="top" src={cardImage} />
                                <Card.Body>
                                    <div className="item-data">
                                        <Card.Title>Discount On Sports Equipment</Card.Title>
                                        <Card.Text>$20</Card.Text>
                                    </div>
                                    <div className="add-idem">
                                        <Image src={addItem} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card style={{ width: '100%' }}>
                                <Card.Img variant="top" src={cardImage} />
                                <Card.Body>
                                    <div className="item-data">
                                        <Card.Title>Discount On Sports Equipment</Card.Title>
                                        <Card.Text>$20</Card.Text>
                                    </div>
                                    <div className="add-idem">
                                        <Image src={addItem} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ width: '100%' }}>
                                <Card.Img variant="top" src={cardImage} />
                                <Card.Body>
                                    <div className="item-data">
                                        <Card.Title>Discount On Sports Equipment</Card.Title>
                                        <Card.Text>$20</Card.Text>
                                    </div>
                                    <div className="add-idem">
                                        <Image src={addItem} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ width: '100%' }}>
                                <Card.Img variant="top" src={cardImage} />
                                <Card.Body>
                                    <div className="item-data">
                                        <Card.Title>Discount On Sports Equipment</Card.Title>
                                        <Card.Text>$20</Card.Text>
                                    </div>
                                    <div className="add-idem">
                                        <Image src={addItem} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>

                <div className="catalog-pagination">
                    <Pagination className="rounded-pill">
                        <Pagination.Prev className="prev-button" />
                        <Pagination.Item>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Item active>{3}</Pagination.Item>
                        <Pagination.Item>{4}</Pagination.Item>
                        <Pagination.Next className="next-button" />
                    </Pagination>
                </div>

                <div className="catalog-cart">
                    Cart
                </div>

                <div className="catalog-list">

                    <Row>

                        <Col md={8} className="items-column">

                            <Row className="product-price-row">
                                <Col md={9} xs={9}>Product</Col>
                                <Col md={3} xs={3}>Price</Col>
                            </Row>

                            <div className="item-rows">

                                <Row className="items-row">

                                    <Col md={9} xs={9} className="items-list">
                                        <div className="items-data">
                                            <Image src={cardImage} />
                                            All Week -30% On Breakfast
                                        </div>

                                    </Col>

                                    <Col md={3} xs={3} className="items-price">
                                        <div className="price">$40</div>
                                        <Button></Button>
                                    </Col>

                                </Row>

                                <Row className="items-row">
                                    <Col md={9} xs={9} className="items-list">
                                        <div className="items-data">
                                            <Image src={cardImage} />
                                            All Week -30% On Breakfast
                                        </div>

                                    </Col>

                                    <Col md={3} xs={3} className="items-price">
                                        <div className="price">$40</div>
                                        <Button></Button>
                                    </Col>

                                </Row>

                            </div>

                            <Row>
                                <div className="total">Total:</div>
                            </Row>

                            <div className="payment-sum">
                                <div className="payment-cards">
                                    <Image src={visa} />
                                    <Image src={master} />
                                    <Image src={paypal} />
                                </div>

                                <div className="payment-amount">
                                    $220.00
                                </div>
                            </div>

                        </Col>

                        <Col md={4} className="contact-colum">
                            <div className="contact-info">
                                <div className="contact-heading">Product</div>
                                <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control placeholder="John Doe"/>
                                        <Form.Control placeholder="name@example.com" />
                                        <Form.Control placeholder="+1 (809) 123-45-67" />
                                    </Form.Group>
                                </Form>

                                <Button variant="primary rounded-pill">COMPLETE YOUR ORDER</Button>

                            </div>
                        </Col>
                    </Row>

                </div>
                <p className="autor-mark">© 2022 trendy.solutions</p>
            </div>
        </>
    )
}

export default CatalogView;