import React from 'react';
import { Button } from 'react-bootstrap'
import ReactPrism from '@versant-digital/react-prismjs';

const jumbotron = `<div className="jumbotron">
    <h1 className="display-4">Hello, world!</h1>
    <p className="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
    <hr className="my-4" />
    <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
    <p className="lead">
        <Button variant="secondary">Learn More</Button>
    </p>
</div>`

const fluidJumbotron = `<div className="jumbotron jumbotron-fluid">
    <div className="container">
        <h1 className="display-4">Fluid jumbotron</h1>
        <p className="lead">This is a modified jumbotron that occupies the entire horizontal space of its parent.</p>
    </div>
</div>`

const colors = `<div className="jumbotron primary">
    <h1 className="display-4">Hello, world!</h1>
    <p className="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
    <hr className="my-4" />
    <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
</div>`

const JumbotronView = () => {
    return (
        <>

            <h1 className="title-section">Jumbotron</h1>
            <h5 className="subtitle">Example</h5>

            <p>A lightweight, flexible component that can optionally extend the entire viewport to showcase
                key marketing messages on your site.</p>

            <div className="jumbotron">
                <h1 className="display-4">Hello, world!</h1>
                <p className="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
                <hr className="my-4" />
                <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
                <p className="lead">
                    <Button variant="secondary">Learn More</Button>
                </p>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {jumbotron}
            </ReactPrism>

            <p>To make the jumbotron full width, and without rounded corners, add the <code className="highlighter-rouge">.jumbotron-fluid</code> modifier class and add a <code className="highlighter-rouge">.container</code> or <code className="highlighter-rouge">.container-fluid</code> within.</p>

            <h5 className="subtitle">Jumbotron fluid</h5>

            <div className="jumbotron jumbotron-fluid">
                <div className="container">
                    <h1 className="display-4">Fluid jumbotron</h1>
                    <p className="lead">This is a modified jumbotron that occupies the entire horizontal space of its parent.</p>
                </div>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {fluidJumbotron}
            </ReactPrism>

            <div className="jumbotron primary">
                <h1 className="display-4">Hello, world!</h1>
                <p className="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
                <hr className="my-4" />
                <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
            </div>

            <br />
            
            <p>Add color next to the jumbotron class name for different variant.</p>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {colors}
            </ReactPrism>
        </>
    );
}

export default JumbotronView;