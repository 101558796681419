import React from 'react'
import { Pagination } from 'react-bootstrap'
import ReactPrism from '@versant-digital/react-prismjs';

const normal = `
return(
    <Pagination>
    <Pagination.Prev className="prev" />
    <Pagination.Item>{1}</Pagination.Item>
    <Pagination.Ellipsis />
    <Pagination.Item>{10}</Pagination.Item>
    <Pagination.Item>{11}</Pagination.Item>
    <Pagination.Item active>{12}</Pagination.Item>
    <Pagination.Item>{13}</Pagination.Item>
    <Pagination.Item disabled>{14}</Pagination.Item>
    <Pagination.Ellipsis />
    <Pagination.Item>{20}</Pagination.Item>
    <Pagination.Next className="next" />
</Pagination>`

const normalRounded = `<Pagination className="rounded-pill">
    <Pagination.Prev className="prev" />
    <Pagination.Item>{1}</Pagination.Item>
    <Pagination.Ellipsis />
    <Pagination.Item>{10}</Pagination.Item>
    <Pagination.Item>{11}</Pagination.Item>
    <Pagination.Item active>{12}</Pagination.Item>
    <Pagination.Item>{13}</Pagination.Item>
    <Pagination.Item disabled>{14}</Pagination.Item>
    <Pagination.Ellipsis />
    <Pagination.Item>{20}</Pagination.Item>
    <Pagination.Next className="next" />
</Pagination>`

const normalArrow = `<Pagination className="rounded-pill">
    <Pagination.Prev className="prev-button" />
    <Pagination.Item>{1}</Pagination.Item>
    <Pagination.Ellipsis />
    <Pagination.Item>{10}</Pagination.Item>
    <Pagination.Item>{11}</Pagination.Item>
    <Pagination.Item active>{12}</Pagination.Item>
    <Pagination.Item>{13}</Pagination.Item>
    <Pagination.Item disabled>{14}</Pagination.Item>
    <Pagination.Ellipsis />
    <Pagination.Item>{20}</Pagination.Item>
    <Pagination.Next className="next-button" />
</Pagination>`

const roundedArrow = `<Pagination className="rounded-pill">
    <Pagination.Prev className="prev-button" />
    <Pagination.Item>{1}</Pagination.Item>
    <Pagination.Ellipsis />
    <Pagination.Item>{10}</Pagination.Item>
    <Pagination.Item>{11}</Pagination.Item>
    <Pagination.Item active>{12}</Pagination.Item>
    <Pagination.Item>{13}</Pagination.Item>
    <Pagination.Item disabled>{14}</Pagination.Item>
    <Pagination.Ellipsis />
    <Pagination.Item>{20}</Pagination.Item>
    <Pagination.Next className="next-button" />
</Pagination>`

const normalBig = `<Pagination size="lg">
    <Pagination.Prev className="prev" />
    <Pagination.Item>{1}</Pagination.Item>
    <Pagination.Item>{2}</Pagination.Item>
    <Pagination.Item>{3}</Pagination.Item>
    <Pagination.Item active>{4}</Pagination.Item>
    <Pagination.Item>{5}</Pagination.Item>
    <Pagination.Next className="next" />
</Pagination>`

const normalRoundedBig = `<Pagination className="rounded-pill" size="lg">
    <Pagination.Prev className="prev" />
    <Pagination.Item>{1}</Pagination.Item>
    <Pagination.Item>{2}</Pagination.Item>
    <Pagination.Item>{3}</Pagination.Item>
    <Pagination.Item active>{4}</Pagination.Item>
    <Pagination.Item>{5}</Pagination.Item>
    <Pagination.Next className="next" />
</Pagination>`

const normalArrowBig = `<Pagination size="lg">
    <Pagination.Prev className="prev-button" />
    <Pagination.Item>{1}</Pagination.Item>
    <Pagination.Item>{2}</Pagination.Item>
    <Pagination.Item>{3}</Pagination.Item>
    <Pagination.Item active>{4}</Pagination.Item>
    <Pagination.Item>{5}</Pagination.Item>
    <Pagination.Next className="next-button" />
</Pagination>`

const normalArrowRoundedBig = `<Pagination className="rounded-pill" size="lg">
    <Pagination.Prev className="prev-button" />
    <Pagination.Item>{1}</Pagination.Item>
    <Pagination.Item>{2}</Pagination.Item>
    <Pagination.Item>{3}</Pagination.Item>
    <Pagination.Item active>{4}</Pagination.Item>
    <Pagination.Item>{5}</Pagination.Item>
    <Pagination.Next className="next-button" />
</Pagination>`

const normalSmall = `<Pagination size="sm">
    <Pagination.Prev className="prev" />
    <Pagination.Item>{1}</Pagination.Item>
    <Pagination.Item>{2}</Pagination.Item>
    <Pagination.Item>{3}</Pagination.Item>
    <Pagination.Item active>{4}</Pagination.Item>
    <Pagination.Item>{5}</Pagination.Item>
    <Pagination.Next className="next" />
</Pagination>`

const normalRoundedSmall = `<Pagination className="rounded-pill" size="sm">
    <Pagination.Prev className="prev" />
    <Pagination.Item>{1}</Pagination.Item>
    <Pagination.Item>{2}</Pagination.Item>
    <Pagination.Item>{3}</Pagination.Item>
    <Pagination.Item active>{4}</Pagination.Item>
    <Pagination.Item>{5}</Pagination.Item>
    <Pagination.Next className="next" />
</Pagination>`

const smallText = `<Pagination size="sm">
    <Pagination.Prev className="prev-button" />
    <Pagination.Item>{1}</Pagination.Item>
    <Pagination.Item>{2}</Pagination.Item>
    <Pagination.Item>{3}</Pagination.Item>
    <Pagination.Item active>{4}</Pagination.Item>
    <Pagination.Item>{5}</Pagination.Item>
    <Pagination.Next className="next-button" />
</Pagination>`

const smallTextRounded = `<Pagination className="rounded-pill" size="sm">
    <Pagination.Prev className="prev-button" />
    <Pagination.Item>{1}</Pagination.Item>
    <Pagination.Item>{2}</Pagination.Item>
    <Pagination.Item>{3}</Pagination.Item>
    <Pagination.Item active>{4}</Pagination.Item>
    <Pagination.Item>{5}</Pagination.Item>
    <Pagination.Next className="next-button" />
</Pagination>`

const example = `let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>,
  );
}

const paginationBasic = (
  <div>
    <Pagination>{items}</Pagination>
    <br />

    <Pagination size="lg">{items}</Pagination>
    <br />

    <Pagination size="sm">{items}</Pagination>
  </div>
);

render(paginationBasic);`

const PaginationView = () => {

    let active = 2;
    let items = [];
    for (let number = 1; number <= 5; number++) {
        items.push(
            <Pagination.Item key={number} active={number === active}>
                {number}
            </Pagination.Item>,
        );
    }

    return (
        <>
            <h1 className="title-section">Pagination</h1>

            <p>A set of presentational components for building pagination UI.</p>

            <p>
                For building more complex pagination UI, there are few
                convenient sub-components for adding "First", "Previous", "Next",
                and "Last" buttons, as well as an
                Ellipsis item for indicating previous or continuing results.
            </p>

            <div>
                <Pagination>{items}</Pagination>
                <br />

                <Pagination size="lg">{items}</Pagination>
                <br />

                <Pagination size="sm">{items}</Pagination>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {example}
            </ReactPrism>

            <h5 className="subtitle">Normal</h5>

            <Pagination>
                <Pagination.Prev className="prev" />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{10}</Pagination.Item>
                <Pagination.Item>{11}</Pagination.Item>
                <Pagination.Item active>{12}</Pagination.Item>
                <Pagination.Item>{13}</Pagination.Item>
                <Pagination.Item disabled>{14}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{20}</Pagination.Item>
                <Pagination.Next className="next" />
            </Pagination>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {normal}
            </ReactPrism>

            <Pagination className="rounded-pill">
                <Pagination.Prev className="prev" />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{10}</Pagination.Item>
                <Pagination.Item>{11}</Pagination.Item>
                <Pagination.Item active>{12}</Pagination.Item>
                <Pagination.Item>{13}</Pagination.Item>
                <Pagination.Item disabled>{14}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{20}</Pagination.Item>
                <Pagination.Next className="next" />
            </Pagination>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {normalRounded}
            </ReactPrism>

            <Pagination>
                <Pagination.Prev className="prev-button" />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{10}</Pagination.Item>
                <Pagination.Item>{11}</Pagination.Item>
                <Pagination.Item active>{12}</Pagination.Item>
                <Pagination.Item>{13}</Pagination.Item>
                <Pagination.Item disabled>{14}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{20}</Pagination.Item>
                <Pagination.Next className="next-button" />
            </Pagination>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {normalArrow}
            </ReactPrism>

            <Pagination className="rounded-pill">
                <Pagination.Prev className="prev-button" />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{10}</Pagination.Item>
                <Pagination.Item>{11}</Pagination.Item>
                <Pagination.Item active>{12}</Pagination.Item>
                <Pagination.Item>{13}</Pagination.Item>
                <Pagination.Item disabled>{14}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{20}</Pagination.Item>
                <Pagination.Next className="next-button" />
            </Pagination>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {roundedArrow}
            </ReactPrism>

            <h5 className="subtitle">Big</h5>

            <Pagination size="lg">
                <Pagination.Prev className="prev" />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item active>{4}</Pagination.Item>
                <Pagination.Item>{5}</Pagination.Item>
                <Pagination.Next className="next" />
            </Pagination>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {normalBig}
            </ReactPrism>

            <Pagination className="rounded-pill" size="lg">
                <Pagination.Prev className="prev" />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item active>{4}</Pagination.Item>
                <Pagination.Item>{5}</Pagination.Item>
                <Pagination.Next className="next" />
            </Pagination>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {normalRoundedBig}
            </ReactPrism>

            <Pagination size="lg">
                <Pagination.Prev className="prev-button" />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item active>{4}</Pagination.Item>
                <Pagination.Item>{5}</Pagination.Item>
                <Pagination.Next className="next-button" />
            </Pagination>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {normalArrowBig}
            </ReactPrism>

            <Pagination className="rounded-pill" size="lg">
                <Pagination.Prev className="prev-button" />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item active>{4}</Pagination.Item>
                <Pagination.Item>{5}</Pagination.Item>
                <Pagination.Next className="next-button" />
            </Pagination>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {normalArrowRoundedBig}
            </ReactPrism>

            <h5 className="subtitle">Small</h5>

            <Pagination size="sm">
                <Pagination.Prev className="prev" />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item active>{4}</Pagination.Item>
                <Pagination.Item>{5}</Pagination.Item>
                <Pagination.Next className="next" />
            </Pagination>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {normalSmall}
            </ReactPrism>

            <Pagination className="rounded-pill" size="sm">
                <Pagination.Prev className="prev" />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item active>{4}</Pagination.Item>
                <Pagination.Item>{5}</Pagination.Item>
                <Pagination.Next className="next" />
            </Pagination>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {normalRoundedSmall}
            </ReactPrism>

            <Pagination size="sm">
                <Pagination.Prev className="prev-button" />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item active>{4}</Pagination.Item>
                <Pagination.Item>{5}</Pagination.Item>
                <Pagination.Next className="next-button" />
            </Pagination>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {smallText}
            </ReactPrism>

            <Pagination className="rounded-pill" size="sm">
                <Pagination.Prev className="prev-button" />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item active>{4}</Pagination.Item>
                <Pagination.Item>{5}</Pagination.Item>
                <Pagination.Next className="next-button" />
            </Pagination>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {smallTextRounded}
            </ReactPrism>
        </>
    );
}

export default PaginationView