import React, { useState, useEffect } from 'react'
import { Button, ToggleButtonGroup, ToggleButton, Image, CloseButton } from 'react-bootstrap';
import ReactPrism from '@versant-digital/react-prismjs';

import walet from '../assets/wallet.svg'
import waletArrow from '../assets/wallet-arrow.svg'
import arrowRight from '../assets/button-icon-arrow-right.svg'
import arrowRight2 from '../assets/button-icon-arrow-right2.svg'
import arrowLeft from '../assets/button-icon-arrow-left.svg'
import profileIcon from '../assets/profile-icon.svg'
import buttonNotification from '../assets/button-notification.svg'
import bellIcon from '../assets/bell-button-ico.svg'
import shoppingBasket from '../assets/shopping-basket-ico.svg'
import houseIco from '../assets/house-ico.svg'
import binIco from '../assets/bin-button-icon.svg'
import dotsIco from '../assets/dots-ico.svg'
import burgerIco from '../assets/button-burger.svg'
import burgerIco2 from '../assets/button-burger2.svg'
import arrows from '../assets/arrows-ico.svg'
import portret from '../assets/portret.png'
import airplane from '../assets/button-airplane.svg'
import fingerPrint from '../assets/fingerprint.svg'

const buttonNormal = `<Button variant="primary">Primary</Button>
<Button variant="secondary">Secondary</Button>
<Button variant="success">Success</Button>
<Button variant="danger">Danger</Button>
<Button variant="warning">Warning</Button>
<Button variant="info">Info</Button>
<Button variant="light">Light</Button>
<Button variant="dark">Dark</Button>`

const buttonRounded = `<Button variant="primary rounded-pill">Primary</Button>
<Button variant="secondary rounded-pill">Secondary</Button>
<Button variant="success rounded-pill">Success</Button>
<Button variant="danger rounded-pill">Danger</Button>
<Button variant="warning rounded-pill">Warning</Button>
<Button variant="info rounded-pill">Info</Button>
<Button variant="light rounded-pill">Light</Button>
<Button variant="dark rounded-pill">Dark</Button>`

const buttonOutline = `<Button outline variant="outline-primary">Primary</Button>
<Button variant="outline-secondary">Secondary</Button>
<Button variant="outline-success">Success</Button>
<Button variant="outline-danger">Danger</Button>
<Button variant="outline-warning">Warning</Button>
<Button variant="outline-info">Info</Button>
<Button variant="outline-light">Light</Button>
<Button variant="outline-dark">Dark</Button>`

const buttonOutlineRounded = `<Button variant="outline-primary rounded-pill">Primary</Button>
<Button variant="outline-secondary rounded-pill">Secondary</Button>
<Button variant="outline-success rounded-pill">Success</Button>
<Button variant="outline-danger rounded-pill">Danger</Button>
<Button variant="outline-warning rounded-pill">Warning</Button>
<Button variant="outline-info rounded-pill">Info</Button>
<Button variant="outline-light rounded-pill">Light</Button>
<Button variant="outline-dark rounded-pill">Dark</Button>`

const progressButton = `<Button  variant="primary rounded-pill">
   <span className="spinner-border spinner-border-sm"></span>
   Primary
</Button>
<Button  variant="secondary rounded-pill">
   <span className="spinner-border spinner-border-sm"></span>
   Secondary
</Button>
<Button  variant="success rounded-pill">
   <span className="spinner-border spinner-border-sm"></span>
   Success
</Button>
<Button  variant="danger rounded-pill">
   <span className="spinner-border spinner-border-sm"></span>
   Danger
</Button>
<Button  variant="warning rounded-pill">
   <span className="spinner-border spinner-border-sm"></span>
   Warning
</Button>
<Button  variant="info rounded-pill">
   <span className="spinner-border spinner-border-sm"></span>
   Info
</Button>
<Button  variant="light rounded-pill">
   <span className="spinner-border spinner-border-sm"></span>
   Light
</Button>
<Button  variant="dark rounded-pill">
   <span className="spinner-border spinner-border-sm"></span>
   Dark
</Button>`

const buttonLarge = `<Button size="xl" variant="primary">Primary</Button>
<Button size="xl" variant="secondary">Secondary</Button>
<Button size="xl" variant="success">Success</Button>
<Button size="xl" variant="danger">Danger</Button>
<Button size="xl" variant="warning">Warning</Button>
<Button size="xl" variant="info">Info</Button>
<Button size="xl" variant="light">Light</Button>
<Button size="xl" variant="dark">Dark</Button>`

const buttonOutlineLarge = `<Button size="xl" variant="outline-primary">Primary</Button>
<Button size="xl" variant="outline-secondary">Secondary</Button>
<Button size="xl" variant="outline-success">Success</Button>
<Button size="xl" variant="outline-danger">Danger</Button>
<Button size="xl" variant="outline-warning">Warning</Button>
<Button size="xl" variant="outline-info">Info</Button>
<Button size="xl" variant="outline-light">Light</Button>
<Button size="xl" variant="outline-dark">Dark</Button>`

const buttonSmall = `<Button size="sm" variant="primary">Primary</Button>
<Button size="sm" variant="secondary">Secondary</Button>
<Button size="sm" variant="success">Success</Button>
<Button size="sm" variant="danger">Danger</Button>
<Button size="sm" variant="warning">Warning</Button>
<Button size="sm" variant="info">Info</Button>
<Button size="sm" variant="light">Light</Button>
<Button size="sm" variant="dark">Dark</Button>`

const buttonSmallOutline = `<Button size="sm" variant="outline-primary">Primary</Button>
<Button size="sm" variant="outline-secondary">Secondary</Button>
<Button size="sm" variant="outline-success">Success</Button>
<Button size="sm" variant="outline-danger">Danger</Button>
<Button size="sm" variant="outline-warning">Warning</Button>
<Button size="sm" variant="outline-info">Info</Button>
<Button size="sm" variant="outline-light">Light</Button>
<Button size="sm" variant="outline-dark">Dark</Button>`

const buttonExtraSmall = `<Button size="xs" variant="primary">Primary</Button>
<Button size="xs" variant="secondary">Secondary</Button>
<Button size="xs" variant="success">Success</Button>
<Button size="xs" variant="danger">Danger</Button>
<Button size="xs" variant="warning">Warning</Button>
<Button size="xs" variant="info">Info</Button>
<Button size="xs" variant="light">Light</Button>
<Button size="xs" variant="dark">Dark</Button>`

const buttonExtraSmallOutline = `<Button size="xs" variant="outline-primary">Primary</Button>
<Button size="xs" variant="outline-secondary">Secondary</Button>
<Button size="xs" variant="outline-success">Success</Button>
<Button size="xs" variant="outline-danger">Danger</Button>
<Button size="xs" variant="outline-warning">Warning</Button>
<Button size="xs" variant="outline-info">Info</Button>
<Button size="xs" variant="outline-light">Light</Button>
<Button size="xs" variant="outline-dark">Dark</Button>`

const blockLevelButton = `<Button variant="primary" size="lg" >Block level button</Button>
<Button variant="secondary" size="lg">Block level button</Button>`

const activeState = `<Button variant="primary" size="lg" active >Primary link</Button>
<Button variant="secondary" size="lg" active >Link</Button>`

const disabledState = `<Button variant="primary" size="lg" disabled >Primary link</Button>
<Button variant="secondary" size="lg" disabled >Link</Button>`

const buttonTags = `<Button href="#">Link</Button> 
<Button type="submit">Button</Button>{' '}
<Button as="input" type="button" value="Input" />{' '}
<Button as="input" type="submit" value="Submit" />{' '}
<Button as="input" type="reset" value="Reset" />`

const loadingButton = `function simulateNetworkRequest() {
   return new Promise((resolve) => setTimeout(resolve, 2000));
}

const [isLoading, setLoading] = useState(false);

useEffect(() => {
   if (isLoading) {
      simulateNetworkRequest().then(() => {
         setLoading(false);
      });
   }
}, [isLoading]);

const handleClick = () => setLoading(true);

return(
   <Button
      variant="primary"
      disabled={isLoading}
      onClick={!isLoading ? handleClick : null}
   >
      {isLoading ? 'Loading…' : 'Click to load'}
   </Button>
)`

const uncontrolled = `<ToggleButtonGroup type="checkbox" defaultValue={[1, 3]} className="mb-2">
  <ToggleButton id="tbg-check-1" value={1}>
    Checkbox 1 (pre-checked)
  </ToggleButton>
  <ToggleButton id="tbg-check-2" value={2}>
    Checkbox 2
  </ToggleButton>
  <ToggleButton id="tbg-check-3" value={3}>
    Checkbox 3 (pre-checked)
  </ToggleButton>
</ToggleButtonGroup>
<br />
<ToggleButtonGroup type="radio" name="options" defaultValue={1}>
  <ToggleButton id="tbg-radio-1" value={1}>
    Radio 1 (pre-checked)
  </ToggleButton>
  <ToggleButton id="tbg-radio-2" value={2}>
    Radio 2
  </ToggleButton>
  <ToggleButton id="tbg-radio-3" value={3}>
    Radio 3
  </ToggleButton>
</ToggleButtonGroup>`

const controlled = `const [value, setValue] = useState([1, 3]);

/*
 * The second argument that will be passed to
 * handleChange from ToggleButtonGroup
 * is the SyntheticEvent object, but we are
 * not using it in this example so we will omit it.
 */
const handleChange = (val) => setValue(val);
return(
   <ToggleButtonGroup type="checkbox" value={value} onChange={handleChange}>
   <ToggleButton id="tbg-btn-1" value={1}>
     Option 1
   </ToggleButton>
   <ToggleButton id="tbg-btn-2" value={2}>
     Option 2
   </ToggleButton>
   <ToggleButton id="tbg-btn-3" value={3}>
     Option 3
   </ToggleButton>
 </ToggleButtonGroup>
)`

const closeButton = `<CloseButton />`

const disableClose = `<CloseButton disabled />`

const closeVariants = `<div className="bg-dark p-3">
   <CloseButton variant="white" />
   <CloseButton variant="white" disabled />
</div>`

const accessClose = `<CloseButton aria-label="Hide" />`

const ButtonView = () => {

   function simulateNetworkRequest() {
      return new Promise((resolve) => setTimeout(resolve, 2000));
   }

   const [isLoading, setLoading] = useState(false);

   const handleClick = () => setLoading(true);

   const [value, setValue] = useState([1, 3]);

   /*
    * The second argument that will be passed to
    * `handleChange` from `ToggleButtonGroup`
    * is the SyntheticEvent object, but we are
    * not using it in this example so we will omit it.
    */

   const handleChange = (val) => setValue(val);

   useEffect(() => {
      if (isLoading) {
         simulateNetworkRequest().then(() => {
            setLoading(false);
         });
      }
   }, [isLoading]);

   return (
      <>

         <h1 className="title-section">Buttons</h1>
         <p>Custom button styles for actions in forms, dialogs, and more with support for multiple sizes, states, and more.</p>

         <h5 className="subtitle">Examples</h5>
         <p>Use any of the available button style types to quickly create a styled
            button. Just modify the <code>variant</code> prop.</p>

         <div className="row-custom">
            <Button variant="primary">Primary</Button>
            <Button variant="secondary">Secondary</Button>
            <Button variant="success">Success</Button>
            <Button variant="danger">Danger</Button>
            <Button variant="warning">Warning</Button>
            <Button variant="info">Info</Button>
            <Button variant="light">Light</Button>
            <Button variant="dark">Dark</Button>
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {buttonNormal}
         </ReactPrism>


         <h5 className="subtitle">Buttons rounded</h5>

         <div className="row-custom">
            <Button variant="primary rounded-pill">Primary</Button>
            <Button variant="secondary rounded-pill">Secondary</Button>
            <Button variant="success rounded-pill">Success</Button>
            <Button variant="danger rounded-pill">Danger</Button>
            <Button variant="warning rounded-pill">Warning</Button>
            <Button variant="info rounded-pill">Info</Button>
            <Button variant="light rounded-pill">Light</Button>
            <Button variant="dark rounded-pill">Dark</Button>
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {buttonRounded}
         </ReactPrism>

         <h5 className="subtitle">Outline Buttons</h5>
         <p>For a lighter touch, Buttons also come in <code>outline-*</code>
            variants with no background color.</p>

         <div className="row-custom">
            <Button variant="outline-primary">Primary</Button>
            <Button variant="outline-secondary">Secondary</Button>
            <Button variant="outline-success">Success</Button>
            <Button variant="outline-danger">Danger</Button>
            <Button variant="outline-warning">Warning</Button>
            <Button variant="outline-info">Info</Button>
            <Button variant="outline-light">Light</Button>
            <Button variant="outline-dark">Dark</Button>
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {buttonOutline}
         </ReactPrism>

         <h5 className="subtitle">Outline Rounded Buttons</h5>

         <div className="row-custom">
            <Button variant="outline-primary rounded-pill">Primary</Button>
            <Button variant="outline-secondary rounded-pill">Secondary</Button>
            <Button variant="outline-success rounded-pill">Success</Button>
            <Button variant="outline-danger rounded-pill">Danger</Button>
            <Button variant="outline-warning rounded-pill">Warning</Button>
            <Button variant="outline-info rounded-pill">Info</Button>
            <Button variant="outline-light rounded-pill">Light</Button>
            <Button variant="outline-dark rounded-pill">Dark</Button>
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {buttonOutlineRounded}
         </ReactPrism>

         <h5 className="subtitle">Button tags</h5>

         <p>Normally <code>&lt;Button&gt;</code> components will render a HTML
            <code>&lt;button&gt;</code> element. However you can render whatever you'd
            like, adding a <code>href</code> prop will automatically render an
            <code>&lt;a /&gt;</code> element. You can use the <code>as</code> prop to
            render whatever your heart desires. React Bootstrap will take care of
            the proper ARIA roles for you.</p>

         <div className="row-custom">
            <Button href="#">Link</Button> <Button type="submit">Button</Button>{' '}
            <Button as="input" type="button" value="Input" />{' '}
            <Button as="input" type="submit" value="Submit" />{' '}
            <Button as="input" type="reset" value="Reset" />
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {buttonTags}
         </ReactPrism>

         <h5 className="subtitle">Sizes</h5>

         <p>Fancy larger or smaller buttons? Add <code>size="lg"</code>,
            <code>size="sm"</code> for additional sizes.</p>

         <h5 className="subtitle">Large</h5>
         <div className="row-custom">
            <Button size="xl" variant="primary">Primary</Button>
            <Button size="xl" variant="secondary">Secondary</Button>
            <Button size="xl" variant="success">Success</Button>
            <Button size="xl" variant="danger">Danger</Button>
            <Button size="xl" variant="warning">Warning</Button>
            <Button size="xl" variant="info">Info</Button>
            <Button size="xl" variant="light">Light</Button>
            <Button size="xl" variant="dark">Dark</Button>
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {buttonLarge}
         </ReactPrism>

         <h5 className="subtitle">Outline Large</h5>

         <div className="row-custom">
            <Button size="xl" variant="outline-primary">Primary</Button>
            <Button size="xl" variant="outline-secondary">Secondary</Button>
            <Button size="xl" variant="outline-success">Success</Button>
            <Button size="xl" variant="outline-danger">Danger</Button>
            <Button size="xl" variant="outline-warning">Warning</Button>
            <Button size="xl" variant="outline-info">Info</Button>
            <Button size="xl" variant="outline-light">Light</Button>
            <Button size="xl" variant="outline-dark">Dark</Button>
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {buttonOutlineLarge}
         </ReactPrism>

         <h5 className="subtitle">Small</h5>

         <div className="row-custom">
            <Button size="sm" variant="primary">Primary</Button>
            <Button size="sm" variant="secondary">Secondary</Button>
            <Button size="sm" variant="success">Success</Button>
            <Button size="sm" variant="danger">Danger</Button>
            <Button size="sm" variant="warning">Warning</Button>
            <Button size="sm" variant="info">Info</Button>
            <Button size="sm" variant="light">Light</Button>
            <Button size="sm" variant="dark">Dark</Button>
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {buttonSmall}
         </ReactPrism>

         <h5 className="subtitle">Small Outline</h5>
         <div className="row-custom">
            <Button size="sm" variant="outline-primary">Primary</Button>
            <Button size="sm" variant="outline-secondary">Secondary</Button>
            <Button size="sm" variant="outline-success">Success</Button>
            <Button size="sm" variant="outline-danger">Danger</Button>
            <Button size="sm" variant="outline-warning">Warning</Button>
            <Button size="sm" variant="outline-info">Info</Button>
            <Button size="sm" variant="outline-light">Light</Button>
            <Button size="sm" variant="outline-dark">Dark</Button>
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {buttonSmallOutline}
         </ReactPrism>

         <h5 className="subtitle">Extra small</h5>

         <div className="row-custom">
            <Button size="xs" variant="primary">Primary</Button>
            <Button size="xs" variant="secondary">Secondary</Button>
            <Button size="xs" variant="success">Success</Button>
            <Button size="xs" variant="danger">Danger</Button>
            <Button size="xs" variant="warning">Warning</Button>
            <Button size="xs" variant="info">Info</Button>
            <Button size="xs" variant="light">Light</Button>
            <Button size="xs" variant="dark">Dark</Button>
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {buttonExtraSmall}
         </ReactPrism>

         <h5 className="subtitle">Extra small Outline</h5>

         <div className="row-custom">
            <Button size="xs" variant="outline-primary">Primary</Button>
            <Button size="xs" variant="outline-secondary">Secondary</Button>
            <Button size="xs" variant="outline-success">Success</Button>
            <Button size="xs" variant="outline-danger">Danger</Button>
            <Button size="xs" variant="outline-warning">Warning</Button>
            <Button size="xs" variant="outline-info">Info</Button>
            <Button size="xs" variant="outline-light">Light</Button>
            <Button size="xs" variant="outline-dark">Dark</Button>
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {buttonExtraSmallOutline}
         </ReactPrism>

         <h5 className="subtitle">Block Level Button</h5>
         <p>Create responsive stacks of full-width, “block buttons” like those in Bootstrap 4 with a mix of our display and gap utilities.</p>

         <div className="row-custom-column">
            <Button variant="primary" size="lg">Block level button</Button>
            <Button variant="secondary" size="lg">Block level button</Button>
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {blockLevelButton}
         </ReactPrism>

         <h5 className="subtitle">Active state</h5>
         <p>To set a button's active state simply set the component's
            <code> active</code> prop.</p>

         <div className="row-custom">
            <Button variant="primary" size="lg" active >Primary link</Button>
            <Button variant="secondary" size="lg" active >Link</Button>
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {activeState}
         </ReactPrism>

         <h5 className="subtitle">Disabled state</h5>
         <p>Make buttons look inactive by adding the <code> disabled</code> prop to.</p>

         <div className="row-custom">
            <Button variant="primary" size="lg" disabled >Primary link</Button>
            <Button variant="secondary" size="lg" disabled >Link</Button>
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {disabledState}
         </ReactPrism>

         <p>Watch out! <code>&lt;a&gt;</code> elements don't naturally support a
            <code> disabled</code> attribute. In browsers that support it this is handled with a
            <code> point-events: none</code> style but not all browsers support it
            yet.</p>

         <p>React Bootstrap will prevent any <code>onClick</code> handlers from
            firing regardless of the rendered element.</p>

         <h5 className="subtitle">Button loading state</h5>

         <p>When activating an asynchronous action from a button it is a good UX
            pattern to give the user feedback as to the loading state, this can
            easily be done by updating your <code>&lt;Button /&gt;</code>s
            props from a state change like below.</p>

         <Button
            variant="primary loading-button"
            disabled={isLoading}
            onClick={!isLoading ? handleClick : null}
         >
            {isLoading ? 'Loading…' : 'Click to load'}
         </Button>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {loadingButton}
         </ReactPrism>

         <h5 className="subtitle">Uncontrolled</h5>

         <ToggleButtonGroup type="checkbox" defaultValue={[1, 3]} className="mb-2">
            <ToggleButton id="tbg-check-1" value={1}>
               Checkbox 1
            </ToggleButton>
            <ToggleButton id="tbg-check-2" value={2}>
               Checkbox 2
            </ToggleButton>
            <ToggleButton id="tbg-check-3" value={3}>
               Checkbox 3
            </ToggleButton>
         </ToggleButtonGroup>
         <br />
         <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
            <ToggleButton id="tbg-radio-1" value={1}>
               Radio 1
            </ToggleButton>
            <ToggleButton id="tbg-radio-2" value={2}>
               Radio 2
            </ToggleButton>
            <ToggleButton id="tbg-radio-3" value={3}>
               Radio 3
            </ToggleButton>
         </ToggleButtonGroup>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {uncontrolled}
         </ReactPrism>

         <h5 className="subtitle">Controlled</h5>

         <ToggleButtonGroup type="checkbox" value={value} onChange={handleChange}>
            <ToggleButton id="tbg-btn-1" value={1}>
               Option 1
            </ToggleButton>
            <ToggleButton id="tbg-btn-2" value={2}>
               Option 2
            </ToggleButton>
            <ToggleButton id="tbg-btn-3" value={3}>
               Option 3
            </ToggleButton>
         </ToggleButtonGroup>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {controlled}
         </ReactPrism>

         <h5 className="subtitle">Progress button</h5>

         <div className="row-custom">
            <Button variant="primary rounded-pill">
               <span className="spinner-border spinner-border-sm"></span>
               Primary
            </Button>
            <Button variant="secondary rounded-pill">
               <span className="spinner-border spinner-border-sm"></span>
               Secondary
            </Button>
            <Button variant="success rounded-pill">
               <span className="spinner-border spinner-border-sm"></span>
               Success
            </Button>
            <Button variant="danger rounded-pill">
               <span className="spinner-border spinner-border-sm"></span>
               Danger
            </Button>
            <Button variant="warning rounded-pill">
               <span className="spinner-border spinner-border-sm"></span>
               Warning
            </Button>
            <Button variant="info rounded-pill">
               <span className="spinner-border spinner-border-sm"></span>
               Info
            </Button>
         </div>
         <div className="row-custom">
            <Button variant="light rounded-pill">
               <span className="spinner-border spinner-border-sm"></span>
               Light
            </Button>
            <Button variant="dark rounded-pill">
               <span className="spinner-border spinner-border-sm"></span>
               Dark
            </Button>
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {progressButton}
         </ReactPrism>

         <h5 className="subtitle">Close button</h5>

         <p>A generic close button for dismissing content such as modals and alerts.</p>

         <CloseButton />

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {closeButton}
         </ReactPrism>

         <h5 className="subtitle">Disabled state</h5>

         <p>Bootstrap adds relevant styling to a disabled close button to prevent user
            interactions.</p>

         <CloseButton disabled />

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {disableClose}
         </ReactPrism>

         <h5 className="subtitle">Close variants</h5>

         <p>Change the default dark color to white using <code>variant="white"</code>.</p>

         <div className="bg-dark p-3">
            <CloseButton variant="white" />
            <CloseButton variant="white" disabled />
         </div>

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {closeVariants}
         </ReactPrism>

         <h5 className="subtitle">Accessibility</h5>

         <p>To ensure the maximum accessibility for Close Button components, it is
            recommended that you provide relevant text for screen readers.  The example
            below provides an example of accessible usage of this component by way of the
            <code>aria-label</code> property.</p>

         <CloseButton aria-label="Hide" />

         <ReactPrism
            language="html"
            plugins={["line-numbers"]}>
            {accessClose}
         </ReactPrism>

         <h5 className="subtitle">Icon button</h5> {/* INLINE STYLING FOR THIS BUTTONS */}

         <div className="row-custom">

            <Button variant="light">
               <Image src={walet} style={{ marginRight: '10px' }} />Your wallet<Image src={waletArrow} style={{ marginLeft: '10px' }} />
            </Button>

            <Button variant="light rounded-pill">
               <Image src={walet} style={{ marginRight: '10px' }} />Your wallet<Image src={waletArrow} style={{ marginLeft: '10px' }} />
            </Button>

         </div>

         <div className="row-custom" style={{ display: 'flex', flexDirection: 'row' }}>

            <Button variant="light" style={{ display: 'flex', alignItems: 'center' }}>
               <Image src={arrowRight} style={{ marginRight: '10px' }} />SIGN IN
            </Button>

            <Button variant="light rounded-pill" style={{ display: 'flex', alignItems: 'center' }}>
               <Image src={arrowRight} style={{ marginRight: '10px' }} />SIGN IN
            </Button>

         </div>

         <div className="row-custom button-arrows">

            <Button variant="light" style={{}}>
               <Image src={arrowLeft} />
            </Button>

            <Button variant="light" style={{}}>
               <Image src={arrowRight2} />
            </Button>

            <Button variant="light" style={{ height: '50px', width: '50px', borderRadius: '50%' }}>
               <Image src={arrowLeft} />
            </Button>

            <Button variant="light" style={{ height: '50px', width: '50px', borderRadius: '50%' }}>
               <Image src={arrowRight2} />
            </Button>
         </div>

         <div className="row-custom" style={{ display: 'flex', flexDirection: 'row' }}>

            <Button variant="light" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px', width: '50px', borderRadius: '15px', marginRight: '20px' }}>
               <Image src={profileIcon} />
               <Image src={buttonNotification} style={{ position: 'absolute', top: '-8px', right: '-8px' }} />
            </Button>

            <Button variant="light" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px', width: '50px', borderRadius: '15px' }}>
               <Image src={bellIcon} />
               <Image src={buttonNotification} style={{ position: 'absolute', top: '-8px', right: '38px' }} />
            </Button>

            <Button variant="light" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px', width: '50px', borderRadius: '15px', marginRight: '20px' }}>
               <Image src={shoppingBasket} />
               <Image src={buttonNotification} style={{ position: 'absolute', top: '-8px', right: '-8px' }} />
            </Button>

         </div>

         <div className="row-custom" style={{ display: 'flex', flexDirection: 'row' }}>

            <Button variant="light" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px', width: '50px', borderRadius: '15px' }}>
               <Image src={houseIco} />
            </Button>

            <Button variant="light" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px', width: '50px', borderRadius: '15px', marginRight: '20px' }}>
               <Image src={binIco} />
            </Button>

         </div>

         <div className="row-custom" style={{ display: 'flex', flexDirection: 'row' }}>

            <Button variant="light" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px', width: '50px', borderRadius: '15px' }}>
               <Image src={dotsIco} />
            </Button>

            <Button variant="light" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px', width: '50px', borderRadius: '15px' }}>
               <Image src={burgerIco} />
            </Button>

            <Button variant="light" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px', width: '50px', borderRadius: '15px' }}>
               <Image src={burgerIco2} />
            </Button>

            <Button variant="light" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px', width: '50px', borderRadius: '15px' }}>
               <Image src={arrows} />
            </Button>

         </div>

         <div className="row-custom">
            <Button variant="light" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '64px', width: '64px', borderRadius: '50%' }}>
               <Image src={portret} />
               <div style={{ width: '8px', height: '8px', borderRadius: '50%', background: '#5561B3', position: 'absolute', top: '2px', right: '2px' }}></div>
            </Button>
         </div>

         <div className="row-custome button-extras">

            <Button>
               <Image src={airplane} style={{ marginRight: '20px' }} />
               <div>AIRPLANE MODE <br />INACTIVE</div>
            </Button>
            <br />
            <Button>
               <Image src={fingerPrint} style={{ marginRight: '20px' }} />
               <div>CONFIRM WITH<br /> TOUCH ID</div>
            </Button>
         </div>

      </>
   )
}

export default ButtonView


