import React from 'react'
import { Row, Col, Navbar, Nav, Image, Button, Card, ListGroup, Form} from 'react-bootstrap'
import webpage2Img from '../assets/webpage2-img.svg'
import featuresImg from '../assets/features-img.png'
import featuresImg2 from '../assets/features2-img.png'

const WebPage2 = () => {
    return (
        <>
            <div className="container-xxl webpage2-wrapper">

                <div className="webpage2-nav">

                    <Navbar bg="light" expand="lg">
                        
                            <Navbar.Brand href="#home">trendy.</Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto">
                                    <Nav.Link href="/">Home</Nav.Link>
                                    <Nav.Link href="#1">Features</Nav.Link>
                                    <Nav.Link href="#2">Review</Nav.Link>
                                    <Nav.Link href="#3">Price</Nav.Link>
                                    <Nav.Link href="#4">Blog</Nav.Link>
                                    <Nav.Link href="#5">Free Trial</Nav.Link>
                                    <Nav.Link href="#6">Join in</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        
                    </Navbar>

                </div>

                <div className="webpage2-heading">
                    SaasKit Creative UI Saas<br />
                    Design & Coding
                </div>

                <div className="webpage2-buttons">
                    <Button variant="primary rounded-pill">Get Free Trial</Button>
                    <Button variant="light rounded-pill">See More Info</Button>
                </div>

                <div className="webpage2-image">
                    <Image src={webpage2Img} />
                </div>

                <div className="webpage2-cards">

                    <Row>
                        <Col md={4}>
                            <Card style={{ width: '100%' }}>
                                <Card.Body>
                                    <Card.Title>Fast and Optimized</Card.Title>
                                    <Card.Text>
                                        Donec semper euismod nisi quis<br />
                                        feugiat. Nullam finibus metus eget<br />
                                        orci volutpat porta.
                                    </Card.Text>
                                    <Button variant="primary rounded-pill">See more</Button>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card style={{ width: '100%' }}>
                                <Card.Body>
                                    <Card.Title>Marketing Strategist</Card.Title>
                                    <Card.Text>
                                        Donec semper euismod nisi quis<br />
                                        feugiat. Nullam finibus metus eget<br />
                                        orci volutpat porta.
                                    </Card.Text>
                                    <Button variant="primary rounded-pill">See more</Button>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card style={{ width: '100%' }}>
                                <Card.Body>
                                    <Card.Title>Easy Integration</Card.Title>
                                    <Card.Text>
                                        Donec semper euismod nisi quis<br />
                                        feugiat. Nullam finibus metus eget<br />
                                        orci volutpat porta.
                                    </Card.Text>
                                    <Button variant="primary rounded-pill">See more</Button>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>


                </div>

                <div className="webpage2-heading" style={{ marginBottom: "5%" }}>
                    Best Features Ever
                </div>

                <div className="webpage2-features" style={{ marginBottom: "13.4%" }}>
                    <Row>
                        <Col lg={6}>
                            <div className="features-text">
                                <div className="features-minitext">
                                    Information about agency
                                </div>
                                <div className="features-heading">
                                    Best preformance
                                </div>
                                <div className="features-normaltext">
                                    Donec semper euismod nisi quis feugiat. Nullam finibus<br />
                                    metus eget orci volutpat porta. Morbi quis arcu vulputate,<br />
                                    dignissim mi ac, varius magna.<br />
                                    <br />
                                    Curabitur mollis bibendum luctus.
                                </div>
                                <Button variant="primary rounded-pill">Learn More</Button>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="features-img">
                                <Image src={featuresImg} />
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="webpage2-features">
                    <Row>
                        <Col lg={6}>
                            <div className="features-img">
                                <Image src={featuresImg2} style={{ marginBottom: "40px" }} />
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="features-text">
                                <div className="features-minitext">
                                    Information about agency
                                </div>
                                <div className="features-heading">
                                    Best preformance
                                </div>
                                <div className="features-normaltext">
                                    Donec semper euismod nisi quis feugiat. Nullam finibus<br />
                                    metus eget orci volutpat porta. Morbi quis arcu vulputate,<br />
                                    dignissim mi ac, varius magna.<br />
                                    <br />
                                    Curabitur mollis bibendum luctus.
                                </div>
                                <Button variant="primary rounded-pill">Learn More</Button>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="webpage2-price">
                    <div className="webpage2-heading">
                        Software Pricing
                    </div>

                    <div className="pricing-text">
                        Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed suscipit.<br />
                        Vestibulum auctor nunc vitae diam eleifend, in maximus metus sollicitudin.
                    </div>

                    <div className="webpage2-pricing">
                        <Row>
                            <Col lg={4}>
                                <Card className="card-pricing h-100">
                                    <div className="card-body">
                                        <div className="text-center p-3">
                                            <h5 className="card-title">BASIC PLAN</h5>

                                            <span className="h2">$</span><span className="price">19</span>
                                            <p>/MONTH</p>
                                        </div>
                                    </div>

                                    <ListGroup className="list-group-flush">
                                        <ListGroup.Item className="list-group-item">Unlimited activity</ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Direct messaging</ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Members<span>10 members</span></ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Admins<span>No access</span></ListGroup.Item>
                                    </ListGroup>
                                    <Button variant="primary btn-lg rounded-pill">Start with Basic</Button>
                                </Card>
                            </Col>

                            <Col lg={4}>
                                <Card className="card-pricing h-100">
                                    <div className="card-body">
                                        <div className="text-center p-3">
                                            <h5 className="card-title">BASIC PLAN</h5>

                                            <span className="h2">$</span><span className="price">29</span>
                                            <p>/MONTH</p>
                                        </div>
                                    </div>

                                    <ListGroup className="list-group-flush">
                                        <ListGroup.Item className="list-group-item">Unlimited activity</ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Direct messaging</ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Members<span>50 members</span></ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Admins<span>5 admins</span></ListGroup.Item>
                                    </ListGroup>
                                    <Button variant="primary btn-lg rounded-pill" >Start with Standard</Button>
                                </Card>
                            </Col>

                            <Col lg={4}>
                                <Card className="card-pricing h-100">
                                    <div className="card-body">
                                        <div className="text-center p-3">
                                            <h5 className="card-title">BASIC PLAN</h5>

                                            <span className="h2">$</span><span className="price">99</span>
                                            <p>/MONTH</p>
                                        </div>
                                    </div>

                                    <ListGroup className="list-group-flush">
                                        <ListGroup.Item className="list-group-item">Unlimited activity</ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Direct messaging</ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Members<span>Unlimited</span></ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Admins<span>Unlimited</span></ListGroup.Item>
                                    </ListGroup>
                                    <Button variant="primary btn-lg rounded-pill" >Start with Enterprice</Button>
                                </Card>
                            </Col>
                        </Row>
                    </div>

                </div>

                <div className="webpage2-heading">
                    Start Your Free Trial
                </div>

                <div className="wepage2-trialtext">
                    Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed suscipit.<br />
                    Vestibulum auctor nunc vitae diam eleifend, in maximus metus sollicitudin.
                </div>

                <div className="webpage2-contact">
                    <div className="contact-header">
                        Get In Touch With Us To See How Our Company Can Help You
                    </div>

                    <div className="webpage2-form">

                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Name"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom02">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Email"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Phone"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom02">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Subject"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" rows={3} />
                                </Form.Group>
                            </Form>
                        </Row>

                        <Button variant="primary">Send Message</Button>

                    </div>
                </div>

            </div>
        </>
    )
}

export default WebPage2