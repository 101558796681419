import React from 'react'
import { Row, Col, Navbar, Nav, Container, Image, Button, Carousel, Card, ListGroup, Form} from 'react-bootstrap'
import glasses from '../assets/glasses.png'
import coffe from '../assets/coffe.png'
import flower from '../assets/flower.png'
import lapTop from '../assets/lap-top.png'
import carouselImg from '../assets/webpage-carousel.png'
import wepageBall from '../assets/webpage-ball.svg'
import wepagePen from '../assets/webpage-pen.svg'
import wepageSearch from '../assets/webpage-search.svg'

const WebPage = () => {
    return (
        <>
            <Image src={flower} id="flower" />
            <Image src={coffe} id="cofee" />

            <div className="container-xxl webpage-wrapper">

                <div className="webpage-navbar">
                    <Navbar collapseOnSelect expand="lg" bg="light" variant="dark">
                        <Container>
                            <Navbar.Brand href="#home">trendy.</Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="me-auto">
                                    <Nav.Link href="/">components</Nav.Link>
                                    <Nav.Link href="#pricing">who we are</Nav.Link>
                                    <Nav.Link href="#pricing">our work</Nav.Link>
                                    <Nav.Link href="#pricing">pricing</Nav.Link>
                                    <Nav.Link href="#pricing">blog</Nav.Link>
                                    <Nav.Link href="#pricing">contact</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>

                <div className="webpage-banner">

                    <div className="banner-center">
                        <Image src={glasses} id="glasses" />
                        <Image src={lapTop} id="lap-top" />
                    </div>

                    <div className="banner-text">
                        <h1 className="main-text">Creative Design</h1>
                        <div className="minor-text">Trendy is a Design Studio
                            <br /> was founded in London, United Kingdom.</div>
                        <Button variant="primary rounded-pill">Learn More</Button>
                    </div>
                </div>

                <div className="webpage-about">
                    <Row>

                        <Col className="webpage-carousel" lg={6}>
                            <div className="carousel-wrapper">

                                <div className="indicators-bottom-wrapper">
                                    <Carousel className="indicators-bottom">
                                        <Carousel.Item>
                                            <Image
                                                className="d-block w-100"
                                                src={carouselImg}
                                                alt="First slide"
                                            />
                                            <Carousel.Caption className="carousel-caption-top" >
                                                <h3>First slide label</h3>
                                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <Image
                                                className="d-block w-100"
                                                src={carouselImg}
                                                alt="Second slide"
                                            />

                                            <Carousel.Caption className="carousel-caption-top">
                                                <h3>Second slide label</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <Image
                                                className="d-block w-100"
                                                src={carouselImg}
                                                alt="Third slide"
                                            />

                                            <Carousel.Caption className="carousel-caption-top">
                                                <h3>Third slide label</h3>
                                                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                            </div>
                        </Col>


                        <Col className="webpage-about-text" lg={6}>

                            <div className="wepage-about-small">
                                Information about Agency
                            </div>
                            <div className="wepage-about-header">
                                Why We're <br />Ordinary
                            </div>

                            <div className="webpage-about-minor-text">
                                Donec semper euismod nisi quis feugiat. Nullam finibus
                                metus eget orci volutpat porta. Morbi quis arcu vulputate,
                                dignissim mi ac, varius magna.<br /><br />
                                Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed
                                suscipit. Vestibulum auctor nunc vitae diam eleifend, in
                                maximus metus sollicitudin.
                            </div>

                            <Button variant="primary rounded-pill">Learn More</Button>
                        </Col>
                    </Row>
                </div>

                <div className="webpage-cards">
                    <div className="webpage-cards-text">
                        <div className="webpage-cards-header">
                            What We Offer
                        </div>
                        <div className="webpage-cards-minor">
                            Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed suscipit.<br />
                            Vestibulum auctor nunc vitae diam eleifend, in maximus metus sollicitudin.
                        </div>
                    </div>

                    <div className="webpage-cards-items">

                        <Row>
                            <Col lg={4}>
                                <Card style={{ width: '100%' }}>
                                    <Card.Body>
                                        <Card.Title>Website Design</Card.Title>
                                        <Card.Subtitle>Website Design</Card.Subtitle>
                                        <Card.Text>
                                            Donec semper euismod nisi quis
                                            feugiat. Nullam finibus metus eget orci
                                            volutpat porta. Morbi quis arcu
                                            vulputate, dignissim mi ac, varius.
                                        </Card.Text>
                                    </Card.Body>
                                    <Image src={wepageBall} className="webpage-card-ico" />
                                </Card>
                            </Col>

                            <Col lg={4}>
                                <Card style={{ width: '100%' }}>
                                    <Card.Body>
                                        <Card.Title>Product branding</Card.Title>
                                        <Card.Subtitle>Package & products</Card.Subtitle>
                                        <Card.Text>
                                            Donec semper euismod nisi quis
                                            feugiat. Nullam finibus metus eget orci
                                            volutpat porta. Morbi quis arcu
                                            vulputate, dignissim mi ac, varius.
                                        </Card.Text>
                                    </Card.Body>
                                    <Image src={wepagePen} className="webpage-card-ico" />
                                </Card>
                            </Col>

                            <Col lg={4}>
                                <Card style={{ width: '100%' }}>
                                    <Card.Body>
                                        <Card.Title>SEO marketing</Card.Title>
                                        <Card.Subtitle>Online Services</Card.Subtitle>
                                        <Card.Text>
                                            Donec semper euismod nisi quis
                                            feugiat. Nullam finibus metus eget orci
                                            volutpat porta. Morbi quis arcu
                                            vulputate, dignissim mi ac, varius.
                                        </Card.Text>
                                    </Card.Body>
                                    <Image src={wepageSearch} className="webpage-card-ico" />
                                </Card>
                            </Col>

                        </Row>

                    </div>
                </div>

                <div className="webpage-stuff">
                    <div className="stuff-heading">

                        <div className="stuff-heading-main">
                            We Have Creative People
                        </div>

                        <div className="stuff-heading-minor">
                            Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed suscipit.<br />
                            Vestibulum auctor nunc vitae diam eleifend, in maximus metus sollicitudin.
                        </div>
                    </div>

                    <div className="webpage-pricing">
                        <Row>
                            <Col lg={4}>
                                <Card className="card-pricing h-100">
                                    <div className="card-body">
                                        <div className="text-center p-3">
                                            <h5 className="card-title">BASIC PLAN</h5>

                                            <span className="h2">$</span><span className="price">19</span>
                                            <p>/MONTH</p>
                                        </div>
                                    </div>

                                    <ListGroup className="list-group-flush">
                                        <ListGroup.Item className="list-group-item">Unlimited activity</ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Direct messaging</ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Members<span>10 members</span></ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Admins<span>No access</span></ListGroup.Item>
                                    </ListGroup>
                                    <Button className="btn-primary btn-lg rounded-pill" >Start with Basic</Button>
                                </Card>
                            </Col>

                            <Col lg={4}>
                                <Card className="card-pricing h-100">
                                    <div className="card-body">
                                        <div className="text-center p-3">
                                            <h5 className="card-title">BASIC PLAN</h5>

                                            <span className="h2">$</span><span className="price">29</span>
                                            <p>/MONTH</p>
                                        </div>
                                    </div>

                                    <ListGroup className="list-group-flush">
                                        <ListGroup.Item className="list-group-item">Unlimited activity</ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Direct messaging</ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Members<span>50 members</span></ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Admins<span>5 admins</span></ListGroup.Item>
                                    </ListGroup>
                                    <Button className="btn-primary btn-lg rounded-pill" >Start with Standard</Button>
                                </Card>
                            </Col>

                            <Col lg={4}>
                                <Card className="card-pricing h-100">
                                    <div className="card-body">
                                        <div className="text-center p-3">
                                            <h5 className="card-title">BASIC PLAN</h5>

                                            <span className="h2">$</span><span className="price">99</span>
                                            <p>/MONTH</p>
                                        </div>
                                    </div>

                                    <ListGroup className="list-group-flush">
                                        <ListGroup.Item className="list-group-item">Unlimited activity</ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Direct messaging</ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Members<span>Unlimited</span></ListGroup.Item>
                                        <ListGroup.Item className="list-group-item">Admins<span>Unlimited</span></ListGroup.Item>
                                    </ListGroup>
                                    <Button className="btn-primary btn-lg rounded-pill" >Start with Enterprice</Button>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="webpage-contact">
                    <Row>
                        <Col className="connect-info" lg={6}>
                            <div className="connect-info-div">
                                <div className="contact-heading">
                                    Connect<br />
                                    with <span style={{ "fontWeight": 900 }}>Trendy</span>.
                                </div>
                                <div className="contact-text">
                                    Curabitur mollis bibendum luctus.<br />
                                    Duis suscipit vitae dui sed suscipit.<br />
                                    Vestibulum auctor nunc vitae diam eleifend.
                                </div>
                                <div className="contact-info">
                                    <ul>
                                        <li>31 Van Choung Street</li>
                                        <li>Ha Noi, Viet Nam</li>
                                        <li>+84 (91)2 847 911</li>
                                        <li>Pagina@info.com</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>

                        <Col className="connect-input" lg={6}>

                            <Form >
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="First name"
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Last name"
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Subject"
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
<br/>
                                <Row>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Control as="textarea" rows={3} placeholder="Your message here..." />
                                        </Form.Group>
                                    </Form>
                                </Row>

                                <Button className="rounded-pill">Send Message</Button>
                            </Form>

                        </Col>
                    </Row>
                </div>

            </div>
        </>
    );
}

export default WebPage