import React from 'react'
import { InputGroup, FormControl, Form, Button, DropdownButton, Dropdown, SplitButton} from 'react-bootstrap'
import ReactPrism from '@versant-digital/react-prismjs';

const example = `<>
    <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
        <FormControl
            placeholder="Username"
            aria-label="Username"
            aria-describedby="basic-addon1"
        />
    </InputGroup>

    <InputGroup className="mb-3">
        <FormControl
            placeholder="Recipient's username"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
        />
        <InputGroup.Text id="basic-addon2">@example.com</InputGroup.Text>
    </InputGroup>

    <Form.Label htmlFor="basic-url">Your vanity URL</Form.Label>
    <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon3">
            https://example.com/users/
    </InputGroup.Text>
        <FormControl id="basic-url" aria-describedby="basic-addon3" />
    </InputGroup>

    <InputGroup className="mb-3">
        <InputGroup.Text>$</InputGroup.Text>
        <FormControl aria-label="Amount (to the nearest dollar)" />
        <InputGroup.Text>.00</InputGroup.Text>
    </InputGroup>

    <InputGroup>
        <InputGroup.Text>With textarea</InputGroup.Text>
        <FormControl as="textarea" aria-label="With textarea" />
    </InputGroup>
</>`

const sizing = `<>
    <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-sm">Small</InputGroup.Text>
        <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
    </InputGroup>
    <br />
    <InputGroup className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-default">Default</InputGroup.Text>
        <FormControl
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
        />
    </InputGroup>
    <br />
    <InputGroup size="lg">
        <InputGroup.Text id="inputGroup-sizing-lg">Large</InputGroup.Text>
        <FormControl aria-label="Large" aria-describedby="inputGroup-sizing-sm" />
    </InputGroup>
</>`

const buttonAddons = `<>
    <InputGroup className="mb-3">
        <Button variant="primary" id="button-addon1">
            Button
        </Button>
        <FormControl
            aria-label="Example text with button addon"
            aria-describedby="basic-addon1"
        />
    </InputGroup>

    <InputGroup className="mb-3">
        <FormControl
            placeholder="Recipient's username"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
        />
        <Button variant="primary" id="button-addon2">
            Button
        </Button>
    </InputGroup>
</>`

const buttonDropdown = `<InputGroup className="mb-3">
    <DropdownButton
        variant="primary"
        title="Dropdown"
        id="input-group-dropdown-1"
    >
        <Dropdown.Item href="#">Action</Dropdown.Item>
        <Dropdown.Item href="#">Another action</Dropdown.Item>
        <Dropdown.Item href="#">Something else here</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item href="#">Separated link</Dropdown.Item>
    </DropdownButton>
    <FormControl aria-label="Text input with dropdown button" />
</InputGroup>

<InputGroup className="mb-3">
    <FormControl aria-label="Text input with dropdown button" />

    <DropdownButton
        variant="primary"
        title="Dropdown"
        id="input-group-dropdown-2"
        align="end"
    >
        <Dropdown.Item href="#">Action</Dropdown.Item>
        <Dropdown.Item href="#">Another action</Dropdown.Item>
        <Dropdown.Item href="#">Something else here</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item href="#">Separated link</Dropdown.Item>
    </DropdownButton>
</InputGroup>

<InputGroup>
    <DropdownButton
        variant="primary"
        title="Dropdown"
        id="input-group-dropdown-3"
    >
        <Dropdown.Item href="#">Action</Dropdown.Item>
        <Dropdown.Item href="#">Another action</Dropdown.Item>
        <Dropdown.Item href="#">Something else here</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item href="#">Separated link</Dropdown.Item>
    </DropdownButton>
    <FormControl aria-label="Text input with 2 dropdown buttons" />
    <DropdownButton
        variant="primary"
        title="Dropdown"
        id="input-group-dropdown-4"
        align="end"
    >
        <Dropdown.Item href="#">Action</Dropdown.Item>
        <Dropdown.Item href="#">Another action</Dropdown.Item>
        <Dropdown.Item href="#">Something else here</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item href="#">Separated link</Dropdown.Item>
    </DropdownButton>
</InputGroup>`

const segmentedButtons = `<InputGroup className="mb-3 split-buttons-start">
    <SplitButton
        variant="primary"
        title="Action"
        id="segmented-button-dropdown-1"
    >
        <Dropdown.Item href="#">Action</Dropdown.Item>
        <Dropdown.Item href="#">Another action</Dropdown.Item>
        <Dropdown.Item href="#">Something else here</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item href="#">Separated link</Dropdown.Item>
    </SplitButton>
    <FormControl aria-label="Text input with dropdown button" />
</InputGroup>

<InputGroup className="mb-3 split-buttons-end">
    <FormControl aria-label="Text input with dropdown button" />
    <SplitButton
        variant="primary"
        title="Action"
        id="segmented-button-dropdown-2"
        alignRight
    >
        <Dropdown.Item href="#">Action</Dropdown.Item>
        <Dropdown.Item href="#">Another action</Dropdown.Item>
        <Dropdown.Item href="#">Something else here</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item href="#">Separated link</Dropdown.Item>
    </SplitButton>
</InputGroup>`

const multiple = `<InputGroup className="mb-3">
    <InputGroup.Text>First and last name</InputGroup.Text>
    <FormControl aria-label="First name"/>
    <FormControl aria-label="Last name"/>
</InputGroup>`

const multipleAddons = `<InputGroup className="mb-3 multiple-addons">
    <div className="multiple-buttons">
        <InputGroup.Text>$</InputGroup.Text>
        <InputGroup.Text>0.00</InputGroup.Text>
    </div>
    <FormControl aria-label="Dollar amount (with dot and two decimal places)" />
</InputGroup>
<InputGroup className="mb-3 multiple-addons">
    <FormControl aria-label="Dollar amount (with dot and two decimal places)" />
    <div className="multiple-buttons">
        <InputGroup.Text>$</InputGroup.Text>
        <InputGroup.Text>0.00</InputGroup.Text>
    </div>
</InputGroup>`

const customSelect = `<InputGroup className="mb-3">
    <InputGroup.Text>Options</InputGroup.Text>
    <Form.Select aria-label="Select options" >
        <option>Option 1</option>
        <option value="1">Option 2</option>
        <option value="2">Option 3</option>
        <option value="3">Option 4</option>
    </Form.Select>
</InputGroup>

<InputGroup className="mb-3">
    <Form.Select aria-label="Select options" >
        <option>Option 1</option>
        <option value="1">Option 2</option>
        <option value="2">Option 3</option>
        <option value="3">Option 4</option>
    </Form.Select>
    <InputGroup.Text>Options</InputGroup.Text>
</InputGroup>`

const doubleButton = `<InputGroup className="mb-3 split-buttons-start">
    <Button>Button</Button>
    <Button id="segmented-button-dropdown-1">Button</Button>
    <FormControl aria-label="Text input with dropdown button" />
</InputGroup>

<InputGroup className="mb-3 split-buttons-end">
    <FormControl aria-label="Text input with dropdown button" />
    <Button>Button</Button>
    <Button id="segmented-button-dropdown-2">Button</Button>
</InputGroup>`

const InputView = () => {

    return (
        <>
            <h1 className="title-section">Input Group</h1>

            <p>Place one add-on or button on either side of an input. You may also
                place one on both sides of an input. Remember to place
                <code>&lt;label&gt;</code>s outside the input group.</p>

            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                <FormControl
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                />
            </InputGroup>

            <InputGroup className="mb-3">
                <FormControl
                    placeholder="Recipient's username"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                />
                <InputGroup.Text id="basic-addon2">@example.com</InputGroup.Text>
            </InputGroup>

            <Form.Label htmlFor="basic-url">Your vanity URL</Form.Label>
            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon3">
                    https://example.com/users/
                </InputGroup.Text>
                <FormControl id="basic-url" aria-describedby="basic-addon3" />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <FormControl aria-label="Amount (to the nearest dollar)" />
                <InputGroup.Text>.00</InputGroup.Text>
            </InputGroup>

            <InputGroup>
                <InputGroup.Text>With textarea</InputGroup.Text>
                <FormControl as="textarea" aria-label="With textarea" />
            </InputGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {example}
            </ReactPrism>

            <h5 className="subtitle">Sizing</h5>

            <p>Add the relative form sizing classes to the <code>InputGroup</code> and
                contents within will automatically resize—no need for repeating the form
                control size classes on each element.</p>

            <>
                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Text id="inputGroup-sizing-sm">Small</InputGroup.Text>
                    <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
                </InputGroup>
                <br />
                <InputGroup className="mb-3">
                    <InputGroup.Text id="inputGroup-sizing-default">Default</InputGroup.Text>
                    <FormControl
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                    />
                </InputGroup>
                <br />
                <InputGroup size="lg">
                    <InputGroup.Text id="inputGroup-sizing-lg">Large</InputGroup.Text>
                    <FormControl aria-label="Large" aria-describedby="inputGroup-sizing-sm" />
                </InputGroup>
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {sizing}
            </ReactPrism>

            <h5 className="subtitle">Button Addons</h5>

            <InputGroup className="mb-3">
                <Button variant="primary" id="button-addon1">
                    Button
                </Button>
                <FormControl
                    aria-label="Example text with button addon"
                    aria-describedby="basic-addon1"
                />
            </InputGroup>

            <InputGroup className="mb-3">
                <FormControl
                    placeholder="Recipient's username"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                />
                <Button variant="primary" id="button-addon2">
                    Button
                </Button>
            </InputGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {buttonAddons}
            </ReactPrism>

            <h5 className="subtitle">Button with Dropdowns</h5>

            <InputGroup className="mb-3">
                <DropdownButton
                    variant="primary"
                    title="Dropdown"
                    id="input-group-dropdown-1"
                >
                    <Dropdown.Item href="#">Action</Dropdown.Item>
                    <Dropdown.Item href="#">Another action</Dropdown.Item>
                    <Dropdown.Item href="#">Something else here</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#">Separated link</Dropdown.Item>
                </DropdownButton>
                <FormControl aria-label="Text input with dropdown button" />
            </InputGroup>

            <InputGroup className="mb-3">
                <FormControl aria-label="Text input with dropdown button" />

                <DropdownButton
                    variant="primary"
                    title="Dropdown"
                    id="input-group-dropdown-2"
                    align="end"
                >
                    <Dropdown.Item href="#">Action</Dropdown.Item>
                    <Dropdown.Item href="#">Another action</Dropdown.Item>
                    <Dropdown.Item href="#">Something else here</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#">Separated link</Dropdown.Item>
                </DropdownButton>
            </InputGroup>

            <InputGroup>
                <DropdownButton
                    variant="primary"
                    title="Dropdown"
                    id="input-group-dropdown-3"
                >
                    <Dropdown.Item href="#">Action</Dropdown.Item>
                    <Dropdown.Item href="#">Another action</Dropdown.Item>
                    <Dropdown.Item href="#">Something else here</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#">Separated link</Dropdown.Item>
                </DropdownButton>
                <FormControl aria-label="Text input with 2 dropdown buttons" />
                <DropdownButton
                    variant="primary"
                    title="Dropdown"
                    id="input-group-dropdown-4"
                    align="end"
                >
                    <Dropdown.Item href="#">Action</Dropdown.Item>
                    <Dropdown.Item href="#">Another action</Dropdown.Item>
                    <Dropdown.Item href="#">Something else here</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#">Separated link</Dropdown.Item>
                </DropdownButton>
            </InputGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {buttonDropdown}
            </ReactPrism>

            <h5 className="subtitle">Segmented buttons</h5>

            <InputGroup className="mb-3 split-buttons-start">
                <SplitButton
                    variant="primary"
                    title="Action"
                    id="segmented-button-dropdown-1"
                >
                    <Dropdown.Item href="#">Action</Dropdown.Item>
                    <Dropdown.Item href="#">Another action</Dropdown.Item>
                    <Dropdown.Item href="#">Something else here</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#">Separated link</Dropdown.Item>
                </SplitButton>
                <FormControl aria-label="Text input with dropdown button" />
            </InputGroup>

            <InputGroup className="mb-3 split-buttons-end">
                <FormControl aria-label="Text input with dropdown button" />
                <SplitButton
                    variant="primary"
                    title="Action"
                    id="segmented-button-dropdown-2"
                    alignRight
                >
                    <Dropdown.Item href="#">Action</Dropdown.Item>
                    <Dropdown.Item href="#">Another action</Dropdown.Item>
                    <Dropdown.Item href="#">Something else here</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#">Separated link</Dropdown.Item>
                </SplitButton>
            </InputGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {segmentedButtons}
            </ReactPrism>

            <h5 className="subtitle">Multiple inputs</h5>

            <p>While multiple inputs are supported visually, validation styles are only
                available for input groups with a single input.</p>

            <InputGroup className="mb-3">
                <InputGroup.Text>First and last name</InputGroup.Text>
                <div className="multiple-inputs">
                    <FormControl aria-label="First name" />
                    <FormControl aria-label="Last name" />
                </div>
            </InputGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {multiple}
            </ReactPrism>

            <h5 className="subtitle">Multiple addons</h5>

            <p>Multiple add-ons are supported and can be mixed with checkbox and radio
                input versions.</p>

            <>
                <InputGroup className="mb-3 multiple-addons">
                    <div className="multiple-buttons">
                        <InputGroup.Text>$</InputGroup.Text>
                        <InputGroup.Text>0.00</InputGroup.Text>
                    </div>
                    <FormControl aria-label="Dollar amount (with dot and two decimal places)" />
                </InputGroup>
                <InputGroup className="mb-3 multiple-addons">
                    <FormControl aria-label="Dollar amount (with dot and two decimal places)" />
                    <div className="multiple-buttons">
                        <InputGroup.Text>$</InputGroup.Text>
                        <InputGroup.Text>0.00</InputGroup.Text>
                    </div>
                </InputGroup>
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {multipleAddons}
            </ReactPrism>

            <h5 className="subtitle">Custom forms</h5>

            <InputGroup className="mb-3">
                <InputGroup.Text>Options</InputGroup.Text>
                <Form.Select aria-label="Select options" >
                    <option>Option 1</option>
                    <option value="1">Option 2</option>
                    <option value="2">Option 3</option>
                    <option value="3">Option 4</option>
                </Form.Select>
            </InputGroup>

            <InputGroup className="mb-3">
                <Form.Select aria-label="Select options" >
                    <option>Option 1</option>
                    <option value="1">Option 2</option>
                    <option value="2">Option 3</option>
                    <option value="3">Option 4</option>
                </Form.Select>
                <InputGroup.Text>Options</InputGroup.Text>
            </InputGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {customSelect}
            </ReactPrism>

            <InputGroup className="mb-3 split-buttons-start">
                <Button>Button</Button>
                <Button id="segmented-button-dropdown-1">Button</Button>
                <FormControl aria-label="Text input with dropdown button" />
            </InputGroup>

            <InputGroup className="mb-3 split-buttons-end">
                <FormControl aria-label="Text input with dropdown button" />
                <Button>Button</Button>
                <Button id="segmented-button-dropdown-2">Button</Button>
            </InputGroup>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {doubleButton}
            </ReactPrism>

            <h5 className="subtitle">Custom file input</h5>

            <InputGroup className="mb-3">
                <Button>Upload</Button>
                <Form.Control type="file" />
                <Button>Button</Button>
            </InputGroup>
        </>
    )
}

export default InputView