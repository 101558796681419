import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import ReactPrism from '@versant-digital/react-prismjs';

const progressDark = `<ProgressBar now={60} variant="dark" />`

const progressPrimary = `<ProgressBar now={60} variant="primary" />`

const progressLabel = `const now = 60;

return(
  <ProgressBar now={now} label={\`\${now}%\`} className="label-progress" variant="primary" />
  )`

const range = `const { Handle } = Slider;
const handle = props => {
  const { value, dragging, index, ...restProps } = props;  
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={\`\${value} %\`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );

return(
  <div className="range-wrapper">
    <Slider min={0} max={100} defaultValue={50} handle={handle} />
  </div>
)`

const contextual = `<div>
  <ProgressBar variant="success" now={40} />
  <ProgressBar variant="info" now={20} />
  <ProgressBar variant="warning" now={60} />
  <ProgressBar variant="danger" now={80} />
</div>`

const stacked = `<ProgressBar className="multiple-bars">
  <ProgressBar variant="success" now={15} key={1} />
  <ProgressBar variant="info" now={25} key={2} />
  <ProgressBar variant="warning" now={10} key={3} />
  <ProgressBar variant="danger" now={15} key={4} />
</ProgressBar>`
const screenreader = `<ProgressBar now={now} label={\`\${now}%\`} visuallyHidden variant="primary"/>`

const striped = `<div>
  <ProgressBar striped variant="success" now={40} />
  <ProgressBar striped variant="info" now={20} />
  <ProgressBar striped variant="warning" now={60} />
  <ProgressBar striped variant="danger" now={80} />
</div>`

const animated = `<ProgressBar animated now={45} />`

const solidColor = `<ProgressBar now={80} variant="primary"/>
<ProgressBar now={60} variant="secondary"/>
<ProgressBar now={40} variant="success"/>
<ProgressBar now={90} variant="info"/>
<ProgressBar now={70} variant="warning"/>
<ProgressBar now={30} variant="danger"/>
<ProgressBar now={80} variant="dark"/>`

const customHeights = `<ProgressBar now={60} variant="primary" style={{height:"24px"}}/>
<ProgressBar now={60} variant="primary" style={{height:"20px"}}/>
<ProgressBar now={60} variant="primary" style={{height:"16px"}}/>
<ProgressBar now={60} variant="primary" style={{height:"10px"}}/>`

const ProgressBarView = () => {

  const { Handle } = Slider;
  const now = 60;

  const handle = props => {
    const { value, dragging, index, ...restProps } = props;

    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} %`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  return (
    <>
      <h1 className="title-section">Progress bars</h1>

      <p className="lead">Provide up-to-date feedback on the progress of a workflow or action with simple yet flexible progress bars.</p>

      <h5 className="subtitle">Example</h5>

      <p>Default progress bar.</p>

      <ProgressBar now={60} variant="dark" />

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {progressDark}
      </ReactPrism>

      <ProgressBar now={60} variant="primary" />

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {progressPrimary}
      </ReactPrism>

      <h5 className="subtitle">With label</h5>

      <p>Add a <code>label</code> prop to show a visible percentage. For low
        percentages, consider adding a min-width to ensure the label's text is
        fully visible.</p>

      <ProgressBar now={now} label={`${now}%`} className="label-progress" variant="primary" />

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {progressLabel}
      </ReactPrism>

      <h5 className="subtitle">Screenreader only label</h5>
      <p>Add a <code>visuallyHidden</code> prop to hide the label visually.</p>
      <ProgressBar now={now} label={`${now}%`} visuallyHidden variant="primary" />
      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {screenreader}
      </ReactPrism>

      <h5 className="subtitle">Range</h5>
      <p>For range element need to import rc-slider plugin.</p>

      <div className="range-wrapper">
        <Slider min={0} max={100} defaultValue={50} handle={handle} />
      </div>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {range}
      </ReactPrism>

      <h5 className="subtitle">Contextual alternatives</h5>
      <p>Progress bars use some of the same button and alert classes for consistent styles.</p>

      <div>
        <ProgressBar variant="success" now={40} />
        <ProgressBar variant="info" now={20} />
        <ProgressBar variant="warning" now={60} />
        <ProgressBar variant="danger" now={80} />
      </div>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {contextual}
      </ReactPrism>

      <h5 className="subtitle">Striped</h5>
      <p>Uses a gradient to create a striped effect.</p>

      <div>
        <ProgressBar striped variant="success" now={40} />
        <ProgressBar striped variant="info" now={20} />
        <ProgressBar striped variant="warning" now={60} />
        <ProgressBar striped variant="danger" now={80} />
      </div>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {striped}
      </ReactPrism>

      <h5 className="subtitle">Animated</h5>
      <p>Add <code>animated</code> prop to animate the stripes right to left. Not available in IE9 and below.</p>

      <ProgressBar animated now={45} />

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {animated}
      </ReactPrism>


      <h5 className="subtitle">Stacked</h5>
      <p>Nest <code>&lt;ProgressBar /&gt;</code>s to stack them.</p>

      <ProgressBar className="multiple-bars">
        <ProgressBar variant="success" now={15} key={1} />
        <ProgressBar variant="info" now={25} key={2} />
        <ProgressBar variant="warning" now={10} key={3} />
        <ProgressBar variant="danger" now={15} key={4} />
      </ProgressBar>

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {stacked}
      </ReactPrism>

      <h5 className="subtitle">Custom heights</h5>

      <ProgressBar now={60} variant="primary" style={{ height: "24px" }} />
      <ProgressBar now={60} variant="primary" style={{ height: "20px" }} />
      <ProgressBar now={60} variant="primary" style={{ height: "16px" }} />
      <ProgressBar now={60} variant="primary" style={{ height: "10px" }} />

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {customHeights}
      </ReactPrism>

      <h5 className="subtitle">Solid color variants</h5>

      <ProgressBar now={80} variant="primary" />
      <ProgressBar now={60} variant="secondary" />
      <ProgressBar now={40} variant="success" />
      <ProgressBar now={90} variant="info" />
      <ProgressBar now={70} variant="warning" />
      <ProgressBar now={30} variant="danger" />
      <ProgressBar now={80} variant="dark" />

      <ReactPrism
        language="html"
        plugins={["line-numbers"]}>
        {solidColor}
      </ReactPrism>
    </>
  );
}

export default ProgressBarView