import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Image, DropdownButton, Dropdown } from 'react-bootstrap';
import burgerIco from '../assets/burger-white.svg'
const Nav = (props) => {

    return (

        <div>
            <nav className="navigation">
                <Container>
                    <div className="navigation-container">
                        <div className="logo">
                            <Link to="/"><h1>Trendy.</h1></Link>
                        </div>
                        <ul className="menu">
                            <li>
                                <DropdownButton id="dropdown-basic-button" title="Docs">
                                    <Dropdown.Item href="https://flatuikit-html.trendy.solutions/components/alerts.html">HTML</Dropdown.Item>
                                    <Dropdown.Item href="https://flatuikit-angular.trendy.solutions">Angular</Dropdown.Item>
                                    <Dropdown.Item href="https://flatuikit-html.trendy.solutions/components/alerts.html">Vue</Dropdown.Item>
                                </DropdownButton>
                            </li>
                        </ul>
                    </div>
                    <div className="nav-button">
                        <a href="https://flatuikit-html.trendy.solutions/components/alerts.html">Buy now</a>
                    </div>
                    <Button onClick={props.toggleMenu} className="burger-button" ><Image src={burgerIco} /></Button>
                </Container>
            </nav>
        </div>
    )

};

export default Nav;