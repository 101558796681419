import React from 'react';
import { Dropdown, DropdownButton, ButtonGroup, Button, SplitButton} from 'react-bootstrap';
import ReactPrism from '@versant-digital/react-prismjs';

const defaultExtraLarge = `const variants = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"]

return(
    {variants.map((variant) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant={variant} id="dropdown-basic" size="xl">
                    {variant.charAt(0).toUpperCase() + variant.slice(1)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    })}
)`

const defaultLarge = `const variants = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"]

return(
    {variants.map((variant) => {
    return (
        <Dropdown>
            <Dropdown.Toggle variant={variant} id="dropdown-basic" size="lg">
                {variant.charAt(0).toUpperCase() + variant.slice(1)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        )
    })}
)`

const defaultStandard = `const variants = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"]

return(
    {variants.map((variant) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant={variant} id="dropdown-basic" size="md">
                    {variant.charAt(0).toUpperCase() + variant.slice(1)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    })}
)`

const small = `const variants = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"]
return(
    {variants.map((variant) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant={variant} id="dropdown-basic" size="sm">
                    {variant.charAt(0).toUpperCase() + variant.slice(1)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    })}
)`

const extraSmall = `const variants = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"]
return(
    {variants.map((variant) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant={variant} id="dropdown-basic" size="xs">
                    {variant.charAt(0).toUpperCase() + variant.slice(1)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    })}
)`

const roundedExtraLarge = `const variants = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"]
return(
    {variants.map((variant) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant={variant + " rounded-pill"} id="dropdown-basic" size="xl">
                    {variant.charAt(0).toUpperCase() + variant.slice(1)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    })}
)`

const roundedLarge = `const variants = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"]
return(
    {variants.map((variant) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant={variant + " rounded-pill"} id="dropdown-basic" size="lg">
                    {variant.charAt(0).toUpperCase() + variant.slice(1)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    })}
)`

const roundedMedium = `const variants = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"]
return(
    {variants.map((variant) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant={variant + " rounded-pill"} id="dropdown-basic" size="md">
                    {variant.charAt(0).toUpperCase() + variant.slice(1)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    })}
)`

const roundedSmall = `const variants = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"]
return(
    {variants.map((variant) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant={variant + " rounded-pill"} id="dropdown-basic" size="sm">
                    {variant.charAt(0).toUpperCase() + variant.slice(1)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    })}
)`

const roundedExtraSmall = `const variants = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"]
return(
    {variants.map((variant) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant={variant + " rounded-pill"} id="dropdown-basic" size="xs">
                    {variant.charAt(0).toUpperCase() + variant.slice(1)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    })}
)`

const dropdown = `<DropdownButton
id="dropdown-button-dark-example2"
variant="primary"
title="Primary"
className="mt-2"
>
    <Dropdown.Item href="#/action-1" active>
        Action
    </Dropdown.Item>
    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
    <Dropdown.Divider />
    <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
</DropdownButton>`

const splitDropdown = `
<Dropdown as={ButtonGroup}>
    <Button variant="primary">Primary</Button>

    <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

    <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
    </Dropdown.Menu>
</Dropdown>`

const dropUp = `<DropdownButton
as={ButtonGroup}
key={'up'}
id={'dropdown-button-drop-up'}
drop={'up'}
variant="primary"
title={'Primary'}
>
    <Dropdown.Item eventKey="1">Action</Dropdown.Item>
    <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
    <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
    <Dropdown.Divider />
    <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
</DropdownButton>`

const splitDropUp = `<SplitButton
key="up"
id="dropdown-button-drop-up"
drop="up"
variant="primary"
title="Primary"
>
    <Dropdown.Item eventKey="1">Action</Dropdown.Item>
    <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
    <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
    <Dropdown.Divider />
    <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
</SplitButton>`

const dropRight = `<SplitButton
key="end"
id="dropdown-button-drop-end"
drop="end"
variant="primary"
title="Primary"
>
    <Dropdown.Item eventKey="1">Action</Dropdown.Item>
    <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
    <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
    <Dropdown.Divider />
    <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
</SplitButton>`

const dropLeft = `<SplitButton
key="start"
id="dropdown-button-drop-start"
drop="start"
variant="primary"
title="Primary"
>
    <Dropdown.Item eventKey="1">Action</Dropdown.Item>
    <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
    <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
    <Dropdown.Divider />
    <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
</SplitButton>`

const example = `<Dropdown>
    <Dropdown.Toggle variant="success" id="dropdown-basic">
    Dropdown Button
    </Dropdown.Toggle>

    <Dropdown.Menu>
    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
    </Dropdown.Menu>
</Dropdown>`

const example2 = `<DropdownButton id="dropdown-basic-button" title="Dropdown button">
    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
</DropdownButton>
`
const toggle = `<>
    {['Primary', 'Secondary', 'Success', 'Info', 'Warning', 'Danger'].map(
    (variant) => (
        <DropdownButton
        as={ButtonGroup}
        key={variant}
        id={\`dropdown-variants-\${variant}\`}
        variant={variant.toLowerCase()}
        title={variant}
        style={{marginRight:"10px", marginBottom:"10px"}}
        >
        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
        <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
        <Dropdown.Item eventKey="3" active>
            Active Item
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
        </DropdownButton>
    ),
    )}
</>`

const dark = `<>
    <Dropdown>
    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
        Dropdown Button
    </Dropdown.Toggle>

    <Dropdown.Menu variant="dark">
        <Dropdown.Item href="#/action-1" active>
        Action
        </Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
    </Dropdown.Menu>
    </Dropdown>

    <DropdownButton
    id="dropdown-button-dark-example2"
    variant="secondary"
    menuVariant="dark"
    title="Dropdown button"
    className="mt-2"
    >
    <Dropdown.Item href="#/action-1" active>
        Action
    </Dropdown.Item>
    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
    <Dropdown.Divider />
    <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
    </DropdownButton>
</>`

const dropdownItems = `<DropdownButton id="dropdown-item-button" title="Dropdown button">
    <Dropdown.ItemText>Dropdown item text</Dropdown.ItemText>
    <Dropdown.Item as="button">Action</Dropdown.Item>
    <Dropdown.Item as="button">Another action</Dropdown.Item>
    <Dropdown.Item as="button">Something else</Dropdown.Item>
</DropdownButton>`

const dropdownEnd = `<DropdownButton align="end" title="Dropdown end" id="dropdown-menu-align-end">
    <Dropdown.Item eventKey="1">Action</Dropdown.Item>
    <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
    <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
    <Dropdown.Divider />
    <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
</DropdownButton>`

const responsiveAlignment = `<div>
    <DropdownButton
    as={ButtonGroup}
    align={{ lg: 'end' }}
    title="Left-aligned but right aligned when large screen"
    id="dropdown-menu-align-responsive-1"
    >
    <Dropdown.Item eventKey="1">Action 1</Dropdown.Item>
    <Dropdown.Item eventKey="2">Action 2</Dropdown.Item>
    </DropdownButton>
</div>`

const menuHeaders = `<Dropdown >
    <Dropdown.Toggle>
    Default Dropdown
    </Dropdown.Toggle>

    <Dropdown.Menu>
    <Dropdown.Header>Dropdown header</Dropdown.Header>
    <Dropdown.Item href="#">Menu Item</Dropdown.Item>
    <Dropdown.Item href="#">Menu Item</Dropdown.Item>
    <Dropdown.Item href="#">Menu Item</Dropdown.Item>
    </Dropdown.Menu>
</Dropdown>`

const menuDividers = `<Dropdown >
    <Dropdown.Toggle>
        Default Dropdown
    </Dropdown.Toggle>

    <Dropdown.Menu>
        <Dropdown.Header>Dropdown header</Dropdown.Header>
        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
    </Dropdown.Menu>
</Dropdown>`

const autoClose = `<>
    <Dropdown className="d-inline mx-2">
    <Dropdown.Toggle id="dropdown-autoclose-true">
        Default Dropdown
    </Dropdown.Toggle>

    <Dropdown.Menu>
        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
    </Dropdown.Menu>
    </Dropdown>

    <Dropdown className="d-inline mx-2" autoClose="inside">
    <Dropdown.Toggle id="dropdown-autoclose-inside">
        Clickable Outside
    </Dropdown.Toggle>

    <Dropdown.Menu>
        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
    </Dropdown.Menu>
    </Dropdown>

    <Dropdown className="d-inline mx-2" autoClose="outside">
    <Dropdown.Toggle id="dropdown-autoclose-outside">
        Clickable Inside
    </Dropdown.Toggle>

    <Dropdown.Menu>
        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
    </Dropdown.Menu>
    </Dropdown>

    <Dropdown className="d-inline mx-2" autoClose={false}>
    <Dropdown.Toggle id="dropdown-autoclose-false">
        Manual Close
    </Dropdown.Toggle>

    <Dropdown.Menu>
        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
    </Dropdown.Menu>
    </Dropdown>
</>`

const customization = `<>
    <Dropdown as={ButtonGroup}>
    <Dropdown.Toggle id="dropdown-custom-1">Pow! Zoom!</Dropdown.Toggle>
    <Dropdown.Menu className="super-colors">
        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
        <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
        <Dropdown.Item eventKey="3" active>
        Active Item
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
    </Dropdown.Menu>
    </Dropdown>{' '}
    <Dropdown as={ButtonGroup}>
    <Button variant="info">mix it up style-wise</Button>
    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
    <Dropdown.Menu className="super-colors">
        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
        <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
        <Dropdown.Item eventKey="3" active>
        Active Item
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
    </Dropdown.Menu>
    </Dropdown>
</>`

const DropdownView = () => {

    const variants = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"]

    return (
        <>

            <h1 className="title-section">Dropdowns</h1>
            <p>Toggle contextual overlays for displaying lists of links and more with the Bootstrap dropdown plugin</p>

            <h5 className="subtitle">Overview</h5>
            <p>Dropdowns are toggleable, contextual overlays for displaying lists of
                links and more. Like overlays, Dropdowns are built using a third-party
                library <a href="https://popper.js.org/">Popper.js</a>, which provides
                dynamic positioning and viewport detection.</p>

            <h5 className="subtitle">Accessibility</h5>

            <p>The <a href="https://www.w3.org/TR/wai-aria/"><abbr title="Web Accessibility Initiative">WAI</abbr> <abbr title="Accessible Rich Internet Applications">ARIA</abbr></a> standard
                defines a <a href="https://www.w3.org/TR/wai-aria-1.1/#menu"><code>role="menu"</code> widget</a>, but it's very specific to a certain kind of menu. <abbr title="Accessible Rich Internet Applications">ARIA</abbr> menus, must
                only contain <code>role="menuitem"</code>, <code>role="menuitemcheckbox"</code>, or <code>role="menuitemradio"</code>.</p>

            <p>On the other hand, Bootstrap's dropdowns are designed to more generic
                and application in a variety of situations. For this reason we don't
                automatically add the menu roles to the markup. We do implement some
                basic keyboard navigation, and if you do provide the "menu" role,
                react-bootstrap will do its best to ensure the focus management is
                compliant with the <abbr title="Accessible Rich Internet Applications">ARIA</abbr> authoring guidelines for menus.</p>

            <h5 className="subtitle">Accessibility</h5>

            <h5 className="subtitle">Single button dropdowns</h5>

            <p>The basic Dropdown is composed of a wrapping <code>Dropdown</code> and
                inner <code>&lt;DropdownMenu&gt;</code>, and <code>&lt;DropdownToggle&gt;</code>. By
                default the <code>&lt;DropdownToggle&gt;</code> will render a
                <code>Button</code> component and accepts all the same props.</p>

            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Dropdown Button
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {example}
            </ReactPrism>

            <p>Since the above is such a common configuration react-bootstrap provides
                the <code>&lt;DropdownButton&gt;</code> component to help reduce typing. Provide
                a <code>title</code> prop and some <code>&lt;DropdownItem&gt;</code>s and you're
                ready to go.</p>

            <DropdownButton id="dropdown-basic-button" title="Dropdown button">
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </DropdownButton>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {example2}
            </ReactPrism>

            <p>DropdownButton will forward Button props to the underlying Toggle
                component</p>

            {['Primary', 'Secondary', 'Success', 'Info', 'Warning', 'Danger'].map(
                (variant) => (
                    <DropdownButton
                        as={ButtonGroup}
                        key={variant}
                        id={`dropdown-variants-${variant}`}
                        variant={variant.toLowerCase()}
                        title={variant}
                        style={{marginRight:"10px", marginBottom:"10px"}}
                    >
                        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                        <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                        <Dropdown.Item eventKey="3" active>
                            Active Item
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
                    </DropdownButton>
                ),
            )}

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {toggle}
            </ReactPrism>

            <h5>Split button dropdowns</h5>

            <p>Similarly, You create a split dropdown by combining the Dropdown components with another Button and a ButtonGroup.</p>

            <h5 className="subtitle">Dropdown</h5>

            <div className="row-custom">
                <DropdownButton
                    id="dropdown-button-dark-example2"
                    variant="primary"
                    
                    title="Primary"
                    className="mt-2"
                >
                    <Dropdown.Item href="#/action-1" active>
                        Action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
                </DropdownButton>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {dropdown}
            </ReactPrism>

            <h5 className="subtitle">Sizing</h5>
            <p>
                Dropdowns work with buttons of all sizes.
            </p>

            <h5 className="subtitle">Default Extra large</h5>

            <div className="wrap-row">
                {variants.map((variant) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant={variant} id="dropdown-basic" size="xl">
                                {variant.charAt(0).toUpperCase() + variant.slice(1)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                })}
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {defaultExtraLarge}
            </ReactPrism>

            <h5 className="subtitle">Default large</h5>

            <div className="wrap-row">
                {variants.map((variant) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant={variant} id="dropdown-basic" size="lg">
                                {variant.charAt(0).toUpperCase() + variant.slice(1)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                })}
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {defaultLarge}
            </ReactPrism>

            <h5 className="subtitle">Default</h5>

            <div className="wrap-row">
                {variants.map((variant) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant={variant} id="dropdown-basic" size="md">
                                {variant.charAt(0).toUpperCase() + variant.slice(1)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                })}
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {defaultStandard}
            </ReactPrism>

            <h5 className="subtitle">Small</h5>

            <div className="wrap-row">
                {variants.map((variant) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant={variant} id="dropdown-basic" size="sm">
                                {variant.charAt(0).toUpperCase() + variant.slice(1)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                })}
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {small}
            </ReactPrism>

            <h5 className="subtitle">Extra small</h5>

            <div className="wrap-row">
                {variants.map((variant) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant={variant} id="dropdown-basic" size="xs">
                                {variant.charAt(0).toUpperCase() + variant.slice(1)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                })}
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {extraSmall}
            </ReactPrism>

            <h5 className="subtitle">Rounded size</h5>
            <h5 className="subtitle">Extra Large Rounded</h5>

            <div className="wrap-row">
                {variants.map((variant) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant={variant + " rounded-pill"} id="dropdown-basic" size="xl">
                                {variant.charAt(0).toUpperCase() + variant.slice(1)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                })}

            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {roundedExtraLarge}
            </ReactPrism>

            <h5 className="subtitle">Default large Rounded</h5>

            <div className="wrap-row">
                {variants.map((variant) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant={variant + " rounded-pill"} id="dropdown-basic" size="lg">
                                {variant.charAt(0).toUpperCase() + variant.slice(1)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                })}
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {roundedLarge}
            </ReactPrism>

            <h5 className="subtitle">Default Rounded</h5>

            <div className="wrap-row">
                {variants.map((variant) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant={variant + " rounded-pill"} id="dropdown-basic" size="md">
                                {variant.charAt(0).toUpperCase() + variant.slice(1)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                })}
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {roundedMedium}
            </ReactPrism>

            <h5 className="subtitle">Small Rounded</h5>

            <div className="wrap-row">
                {variants.map((variant) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant={variant + " rounded-pill"} id="dropdown-basic" size="sm">
                                {variant.charAt(0).toUpperCase() + variant.slice(1)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                })}
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {roundedSmall}
            </ReactPrism>

            <h5 className="subtitle">Extra small Rounded</h5>

            <div className="wrap-row">
                {variants.map((variant) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant={variant + " rounded-pill"} id="dropdown-basic" size="xs">
                                {variant.charAt(0).toUpperCase() + variant.slice(1)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                })}
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {roundedExtraSmall}
            </ReactPrism>

            <h5 className="subtitle">Dark dropdowns</h5>

            <p>Opt into darker dropdowns to match a dark navbar or custom style by adding
                <code>variant="dark"</code> onto an existing <code>DropdownMenu</code>. Alternatively, use
                <code>menuVariant="dark"</code> when using the <code>DropdownButton</code> component.</p>

            <Dropdown>
                <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                    Dropdown Button
                </Dropdown.Toggle>

                <Dropdown.Menu variant="dark">
                    <Dropdown.Item href="#/action-1" active>
                        Action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <DropdownButton
                id="dropdown-button-dark-example2"
                variant="secondary"
                menuVariant="dark"
                title="Dropdown button"
                className="mt-2"
            >
                <Dropdown.Item href="#/action-1" active>
                    Action
                </Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
            </DropdownButton>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {dark}
            </ReactPrism>

            <h5 className="subtitle">Drop directions</h5>

            <p>
                Trigger dropdown menus above, below, left, or to the right of their toggle elements, with the drop prop.
            </p>

            <h5 className="subtitle">Split dropdown</h5>

            <Dropdown as={ButtonGroup}>
                <Button variant="primary">Primary</Button>

                <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {splitDropdown}
            </ReactPrism>

            <h5 className="subtitle">Dropup</h5>

            <div className="row-custom">

                <DropdownButton
                    as={ButtonGroup}
                    key={'up'}
                    id={`dropdown-button-drop-up`}
                    drop={'up'}
                    variant="primary"
                    title={`Primary`}
                >
                    <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                    <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                    <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
                </DropdownButton>
            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {dropUp}
            </ReactPrism>

            <h5 className="subtitle">Split dropup</h5>

            <SplitButton
                key="up"
                id="dropdown-button-drop-up"
                drop="up"
                variant="primary"
                title="Primary"
            >
                <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
            </SplitButton>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {splitDropUp}
            </ReactPrism>

            <h5 className="subtitle">Dropright</h5>

            <SplitButton
                key="end"
                id="dropdown-button-drop-end"
                drop="end"
                variant="primary"
                title="Primary"
            >
                <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
            </SplitButton>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {dropRight}
            </ReactPrism>

            <h5 className="subtitle">Dropleft</h5>

            <SplitButton
                key="start"
                id="dropdown-button-drop-start"
                drop="start"
                variant="primary"
                title="Primary"
            >
                <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
            </SplitButton>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {dropLeft}
            </ReactPrism>

            <h5 className="subtitle">Dropdown Items</h5>

            <p>Historically dropdown menu contents had to be links, but that’s no
                longer the case with v4. Now you can optionally use
                <code>&lt;button&gt;</code> elements in your dropdowns instead of just <code>&lt;a&gt;</code>s.</p>

            <p>You can also create non-interactive dropdown items with <code>&lt;Dropdown.ItemText&gt;</code>.
                Feel free to style further with custom CSS or text utilities.</p>

            <DropdownButton id="dropdown-item-button" title="Dropdown button">
                <Dropdown.ItemText>Dropdown item text</Dropdown.ItemText>
                <Dropdown.Item as="button">Action</Dropdown.Item>
                <Dropdown.Item as="button">Another action</Dropdown.Item>
                <Dropdown.Item as="button">Something else</Dropdown.Item>
            </DropdownButton>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {dropdownItems}
            </ReactPrism>

            <h5 className="subtitle">Menu alignment</h5>

            <p>By default, a dropdown menu is aligned to the left, but you can switch
                it by passing <code>align="end"</code> to a <code>&lt;Dropdown&gt;</code>, <code>&lt;DropdownButton&gt;</code>, or <code>&lt;SplitButton&gt;</code>.</p>

            <DropdownButton align="end" title="Dropdown end" id="dropdown-menu-align-end">
                <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
            </DropdownButton>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {dropdownEnd}
            </ReactPrism>

            <h5 className="subtitle">Responsive alignment</h5>

            <p>If you want to use responsive menu alignment, pass an object containing a breakpoint to the
                <code>align</code> prop on the <code>&lt;DropdownMenu&gt;</code>, <code>&lt;DropdownButton&gt;</code>, or <code>&lt;SplitButton&gt;</code>.
                You can specify <code>start</code> or <code>end</code> for the various breakpoints.</p>

            <aside role="note" className="Callout-styles-module--danger--19388 Callout-styles-module--callout--1_K3h"><header className="h5">Warning</header><div>Using responsive alignment will disable Popper usage so any dynamic positioning features such as <code>flip</code> will not work.</div></aside>
            <br />

            <>
                <div>
                    <DropdownButton
                        as={ButtonGroup}
                        align={{ lg: 'end' }}
                        title="Left-aligned but right aligned when large screen"
                        id="dropdown-menu-align-responsive-1"
                    >
                        <Dropdown.Item eventKey="1">Action 1</Dropdown.Item>
                        <Dropdown.Item eventKey="2">Action 2</Dropdown.Item>
                    </DropdownButton>
                </div>
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {responsiveAlignment}
            </ReactPrism>

            <h5 className="subtitle">Menu headers</h5>

            <p>Add a header to label sections of actions.</p>

            <Dropdown >
                <Dropdown.Toggle>
                    Default Dropdown
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Header>Dropdown header</Dropdown.Header>
                    <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                    <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                    <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {menuHeaders}
            </ReactPrism>

            <h5 className="subtitle">Menu dividers</h5>

            <p>Separate groups of related menu items with a divider.</p>

            <Dropdown >
                <Dropdown.Toggle>
                    Default Dropdown
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Header>Dropdown header</Dropdown.Header>
                    <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                    <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {menuDividers}
            </ReactPrism>

            <h5 className="subtitle">AutoClose</h5>

            <p>By default, the dropdown menu is closed when selecting a menu item or clicking outside of the
                dropdown menu. This behaviour can be changed by using the <code>autoClose</code> property.</p>

            <p>By default, <code>autoClose</code> is set to the default value <code>true</code> and behaves like expected. By choosing <code>false</code>, the dropdown
                menu can only be toggled by clicking on the dropdown button. <code>inside</code> makes the dropdown disappear <strong>only </strong>
                by choosing a menu item and <code>outside</code> closes the dropdown menu <strong>only</strong> by clicking outside.</p>

            <p><strong>Notice</strong> how the dropdown is toggled in each scenario by clicking on the button.</p>

            <>
                <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle id="dropdown-autoclose-true">
                        Default Dropdown
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="d-inline mx-2" autoClose="inside">
                    <Dropdown.Toggle id="dropdown-autoclose-inside">
                        Clickable Outside
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="d-inline mx-2" autoClose="outside">
                    <Dropdown.Toggle id="dropdown-autoclose-outside">
                        Clickable Inside
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="d-inline mx-2" autoClose={false}>
                    <Dropdown.Toggle id="dropdown-autoclose-false">
                        Manual Close
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                        <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {autoClose}
            </ReactPrism>

            <h5 className="subtitle">Customization</h5>

            <p>If the default handling of the dropdown menu and toggle components
                aren't to your liking, you can customize them, by using the more basic
                <code>&lt;Dropdown&gt;</code> Component to explicitly specify the Toggle and
                Menu components</p>

            <>
            <div className="wrap-row">
                <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle id="dropdown-custom-1">Pow! Zoom!</Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                        <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                        <Dropdown.Item eventKey="3" active>
                            Active Item
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>{' '}
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">mix it up style-wise</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                        <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                        <Dropdown.Item eventKey="3" active>
                            Active Item
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </div>
            </>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {customization}
            </ReactPrism>

        </>
    );
}

export default DropdownView