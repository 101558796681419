import React from 'react';
import ReactPrism from '@versant-digital/react-prismjs';
import leftArrow from '../assets/left_arrow_switch.svg'
import rightArrow from '../assets/right_arrow_switch.svg'

const switch1 = `<div className="container-switch">
  <div className="pair primary-checked">
  <div className="toggle-small">
    <label className="toggle-switch round-primary">
      <input type="checkbox" />
      <div className="slider"></div>
    </label>
    <p>Switch</p>
  </div>
  <label className="toggle-switch round-primary big">
    <input type="checkbox" />
    <div className="slider"></div>
  </label>
  </div>
  <div className="pair primary-unchecked">
  <div className="toggle-small">
    <label className="toggle-switch round-primary">
      <input type="checkbox" />
      <div className="slider"></div>
    </label>
    <p>Switch</p>
  </div>
  <label className="toggle-switch round-primary big">
    <input type="checkbox" />
    <div className="slider"></div>
  </label>
  </div>
</div>`

const switch2 = `<div className="container-switch">
  <div className="small-with-bg">
  <div className="toggle-small light">
    <label className="toggle-switch round-primary">
      <input type="checkbox" />
      <div className="slider"></div>
    </label>
    <p>Switch</p>
  </div>
  <div className="toggle-small light">
    <label className="toggle-switch round-primary">
      <input type="checkbox" />
      <div className="slider"></div>
    </label>
    <p>Switch</p>
  </div>
  </div>
</div>`

const switch3 = `<div className="container-switch">
  <div className="switcher-big ">
    <label className="toggle-switch round-primary big">
      <input type="checkbox" />
      <div className="slider"></div>
      <div className="switch">
        <div className="">OFF</div>
        <div className="">ON</div>
      </div>
    </label>
  </div>
</div>`

const switch4 = `<div className="container-switch">
  <div className="arrow-group">
  <div className="switcher-small">
    <label className="toggle-switch round-primary big">
      <input type="checkbox" />
      <div className="slider">
        <div className="switch">
          <div className="image"><img src={leftArrow} alt="" />
          </div>
          <div className="image"><img src={rightArrow} alt="" /></div>
        </div>
      </div>
    </label>
  </div>

  <div className="switcher-small">
    <label className="toggle-switch round-primary big">
      <input type="checkbox" />
      <div className="slider">
        <div className="switch">
          <div className="image"><img src={leftArrow} alt="" /></div>
          <div className="image"><img src={rightArrow} alt="" /></div>
        </div>
      </div>
    </label>
  </div>
  </div>
</div>`

const switch5 = `<div className="container-switch">
  <div className="on-off-group">
  <div className="switcher-small on-off">
    <label className="toggle-switch round-primary big">
      <input type="checkbox" />
      <div className="slider">
        <div className="switch">
          <div className="">On</div>
          <div className="">Off</div>
        </div>
      </div>
    </label>
  </div>
  <div className="switcher-small on-off">
    <label className="toggle-switch round-primary big">
      <input type="checkbox" />
      <div className="slider">
        <div className="switch">
          <div className="">On</div>
          <div className="">Off</div>
        </div>
      </div>
    </label>
  </div>
  </div>
</div>`

const SwitchersView = () => {

  return (
    < >
      <h1 className="title-section">Switches</h1>

      <p>
        A switch has the markup of a custom checkbox but uses the .form-switch
        class to render a toggle switch. Consider using role="switch" to more
        accurately convey the nature of the control to assistive technologies
        that support this role. In older assistive technologies,
        it will simply be announced as a regular checkbox as a fallback.
        Switches also support the disabled attribute.
      </p>
      <br />
      <div className="container-switch">

        <div className="pair primary-checked" style={{alignItems:"center"}}>
          <div className="toggle-small" style={{alignItems:"center"}}>
            <label className="toggle-switch round-primary">
              <input type="checkbox" />
              <div className="slider"></div>
            </label>
            <p style={{marginBottom:"0"}}>Switch</p>
          </div>
          <label className="toggle-switch round-primary big">
            <input type="checkbox" />
            <div className="slider"></div>
          </label>
        </div>
        <div className="pair primary-unchecked" style={{alignItems:"center"}}>
          <div className="toggle-small" style={{alignItems:"center"}}>
            <label className="toggle-switch round-primary">
              <input type="checkbox" />
              <div className="slider"></div>
            </label>
            <p style={{marginBottom:"0"}}>Switch</p>
          </div>
          <label className="toggle-switch round-primary big">
            <input type="checkbox" />
            <div className="slider"></div>
          </label>
        </div>

        <ReactPrism
          language="html"
          plugins={["line-numbers"]}>
          {switch1}
        </ReactPrism>

        <h5 className="subtitle">Switches inside div</h5>

        <div className="small-with-bg">
          <div className="toggle-small light">
            <label className="toggle-switch round-primary">
              <input type="checkbox" />
              <div className="slider"></div>
            </label>
            <p>Switch</p>
          </div>
          <div className="toggle-small light">
            <label className="toggle-switch round-primary">
              <input type="checkbox" />
              <div className="slider"></div>
            </label>
            <p>Switch</p>
          </div>
        </div>

        <ReactPrism
          language="html"
          plugins={["line-numbers"]}>
          {switch2}
        </ReactPrism>

        <h5 className="subtitle">Switches with ON/OFF state</h5>

        <div className="switcher-big ">
          <label className="toggle-switch round-primary big">
            <input type="checkbox" />
            <div className="slider"></div>
            <div className="switch">
              <div className="">OFF</div>
              <div className="">ON</div>
            </div>
          </label>
        </div>

        <ReactPrism
          language="html"
          plugins={["line-numbers"]}>
          {switch3}
        </ReactPrism>

        <h5 className="subtitle">Switches with arrows</h5>

        <div className="arrow-group">
          <div className="switcher-small">
            <label className="toggle-switch round-primary big">
              <input type="checkbox" />
              <div className="slider">
                <div className="switch">
                  <div className="image"><img src={leftArrow} alt="" />
                  </div>
                  <div className="image"><img src={rightArrow} alt="" /></div>
                </div>
              </div>
            </label>
          </div>

          <div className="switcher-small">
            <label className="toggle-switch round-primary big">
              <input type="checkbox" />
              <div className="slider">
                <div className="switch">
                  <div className="image"><img src={leftArrow} alt="" /></div>
                  <div className="image"><img src={rightArrow} alt="" /></div>
                </div>
              </div>
            </label>
          </div>
        </div>

        <ReactPrism
          language="html"
          plugins={["line-numbers"]}>
          {switch4}
        </ReactPrism>

        <h5 className="subtitle">On off group</h5>

        <div className="on-off-group">
          <div className="switcher-small on-off">
            <label className="toggle-switch round-primary big">
              <input type="checkbox" />
              <div className="slider">
                <div className="switch">
                  <div className="">On</div>
                  <div className="">Off</div>
                </div>
              </div>
            </label>
          </div>
          <div className="switcher-small on-off">
            <label className="toggle-switch round-primary big">
              <input type="checkbox" />
              <div className="slider">
                <div className="switch">
                  <div className="">On</div>
                  <div className="">Off</div>
                </div>
              </div>
            </label>
          </div>
        </div>

        <ReactPrism
          language="html"
          plugins={["line-numbers"]}>
          {switch5}
        </ReactPrism>

      </div>

    </>
  )
}

export default SwitchersView