import React from 'react'
import {Col, Button, Card, ListGroup } from 'react-bootstrap';
import ReactPrism from '@versant-digital/react-prismjs';

const plans = `<Card className="card-pricing h-100">
    <div className="card-body">
        <div className="text-center p-3">
            <h5 className="card-title">BASIC PLAN</h5>

            <span className="h2">$</span><span className="price">19</span>
            <p>/MONTH</p>
        </div>
    </div>

    <ListGroup className="list-group-flush">
        <ListGroup.Item className="list-group-item">Unlimited activity</ListGroup.Item>
        <ListGroup.Item className="list-group-item">Direct messaging</ListGroup.Item>
        <ListGroup.Item className="list-group-item">Members<span>10 members</span></ListGroup.Item>
        <ListGroup.Item className="list-group-item">Admins<span>No access</span></ListGroup.Item>
    </ListGroup>
    <Button variant="primary btn-lg">Start with Standard</Button>
</Card>`

const PlansAndPricingView = () => {
    return (
        <>
            <h1 className="title-section">Plans and Pricing</h1>

            <div className="wrap-row">
                <Col>
                    <Card className="card-pricing h-100">
                        <div className="card-body">
                            <div className="text-center p-3">
                                <h5 className="card-title">BASIC PLAN</h5>

                                <span className="h2">$</span><span className="price">19</span>
                                <p>/MONTH</p>
                            </div>
                        </div>

                        <ListGroup className="list-group-flush">
                            <ListGroup.Item className="list-group-item">Unlimited activity</ListGroup.Item>
                            <ListGroup.Item className="list-group-item">Direct messaging</ListGroup.Item>
                            <ListGroup.Item className="list-group-item">Members<span>10 members</span></ListGroup.Item>
                            <ListGroup.Item className="list-group-item">Admins<span>No access</span></ListGroup.Item>
                        </ListGroup>
                        <Button variant="primary btn-lg" >Start with Basic</Button>
                    </Card>
                </Col>

                <Col>
                    <Card className="card-pricing h-100">
                        <div className="card-body">
                            <div className="text-center p-3">
                                <h5 className="card-title">BASIC PLAN</h5>

                                <span className="h2">$</span><span className="price">29</span>
                                <p>/MONTH</p>
                            </div>
                        </div>

                        <ListGroup className="list-group-flush">
                            <ListGroup.Item className="list-group-item">Unlimited activity</ListGroup.Item>
                            <ListGroup.Item className="list-group-item">Direct messaging</ListGroup.Item>
                            <ListGroup.Item className="list-group-item">Members<span>50 members</span></ListGroup.Item>
                            <ListGroup.Item className="list-group-item">Admins<span>5 admins</span></ListGroup.Item>
                        </ListGroup>
                        <Button variant="primary btn-lg" >Start with Standard</Button>
                    </Card>
                </Col>

                <Col>
                    <Card className="card-pricing h-100">
                        <div className="card-body">
                            <div className="text-center p-3">
                                <h5 className="card-title">BASIC PLAN</h5>

                                <span className="h2">$</span><span className="price">99</span>
                                <p>/MONTH</p>
                            </div>
                        </div>

                        <ListGroup className="list-group-flush">
                            <ListGroup.Item className="list-group-item">Unlimited activity</ListGroup.Item>
                            <ListGroup.Item className="list-group-item">Direct messaging</ListGroup.Item>
                            <ListGroup.Item className="list-group-item">Members<span>Unlimited</span></ListGroup.Item>
                            <ListGroup.Item className="list-group-item">Admins<span>Unlimited</span></ListGroup.Item>
                        </ListGroup>
                        <Button variant="primary btn-lg" >Start with Enterprice</Button>
                    </Card>
                </Col>

            </div>

            <ReactPrism
                language="html"
                plugins={["line-numbers"]}>
                {plans}
            </ReactPrism>

        </>
    )
}

export default PlansAndPricingView
