import React from 'react'
import { Form, Row, Col } from 'react-bootstrap';
import { Doughnut, Bar, Line, Pie } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels';

const lineData = {
    labels: ['', '02 JAN', '03 JAN', '04 JAN', '05 JAN', '06 JAN', '07 JAN', '08 JAN', '09 JAN', '10 JAN', '11 JAN', '12 JAN', ""],
    datasets: [{
        data: [18, 26, 16, 17, 13, 18.7, 17, 25, 16, 22, 23, 23],
    }],
};

const lineOptions = {
    responsive: true,
    maintainAspectRatio: true,
    layout: {
        padding: {
            left: 22,
            right: 30,
        },
    },
    elements: {
        point: {
            radius: 0
        },
        line: {
            tension: 0.5,
            borderColor: '#5561B3',
            borderWidth: 6,
        }
    },
    scales: {
        y: {
            grid: {
                drawBorder: false,
                color: '#F4F5FC'
            },
            min: 9,
            max: 27,
            ticks: {
                stepSize: 3,
                display: true,
                color: '#161F5E',
                font: {
                    family: "'Poppins', sans-serif",
                    weight: 500,
                    size: 16,
                }
            }
        },
        x: {
            grid: {
                display: false,
            },
            ticks: {
                display: true,
                color: '#161F5E',
                font: {
                    family: "'Poppins', sans-serif",
                    weight: 500,
                    size: 16,
                }
            }
        }
    },
    plugins: {
        legend: {
            display: false
        }
    }
}

const barData = {
    labels: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    datasets: [{
        data: [7, 9, 12, 9, 15, 10, 12],
    },
    ]
};

const barOptions = {
    backgroundColor: "#5561B3",
    barThickness: 20,
    borderColor: "#161F5E",
    borderSkipped: false,
    borderRadius: 20,
    borderWidth: {
        top: 20,
    },
    layout: {
        padding: {
            left: 38,
            right: 38,
            top: 31,
            bottom: 23
        },
    },
    scales: {
        y: {
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                display: false,
            }
        },
        x: {
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                display: true,
                color: '#575F6B',
                font: {
                    family: "'Poppins', sans-serif",
                    weight: 500,
                    size: 16,
                }
            }
        }
    },
    plugins: {
        datalabels: {
            anchor: 'end',
            align: 'top',
            font: {
                family: "'Poppins', sans-serif",
                weight: 500,
                size: 16,
            },
            formatter: function (value) {
                return value + '°';
            }
        },
        legend: {
            display: false
        }

    },


}

const doughnutData = {
    labels: ['Iphone', 'Samsung', 'Huawei', 'Xiaomi', 'General Mobile'],
    datasets: [{
        data: [25, 15, 20, 10, 30],
        backgroundColor: [
            '#5561B3',
            '#DF4149',
            '#DFCA41',
            '#4FAC68',
            '#161F5E'
        ],
    },
    ]
};

const doughnutOptions = {
    radius: 120,
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
        legend: {
            display: false
        }
    },
}

const pieData = {
    labels: ['Organic Search', 'Email', 'Referral', 'Paid Search'],
    datasets: [{
        data: [25, 35, 35, 20],
        backgroundColor: [
            '#5561B3',
            '#161F5E',
            '#DFCA41',
            '#DF4149'
        ],
    },
    ]
};

const pieOptions = {
    radius: 120,
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
        legend: {
            display: false
        }
    },
}

const lineData2 = {
    labels: ['02 JAN', '03 JAN', '04 JAN', '05 JAN', '06 JAN', '07 JAN', '08 JAN'],

    datasets: [
        {
            data: [0, 50, -50, 2, -40, 100, -10, 0],
            backgroundColor: '#5561B3',
        }
    ],
};

const lineOptions2 = {
    borderColor: '#ffff',
    lineTension: 0.3,
    borderWidth: 6,
    fill: false,

    legend: {
        display: false
    },
    elements: {
        point: {
            radius: 0
        }
    },
    scales: {
        x: {
            display: false,
            beginAtZero: false,
            grid: {
                display: false
            }
        },
        y: {
            display: false,
            beginAtZero: true,
            grid: {
                display: false
            }
        }
    },
    plugins: {
        legend: {
            display: false
        },
    },

}

const barData2 = {
    labels: ['02 JAN', '03 JAN', '04 JAN', '05 JAN', '06 JAN', '07 JAN', '08 JAN', '09 JAN', '10 JAN', '11 JAN'],
    datasets: [{
        data: [48, 65, 41, 82, 45, 48, 65, 41, 82, 45],
    },
    ]
};

const barOptions2 = {
    backgroundColor: '#5561B3',
    barPercentage: 1,
    borderColor: "#161F5E",
    borderSkipped: false,
    borderRadius: 15,
    borderWidth: {
        top: 20,
    },
    scales: {
        x: {
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                display: true,
                color: '#575F6B',
                font: {
                    family: "'Poppins', sans-serif",
                    weight: 500,
                    size: 16,
                }
            }
        },
        y: {
            min: 0,
            max: 100,
            grid: {
                drawBorder: false,
            },
            ticks: {
                display: true,
                color: '#575F6B',
                font: {
                    family: "'Poppins', sans-serif",
                    weight: 500,
                    size: 16,
                }
            }
        },
    },
    plugins: {
        datalabels: {
            anchor: 'center',
            align: 'center',
            color: 'white',
            font: {
                family: "'Poppins', sans-serif",
                weight: 500,
                size: 16,
            },

        },
        legend: {
            display: false
        }
    }
}

const horizontalBarData = {
    labels: ['/index.html', '/about.html', '/product.html', '/categories.html', '/contact.html', '/users.html', '/blog.html'],
    datasets: [{
        data: [64, 48, 36, 78, 56, 92, 42],
        backgroundColor: '#5561B3',
    },
    {
        data: [100, 100, 100, 100, 100, 100, 100],
        backgroundColor: '#161F5E',
    }]
}

const horizontalBarOptions = {
    indexAxis: 'y',
    inflateAmount: 'auto',
    barThickness: 16,
    borderSkipped: false,
    responsive: true,
    maintainAspectRatio: true,
    borderRadius: 15,
    layout: {
        padding: 10,
    },
    scales: {
        y: {
            stacked: true,
            ticks: {
                padding: 10,
            },
            grid: {
                display: false,
                drawBorder: false,
                offset: true,
                beginAtZero: false,
            }
        },
        x: {
            display: false,
        }
    },
    plugins: {
        legend: {
            display: false
        },
        datalabels: {
            anchor: 'end',
            align: 'start',
            color: 'white',
            font: {
                family: "'Poppins', sans-serif",
                weight: 500,
                size: 12,
            },
            formatter: function (value) {
                if (value === 100) {
                    return null;
                }
                return value + '%';
            }
        },
    },
}

const ChartsView = () => {
    return (
        <>
            <h1 className="title-section">Charts</h1>
            <p>To use charts, it is required to download 'react-chartjs-2' npm library (npm install --save react-chartjs-2 chart.js).</p>
            <p>For datalabels install chartjs-plugin-datalabels (npm install chartjs-plugin-datalabels --save)</p>

            <h5 className="subtitle">Line chart</h5>
            <p>A line chart is a way of plotting data points on a line. Often, it is used to show trend data, or the comparison of two data sets.</p>
            <div className="line-chart-wrapper">
                <p>STATISTICS</p>
                <Line data={lineData} options={lineOptions} className="line-chart" />
            </div>

            <br />
            <div className="chart-area-line">
                <div className="line-chart2-parameters">
                    <div className="line-chart-parameter-name">Active users</div>
                    <div className="line-chart-number">46</div>
                </div>
                <Line data={lineData2} options={lineOptions2} className="chart-line-2" />
            </div>

            <br />

            <h5 className="subtitle">Bar chart</h5>
            <p>A bar chart provides a way of showing data values represented as vertical bars. It is sometimes used to show trend data, and the comparison of multiple data sets side by side.</p>
            <div className="bar-chart-wrapper">
                <div className="bar-chart-menu">
                    <Form.Select aria-label="Select options">
                        <option value="3">WEEK 2</option>
                        <option value="5">WEEK 3</option>
                        <option value="10">WEEK 4</option>
                        <option value="15">WEEK 5</option>
                    </Form.Select>
                    <Form.Select aria-label="Select options">
                        <option value="3">FEBRUARY 2020</option>
                        <option value="5">MARCH 2020</option>
                        <option value="10">MAY 2020</option>
                        <option value="15">APRIL 2020</option>
                    </Form.Select>
                </div>
                <Bar data={barData} options={barOptions} plugins={[ChartDataLabels]} className="bar-chart" />
            </div>
            <br />
            <Bar data={barData2} options={barOptions2} plugins={[ChartDataLabels]} className="bar-chart-2" />

            <br />

            <h5 className="subtitle">Doughnut and Pie chart</h5>
            <p>Pie and doughnut charts are probably the most commonly used charts. They are divided into segments, the arc of each segment shows the proportional value of each piece of data.</p>
            <p>They are excellent at showing the relational proportions between data.</p>
            <p>Pie and doughnut charts are effectively the same class in Chart.js, but have one different default value - their <code>cutout</code>. This equates to what portion of the inner should be cut out. This defaults to <code>0</code> for pie charts, and <code>'50%'</code> for doughnuts.</p>
            <p>They are also registered under two aliases in the <code>Chart</code> core. Other than their different default value, and different alias, they are exactly the same.</p>

            <div className="doughnut-chart-area">
                <div className="doughnut-chart-heading">
                    <div className="chart-name">Hot products</div>
                    <Form.Select aria-label="Select options">
                        <option value="3">OPTIONS</option>
                        <option value="5">WEEK 3</option>
                        <option value="10">WEEK 4</option>
                        <option value="15">WEEK 5</option>
                    </Form.Select>
                </div>
                <Doughnut data={doughnutData} options={doughnutOptions} className="doughnut-chart" />
                <div className="doughnut-parameters">
                    <div className="doughnut-parameter"><div className="chart-circle primary"></div>Iphone</div>
                    <div className="doughnut-parameter"><div className="chart-circle danger"></div>Samsung</div>
                    <div className="doughnut-parameter"><div className="chart-circle warning"></div>Huawei</div>
                    <div className="doughnut-parameter"><div className="chart-circle success"></div>Xiaomi</div>
                    <div className="doughnut-parameter"><div className="chart-circle secondary"></div>Generic mobile</div>
                </div>
            </div>

            <br />

            <div className="pie-chart-area">
                <div className="chart-name">Sessions By Chanel</div>
                <Pie data={pieData} options={pieOptions} className="pie-chart" />
                <div className="pie-chart-legend">
                    <Row>
                        <Col>
                            <div className="pie-chart-parameter">Organic Search
                                <div className="pie-chart-parameter-number">
                                    <div className="chart-circle primary"></div>1.242</div>
                            </div>
                        </Col>
                        <Col>
                            <div className="pie-chart-parameter">Email
                                <div className="pie-chart-parameter-number">
                                    <div className="chart-circle warning"></div>742</div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="pie-chart-parameter">Referral
                                <div className="pie-chart-parameter-number">
                                    <div className="chart-circle danger"></div>422</div>
                            </div>
                        </Col>
                        <Col>
                            <div className="pie-chart-parameter">Paid Search
                                <div className="pie-chart-parameter-number">
                                    <div className="chart-circle secondary"></div>1.738</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <br />

            <h5 className="subtitle">Horizontal bar chart</h5>

            <br />
            <Bar data={horizontalBarData} options={horizontalBarOptions} plugins={[ChartDataLabels]} className="horizontal-chart" />
        </>
    )
}

export default ChartsView